import classes from "./BusinessType.module.css";
import { SignupDetails } from "../../components/Signupdetails/SignupDetails";

import { Link } from "react-router-dom";
import PersonalDetailsImg from "../../images/Signup/PersonalDetails.png";
import { signupActions } from "../../store/signup-slice";

import BuzzerLogo from "../../images/loginLogo.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useState, useEffect } from "react";

import Arrow from "../../images/ForgotPassword/ArrowOne.png";
import Lock from "../../images/ForgotPassword/Lock.png";
import cross from "../../images/blackcross.png";

import ContactUsImg from "../../images/contactus/ContactUsImg.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputPassword } from "../../components/InputPassword/InputPassword";
import { PhoneCountry } from "../../components/PhoneWithCountry/PhoneWithCountry";
import { Button } from "react-bootstrap";

export const BusinessType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.signup);
  useEffect(() => {
    console.log(user);
  }, [user]);
  let [countryCode, setCountryCode] = useState("");
  const [BusinessType, setBusinessType] = useState("noselect");
  const changeBusinessType = (val) => {
    setBusinessType(val);
  };
  const saveBusinessType = () => {
    console.log(BusinessType);
    if (BusinessType === "noselect") {
    } else {
      dispatch(
        signupActions.signupuser({
          business: BusinessType,
        })
      );
      navigate("/businesslocationaddress");
    }
  };
  return (
    <SignupDetails>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <img src={cross} className={`${classes.imagecross}`} />
          </div>
          <div className="col-md-12 text-center">
            <img src={BuzzerLogo} />
          </div>
          <div className="col-md-12 px-5 text-center">
            <p className={`${classes.businessheading}`}>
              Select your Business Type
            </p>
            <select
              onChange={(event) => changeBusinessType(event.target.value)}
              value={BusinessType}
            >
              <option value="Business Type" hidden selected>
                Select Business Type
              </option>
              <option value="Authorized Dealer">Authorized Dealer</option>
              <option value="Exempt Dealer">Exempt Dealer</option>
              <option value="Association">Association</option>
            </select>
          </div>
          <div className="col-md-12 text-center">
            <button
              onClick={saveBusinessType}
              className={`${classes.btnnext} btn btn-primary btn-lg`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
