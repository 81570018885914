import classes from "./UpBar.module.css";
import star from "../../images/workspace/star.png";
import { WorkspaceInviteModal } from "./WorkspaceInviteModal";
import { WorkspaceTimeModal } from "./WorkspaceTimeModal";
import Search from "./AnimatedSearchBox";

import activity from "../../images/workspace/activity.png";
import { useState } from "react";
import invite from "../../images/workspace/invite.png";
import color from "../../images/workspace/color.png";
import integration from "../../images/workspace/integration.png";
import menu from "../../images/workspace/menu.png";
import whiteplus from "../../images/workspace/whiteplus.png";
import automation from "../../images/workspace/automation.png";
import { useSelector, useDispatch } from "react-redux";
import { workspaceActions } from "../../store/Workspace/workspace-slice";
import Girlone from "../../images/workspace/girlone.jpeg";
import Girltwo from "../../images/workspace/Girltwo.jpg";
import { deleteTasks } from "../../store/Workspace/workspace-actions";
import { WorkspaceRightDropdown } from "./WorkspaceRightDropdown";
import { WorkspaceGroupSection } from "./WorkspaceGroupSection";
export const UpBar = () => {
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);
  let [restore, setRestore] = useState(false);
  console.log(restore);
  const deleteTasksHandler = () => {
    dispatch(deleteTasks(workspace));
  };
  const closetimemodal = () => {
    console.log("ahah");
    dispatch(
      workspaceActions.addtimemodal({
        showaddtimes: !workspace.buttonclicks.showaddtimes,
      })
    );
  };
  const closeinvitemodal = () => {
    console.log("ahah");
    dispatch(
      workspaceActions.addinvitemodal({
        showaddinvite: !workspace.buttonclicks.showaddinvite,
      })
    );
  };
  const openinvitemodal = () => {
    dispatch(
      workspaceActions.addinvitemodal({
        showaddinvite: !workspace.buttonclicks.showaddinvite,
      })
    );
  };
  const showgroupmodal = () => {
    dispatch(
      workspaceActions.addgroupmodal({
        showaddGroup: !workspace.buttonclicks.showaddGroup,
      })
    );
  };
  const openmembersmodal = () => {
    dispatch(
      workspaceActions.addshowmemberin({
        showmemberin: !workspace.buttonclicks.showmemberin,
      })
    );
  };
  const openmemberspendingmodal = () => {
    dispatch(
      workspaceActions.openmemberspendingmodal({
        openmemberspendingmodal:
          !workspace.buttonclicks.openmemberspendingmodal,
      })
    );
  };
  return (
    <>
      <div className="d-flex justify-content-end">
        <div className={`d-flex ${classes.actpad}`}>
          <img src={activity} className={classes.activity} alt="" />
          <p className={classes.activity}>Activity</p>
        </div>

        {(workspace.useablevariables.workspacerole === "company" ||
          workspace.useablevariables.updatemyoperatemember === "1") && (
          <div className="d-flex align-items-center">
            <img
              src={invite}
              className={classes.invite}
              onClick={openinvitemodal.bind(null, [
                Number(workspace.latestboard.id),
                Number(workspace.latestworkspace.id),
              ])}
              alt=""
            />
            <p className={classes.invitetext}>Invite</p>
            <img src={menu} className={classes.menu} alt="" />
            {/* <WorkspaceRightDropdown /> */}
          </div>
        )}
      </div>
      <div className="d-flex align-items-center">
        <p className={classes.newboard}>
          {workspace.latestboard
            ? workspace.latestboard.name
              ? workspace.latestboard.name === "No Board"
                ? "No Board"
                : workspace.latestboard.name
              : workspace.latestworkspace
              ? "Loading..."
              : "No Board"
            : workspace.latestworkspace
            ? "Loading..."
            : "Loading..."}
        </p>

        <img src={star} className={classes.star} alt="" />
        {workspace.useablevariables.workspacerole === "company" &&
          workspace.latestboard.name && (
            <>
              <div
                className={`d-flex align-items-center ${classes.mlpa}`}
                onClick={openmembersmodal}
              >
                {workspace.memberinboard.slice(0, 3).map((item) => (
                  <img
                    key={item.id}
                    src={`https://buzzuperpbackend.dejuretechnologies.com${item.image}`}
                    className={classes.members}
                  />
                ))}
                <div className={`${classes.memberscount}`}>
                  <div
                    style={{ marginTop: "-2px" }}
                  >{`${workspace.memberinboard.length}`}</div>
                </div>
                <h5 className="m-0" style={{ paddingLeft: "10px !important" }}>
                  Members
                </h5>
              </div>

              <div
                className={`d-flex align-items-center ${classes.mlpaa}`}
                onClick={openmemberspendingmodal}
              >
                <div className={`${classes.memberscount}`}>
                  <div
                    style={{ marginTop: "-2px" }}
                  >{`${workspace.memberinboardpendings.length}`}</div>
                </div>
                <h5 className="m-0" style={{ paddingLeft: "10px !important" }}>
                  Pending Invites
                </h5>
              </div>
            </>
          )}
      </div>

      <div className="d-flex justify-content-end">
        {/* <div className={`d-flex ${classes.sidecolor}`}>
          <img src={color} className={classes.color} alt="" />
          <p className={classes.color}>color</p>
        </div> */}

        <div className="d-flex align-items-center">
          <img src={integration} className={classes.integration} alt="" />
          <p className={classes.invitetext}>Integrate</p>
        </div>
        <div className={`d-flex align-items-center ${classes.automation}`}>
          <img src={automation} className={classes.invite} alt="" />
          <p className={classes.automationtext}>Automation</p>
        </div>
      </div>
      <hr className={classes.hrstyle} />
      <Search />
      {workspace.buttonclicks.showaddinvite && (
        <WorkspaceInviteModal
          onclose={closeinvitemodal}
          workspace={JSON.parse(JSON.stringify(workspace))}
        />
      )}
      {workspace.buttonclicks.showaddtimes && (
        <WorkspaceTimeModal
          onclose={closetimemodal}
          workspace={JSON.parse(JSON.stringify(workspace))}
        />
      )}
      {workspace.latestworkspace ? (
        workspace.latestboard ? (
          workspace.latestboard.name ? (
            <>
              {workspace.useablevariables.TaskDelCounts.length > 0 && (
                <div
                  className="d-flex justify-content-between px-4 py-2 align-items-center mb-3"
                  style={{ backgroundColor: "#a1c1ef" }}
                >
                  <h4 style={{ marginBottom: "2px", marginLeft: "-12px" }}>
                    <b>Action</b>
                  </h4>
                  <button
                    className={`btn ${
                      workspace.useablevariables.TaskSection === "main"
                        ? "btn-danger"
                        : "btn-success"
                    }`}
                    style={{ marginBottom: "2px", marginRight: "-12px" }}
                    onClick={deleteTasksHandler}
                  >
                    {workspace.useablevariables.TaskSection === "main" &&
                      `Delete Tasks ${workspace.useablevariables.TaskDelCounts.length}`}
                    {workspace.useablevariables.TaskSection !== "main" &&
                      `Restore Tasks ${workspace.useablevariables.TaskDelCounts.length}`}
                  </button>
                </div>
              )}
              {/* {workspace.useablevariables.RestoreStatus !== "ok" &&
                workspace.useablevariables.TaskSection !== "main" && (
                  <h1>Trash is Empty</h1>
                )} */}

              {workspace.searchZone &&
                workspace.searchGroups.map((item) => (
                  <WorkspaceGroupSection key={item.id} item={item} />
                ))}
              {!workspace.searchZone &&
                workspace.groups.map((item) => (
                  <WorkspaceGroupSection key={item.id} item={item} />
                ))}
              {workspace.useablevariables.TaskSection === "main" && (
                <div className="py-3">
                  <button
                    onClick={showgroupmodal}
                    className={`btn ${classes.bgaddgroup} d-flex align-items-center`}
                  >
                    <img
                      src={whiteplus}
                      style={{ width: "15px", marginRight: "5px" }}
                    />
                    <span>Add New Group</span>
                  </button>
                </div>
              )}
            </>
          ) : workspace.latestboard === "no board" ? (
            <p>You dont have any Group</p>
          ) : (
            <p></p>
          )
        ) : (
          <p></p>
        )
      ) : (
        <p></p>
      )}
    </>
  );
};
