import { useEffect, useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import { useSelector, useDispatch } from "react-redux";
import { signinActions } from "../../store/signin-slice";
import { sendSigninData } from "../../store/signin-actions";

import { Link } from "react-router-dom";
import loginImg from "../../images/loginImg.png";
import loginLogo from "../../images/loginLogo.png";
import googleIcon from "../../images/icons/googleIcon.png";
import { useFormik } from "formik";
import * as yup from "yup";

let isInitial = true;

export const Login = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const navigate = useNavigate();
  console.log(cookies);
  useEffect(() => {
    if (cookies.Token !== undefined) {
      navigate("/workspace");
    } else {
      navigate("/login");
    }
  }, []);
  const [count, setCount] = useState(1);
  console.log(cookies);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.signin);
  console.log(user);
  useEffect(() => {
    if (isInitial) {
      isInitial = false;
      return;
    }
    console.log(user.email);
    if (user.email.length > 0) {
      dispatch(sendSigninData(user, setCookie, navigate));
    }
  }, [user, dispatch, count]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      console.log(values);
      dispatch(
        signinActions.signinuser({
          email: values.email,
          password: values.password,
        })
      );
      setCount(count++);
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is Required"),
      password: yup
        .string()
        .required("Password is Required")
        .min(8, "Password is too short - should be 8 chars minimum."),
    }),
  });
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-7 col1-main">
          <div className="login-img">
            <img alt="login" src={loginImg} />
          </div>
        </div>
        <div className="col-lg-5 col2-main">
          <div className="login-logo">
            <img alt="Buzzup" src={loginLogo} />
          </div>

          <div className="signin-form">
            <p>Sign In to BuzzUp</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="signin-input">
                <p>Email</p>
                <input
                  type="text"
                  placeholder="Abc@gmail.com"
                  required
                  id="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="signin-input mt-4">
                <p>Password</p>
                <input
                  type="password"
                  placeholder="********"
                  required
                  id="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.errors.password ? (
                  <div className="error">{formik.errors.password}</div>
                ) : (
                  ""
                )}
              </div>
              <p className="forgot-pass">
                <Link
                  to="/forgotpassword"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Forgot Password?
                </Link>
              </p>
              <button type="submit" className="login-btn">
                Log In
              </button>
            </form>
            <p className="login-with">OR Log In with</p>
            <button type="submit" className="google-btn">
              Google <img alt="icon" src={googleIcon} className="google-icon" />
            </button>
            <div className="have-account">
              <p>
                Don’t have an account?{" "}
                <Link to="/signup">
                  <span> Sign up</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
