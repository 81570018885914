import { createSlice } from "@reduxjs/toolkit";

const signinSlice = createSlice({
  name: "signin",
  initialState: {
    email: '',
    password: ''
  },
  reducers: {
    signinuser(state, action) {

        const user = action.payload;
        console.log('thissssssss')
        console.log(user)
        console.log('thissssssss')

        if(user.email){
            state.email = user.email
        }else{
          state.email = user.email

        }
        if(user.password){
            state.password = user.password
        }else{
          state.password = user.password
        }

        // console.log(user);
    },
  },
});

export const signinActions = signinSlice.actions;

export default signinSlice;
