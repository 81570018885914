import { Store } from "react-notifications-component";
import { workspaceActions } from "./workspace-slice";
import { signupActions } from "../../store/MemberSignup/MemberSignup-slice";


import "animate.css/animate.min.css";

import "animate.css/animate.compat.css";
import axios from "axios";
import { date } from "yup";
import { iterateExtend } from "@syncfusion/ej2-react-grids";
import { NoStroller } from "@mui/icons-material";


export const getTaskData = (id)=>{
  return async (dispatch)=>{
    try{
      const response = await axios({
        method: "GET",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/items/getitemdescription/${id}`,
      });
      console.log('aaaaaaaaaaaaaaaaa');
      console.log(response.data.data);
      dispatch(workspaceActions.setItemDetails({itemDetails:JSON.parse(JSON.stringify(response.data.data))}))
    } catch (error) {
  console.log(error);

  Store.addNotification({
    title: "Something Wrong",
    message:`${error.response.data.message}`,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      // onScreen: true,
    },
  });
  console.log(error);

}  
  }
}

export const updateTaskDescription = (val,id)=>{
  return async (dispatch)=>{
    try{
      let data = new FormData();
      data.append('description',val)
      const response = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/items/add/description/${id}`,
        data,
      });
      console.log(response);
    } catch (error) {
  console.log(error);

  Store.addNotification({
    title: "Something Wrong",
    message:`${error.response.data.message}`,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      // onScreen: true,
    },
  });
  console.log(error);

}
  }
}
export const updateTaskDeadline = (val,id)=>{
  return async (dispatch)=>{
    
    try{
      let storeid=Store.addNotification({
        title: "Loading...",
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
      });
      let data = new FormData();
      data.append('deadline',val)
      const response = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/items/add/deadline/${id}`,
        data,
      });
      Store.removeNotification(storeid)
      console.log(response);
      Store.addNotification({
        title: "Deadline Assigned",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          // onScreen: true,
        },
      });
    } catch (error) {
  console.log(error);

  Store.addNotification({
    title: "Something Wrong",
    message:`${error.response.data.message}`,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      // onScreen: true,
    },
  });
  console.log(error);

}
  }
}
export const updateTaskDocuments = (val,id,workspace)=>{
  return async (dispatch)=>{
    try{
      let storeid=Store.addNotification({
        title: "Uploading...",
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
      });
      let data = new FormData();
      data.append('document',val)
      data.append('item_id',id)
      const response = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/items/add/document`,
        data,
      });
      Store.removeNotification(storeid)
      console.log(response);
      workspace= JSON.parse(JSON.stringify(workspace));
      workspace.useablevariables.itemDetails.documents.push(response.data.url)
console.log('akajajajjajj');
console.log(workspace.useablevariables.itemDetails.documents);
dispatch(
  workspaceActions.setItemDetails({
          itemDetails: JSON.parse(
            JSON.stringify({
              ...JSON.parse(
                JSON.stringify(workspace.useablevariables.itemDetails)
              ),
              documents: workspace.useablevariables.itemDetails.documents,
            })
          ),
        })
)
      
      Store.addNotification({
        title: "Document Uploaded",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          // onScreen: true,
        },
      });
    } catch (error) {
  console.log(error);

  Store.addNotification({
    title: "Something Wrong",
    message:`${error.response.data.message}`,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      // onScreen: true,
    },
  });
  console.log(error);

}
  }
}
export const deleteTasks = (workspace)=>{
  return async (dispatch)=>{
    try{
      let allarr=[]
      let abc = JSON.parse(JSON.stringify(workspace.useablevariables.TaskDelCounts))
      abc.map(item=>{
        allarr.push(
          axios({
            method: "POST",
            url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/items/change/status/${item}`,
          })
        )
      })
       Promise.all(allarr)
       dispatch(
        workspaceActions.updateTaskDelCounts({count:[]})
       );
       let abcd= JSON.parse(JSON.stringify(workspace.groups));
       abcd = abcd.map(item=>{
        item.items = item.items.map(items=>{
          if(abc.includes(Number(items.id))){
            items.status = items.status==='1'?'0':'1'
            return items
          }
          return items
        })
        return item
       })       
       dispatch(
        workspaceActions.updategroups(abcd)
       );
    } catch (error) {
  console.log(error);


//   Store.removeNotification(loadingid)

  Store.addNotification({
    title: "Something Wrong",
    message:`${error.response.data.message}`,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      // onScreen: true,
    },
  });
  console.log(error);
  // dispatch(
  //   uiActions.showNotification({
  //     status: "error",
  //     title: "Error!",
  //     message: "Sending cart data failed!",
  //   })
  // );
}
  }
}
export const additemingtodb = (token,id,workspace)=>{
  return async (dispatch)=>{
    console.log(token,id)    
    let loadingid=''

    let finalworkspace= JSON.parse(JSON.stringify(workspace));
    let str= Math.random().toString(36).substring(2,8+2)
    const sendRequest = async () => {
    //   loadingid = Store.addNotification({
    //     title: "Loading...",
    //     type: "warning",
    //     insert: "top",
    //     container: "top-right",
    //     animationIn: ["animate__animated", "animate__fadeIn"],
    //     animationOut: ["animate__animated", "animate__fadeOut"],
    //     slidingExit:{
    //       timingFunction:'linear'
    //     }
    //   });
    //   console.log('Loading id',token)
      let data = new FormData();
      data.append('group_id',id);
      data.append('name','Task');
      data.append('task_status','none');
      workspace= JSON.parse(JSON.stringify(workspace));

      workspace.groups= workspace.groups.map(item=>{
        if(Number(item.id)===Number(id)){
          item.items.push({
            id:20,
            group_id:id,
            name:'Task',
            task_status:'none'
          })
          return item
        }
        return item
      })    
      // workspace
      dispatch(
        workspaceActions.updategroups(workspace.groups)
      );
      const response = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/items/create`,
        data,
        headers:{
          jwt:token            
        }
      });
      return response;
      // if (response.data.status !== "success") {
        //   throw new Error("Sending cart data failed.");
        // }
      };
      
      try {
      console.log(workspace.groups)
      let response= await sendRequest();
      response.data.data.members=[]
      finalworkspace= JSON.parse(JSON.stringify(finalworkspace));
      finalworkspace.groups= finalworkspace.groups.map(item=>{
        if(Number(item.id)===Number(id)){
          item.items.push(response.data.data)
          return item
        }
        return item
      })    
      // workspace
      dispatch(
        workspaceActions.updategroups(finalworkspace.groups)
      );

      //   var today = new Date();
    //   var tomorrow = new Date();
    //   tomorrow.setDate(today.getDate()+1);
    //   setCookie("Token", `${response.data.message.remember_token}`, { path: "/",expires: tomorrow });
    //   dispatch(
    //     signinActions.signinuser({
    //       email: '',
    //       password: ''
    //     })
    //   );
    //   Store.removeNotification(loadingid)
      // Store.addNotification({
      //   title: "Get All",
      //   type: "success",
      //   insert: "top",
      //   container: "top-right",
      //   animationIn: ["animate__animated", "animate__fadeIn"],
      //   animationOut: ["animate__animated", "animate__fadeOut"],
      //   dismiss: {
      //     duration: 5000,
      //   },
      // });
    } catch (error) {
      console.log(error);


    //   Store.removeNotification(loadingid)

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  }  
}

export const getallworkspacesdb = (token) => {
  return async (dispatch) => {
    console.log(token)
    let loadingid=''
    const sendRequest = async () => {
    //   loadingid = Store.addNotification({
    //     title: "Loading...",
    //     type: "warning",
    //     insert: "top",
    //     container: "top-right",
    //     animationIn: ["animate__animated", "animate__fadeIn"],
    //     animationOut: ["animate__animated", "animate__fadeOut"],
    //     slidingExit:{
    //       timingFunction:'linear'
    //     }
    //   });
    //   console.log('Loading id',token)

      const response = await axios({
        method: "GET",
        url: "https://buzzuperpbackend.dejuretechnologies.com/api/v1/workspaces/all_workspace",
        headers:{
            jwt:token            
        }
      });
      return response;
      // if (response.data.status !== "success") {
        //   throw new Error("Sending cart data failed.");
        // }
      };
      
      try {
      let response= await sendRequest();
      console.log(response)
      dispatch(
        workspaceActions.updateworkspaces(response.data.data)
      );
      dispatch(
        workspaceActions.updatelatestworkspace(response.data.data.length>0?response.data.data[0]:false)
      );

      dispatch(workspaceActions.selectworkspaces(response.data.data.length>0?response.data.data[0]:false));

      //   var today = new Date();
    //   var tomorrow = new Date();
    //   tomorrow.setDate(today.getDate()+1);
    //   setCookie("Token", `${response.data.message.remember_token}`, { path: "/",expires: tomorrow });
    //   dispatch(
    //     signinActions.signinuser({
    //       email: '',
    //       password: ''
    //     })
    //   );
    //   Store.removeNotification(loadingid)
      // Store.addNotification({
      //   title: "Get All",
      //   type: "success",
      //   insert: "top",
      //   container: "top-right",
      //   animationIn: ["animate__animated", "animate__fadeIn"],
      //   animationOut: ["animate__animated", "animate__fadeOut"],
      //   dismiss: {
      //     duration: 5000,
      //   },
      // });
    } catch (error) {
      console.log(error);


    //   Store.removeNotification(loadingid)

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  };
};
export const updateitemindb = (token,data,workspace)=>{
  return async (dispatch)=>{
    console.log(token,data,workspace)
    const sendRequest = async () => {
      //   loadingid = Store.addNotification({
      //     title: "Loading...",
      //     type: "warning",
      //     insert: "top",
      //     container: "top-right",
      //     animationIn: ["animate__animated", "animate__fadeIn"],
      //     animationOut: ["animate__animated", "animate__fadeOut"],
      //     slidingExit:{
      //       timingFunction:'linear'
      //     }
      //   });
      //   console.log('Loading id',token)
        const response = await axios({
          method: "POST",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/items/update_item/${data.id}`,
          data:{
            name:data.val?data.val:undefined,
            task_status:data.status?data.status:undefined,
          },
          headers:{
              jwt:token            
          }
        });
        return response;
        // if (response.data.status !== "success") {
          //   throw new Error("Sending cart data failed.");
          // }
        };
        
        try {

          let response= await sendRequest();
        console.log(response)
   
        //   var today = new Date();
      //   var tomorrow = new Date();
      //   tomorrow.setDate(today.getDate()+1);
      //   setCookie("Token", `${response.data.message.remember_token}`, { path: "/",expires: tomorrow });
      let obj = JSON.parse(JSON.stringify(workspace.groups))
      obj = obj.map(item=>{
        item.items = item.items.map(items=>{
          if(Number(items.id)===Number(data.id)){
            items.name = response.data.data.name;
            items.task_status = response.data.data.task_status;
            return items
          }
          return items          
        })
        return item
      })  
      dispatch(
          workspaceActions.updategroups(obj)
      );
      //   Store.removeNotification(loadingid)
        // Store.addNotification({
        //   title: "Get All",
        //   type: "success",
        //   insert: "top",
        //   container: "top-right",
        //   animationIn: ["animate__animated", "animate__fadeIn"],
        //   animationOut: ["animate__animated", "animate__fadeOut"],
        //   dismiss: {
        //     duration: 5000,
        //   },
        // });
      } catch (error) {
        console.log(error);
  
  
      //   Store.removeNotification(loadingid)
  
        Store.addNotification({
          title: "Something Wrong",
          message:`${error.response}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            // onScreen: true,
          },
        });
        console.log(error);
        // dispatch(
        //   uiActions.showNotification({
        //     status: "error",
        //     title: "Error!",
        //     message: "Sending cart data failed!",
        //   })
        // );
      }
  }
}
export const createtimesdb = (token, data,workspace)=>{
  return async (dispatch) => {
    console.log(token)


    let loadingid=''
    const sendRequest = async () => {
    //   loadingid = Store.addNotification({
    //     title: "Loading...",
    //     type: "warning",
    //     insert: "top",
    //     container: "top-right",
    //     animationIn: ["animate__animated", "animate__fadeIn"],
    //     animationOut: ["animate__animated", "animate__fadeOut"],
    //     slidingExit:{
    //       timingFunction:'linear'
    //     }
    //   });
    //   console.log('Loading id',token)
      let datas = new FormData();
      datas.append('item_id',data.id)
      datas.append('time',data.time)
      datas.append('date',data.date)
      const response = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/times/create`,
        data:datas,
        headers:{
            jwt:token            
        }
      });
      return response;
      // if (response.data.status !== "success") {
        //   throw new Error("Sending cart data failed.");
        // }
      };
      
      try {
      let response= await sendRequest();
      console.log(response)

      // dispatch(
      //   workspaceActions.updatetimes(response.data.Time)
      // );

      //   var today = new Date();
    //   var tomorrow = new Date();
    //   tomorrow.setDate(today.getDate()+1);
    //   setCookie("Token", `${response.data.message.remember_token}`, { path: "/",expires: tomorrow });
    //   dispatch(
    //     signinActions.signinuser({
    //       email: '',
    //       password: ''
    //     })
    //   );
    //   Store.removeNotification(loadingid)
      // Store.addNotification({
      //   title: "Get All",
      //   type: "success",
      //   insert: "top",
      //   container: "top-right",
      //   animationIn: ["animate__animated", "animate__fadeIn"],
      //   animationOut: ["animate__animated", "animate__fadeOut"],
      //   dismiss: {
      //     duration: 5000,
      //   },
      // });
    } catch (error) {
      console.log(error);


    //   Store.removeNotification(loadingid)

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  };
}
export const changeItemMemberStatus= (data)=>{
  return async (dispatch) => {
    const sendRequest = async () => {
      let datas = new FormData();
      datas.append('member_id',data.memberid);
      datas.append('item_id',data.itemid);
      const response = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/item/members/create`,
        data:datas,
      });
      return response;
      };
      
      try {
      let response= await sendRequest();
      console.log(response)


    } catch (error) {
      console.log(error);


    //   Store.removeNotification(loadingid)

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  };  
}
export const changeRole = (status, id)=>{
  return async (dispatch) => {


    let loadingid=''
    const sendRequest = async () => {
    //   loadingid = Store.addNotification({
    //     title: "Loading...",
    //     type: "warning",
    //     insert: "top",
    //     container: "top-right",
    //     animationIn: ["animate__animated", "animate__fadeIn"],
    //     animationOut: ["animate__animated", "animate__fadeOut"],
    //     slidingExit:{
    //       timingFunction:'linear'
    //     }
    //   });
    //   console.log('Loading id',token)
      let datas = new FormData();
      datas.append('permission',status)
      const response = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/members/change/permission/${id}`,
        data:datas,
      });
      return response;
      // if (response.data.status !== "success") {
        //   throw new Error("Sending cart data failed.");
        // }
      };
      
      try {
      let response= await sendRequest();
      console.log(response)


    } catch (error) {
      console.log(error);


    //   Store.removeNotification(loadingid)

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  };
}
export const getalltimesdb = (token, ids, workspace)=>{  
  return async (dispatch) => {
    console.log(token)
    let loadingid=''
    const sendRequest = async () => {
    //   loadingid = Store.addNotification({
    //     title: "Loading...",
    //     type: "warning",
    //     insert: "top",
    //     container: "top-right",
    //     animationIn: ["animate__animated", "animate__fadeIn"],
    //     animationOut: ["animate__animated", "animate__fadeOut"],
    //     slidingExit:{
    //       timingFunction:'linear'
    //     }
    //   });
    //   console.log('Loading id',token)
    let pendinngs=[]
    ids.map(item=>{
      pendinngs.push(axios({
        method: "get",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/times/all_time/${item}`,
        headers:{
            jwt:token            
        }
      }));
    })
    pendinngs=await Promise.all(pendinngs)

    pendinngs = pendinngs.map(item=>{
      return item.data.Time;
    })

    pendinngs = pendinngs.filter(item=>{
      if(item.length===0){
        return false;
      }else{
        return true;
      }
    })

    let company=[]
    let members=[]
    
    pendinngs.map(item=>{
      console.log('aaaaaaaaaaaaaa')
      console.log(item)
      Object.keys(item).forEach(function(key) {
        item[key].map(items=>{
          items.role==='company'? company.push(items): members.push(items)
        })
      });
    })
    console.log('companyyyyy')
    console.log(company)
    let finalssCompanyObj={}
    let allcompanyid=[]
    company.map(item=>{
      allcompanyid.push(item.company_id)      
    })
    allcompanyid = new Set(allcompanyid)
    allcompanyid=[...allcompanyid]
    console.log(allcompanyid)
    allcompanyid.map(item=>{
      finalssCompanyObj[`${item}`]=[]
      company.map(items=>{
        if(Number(item)===Number(items.company_id)){
          finalssCompanyObj[`${item}`].push(items)
        }
      })
    })
// HEREEEEE COMPANY
console.log('finalssCompanyObj')
console.log(finalssCompanyObj)

let finalsaa={}
Object.keys(finalssCompanyObj).forEach(function(key){
  let bydateTime={}
  
  finalssCompanyObj[key].map(item=>{
    let month= new Date(Number(item.date)).getMonth()+1
    let date= new Date(Number(item.date)).getDate()
    let year= new Date(Number(item.date)).getFullYear()
    bydateTime[`${year}-${month}-${date}`]=[]
  })
  finalssCompanyObj[key].map(item=>{
    let month= new Date(Number(item.date)).getMonth()+1
    let date= new Date(Number(item.date)).getDate()
    let year= new Date(Number(item.date)).getFullYear()
    bydateTime[`${year}-${month}-${date}`].push(item)
  })
  let allitemss=[]
  let opila={}
  Object.keys(bydateTime).forEach(function(key){
    bydateTime[key].map(item=>{
      allitemss.push(item.item_id)
      opila[item.item_id]=[]
    })
    allitemss=[...new Set(allitemss)]
    allitemss.map(item=>{
      bydateTime[key].map(items=>{
      if(items.item_id=== item){
        opila[items.item_id].push(items)
      }
      })
    })
    console.log('opila')
    console.log(opila)
    bydateTime[key]=opila
  })
  finalsaa[key]=bydateTime
})

Object.keys(finalsaa).forEach(function(key){
  Object.keys(finalsaa[key]).forEach(function(keytwo){
    Object.keys(finalsaa[key][keytwo]).forEach(function(keythree){
      let finasum=0;
    console.log(finalsaa[key][keytwo][keythree])
    finalsaa[key][keytwo][keythree].map(item=>{
      finasum= finasum+= Number(item.time)
    })
    console.log('finasumaaaaaaa')
    console.log(finasum)

    finalsaa[key][keytwo][keythree][0]['time']= finasum;
    finalsaa[key][keytwo][keythree] = [finalsaa[key][keytwo][keythree][0]];
    });
  })
})
console.log('finalsaa')  
console.log(finalsaa)  

// OPEN LATER

// function checkdate(time){
//   let abc =new Date(Number(time)).setHours(0)
//   abc =new Date(Number(abc)).setMinutes(0)
//   abc =new Date(Number(abc)).setSeconds(0)
//   return abc;
// }
// Object.keys(finalssCompanyObj).forEach(function(key) {
//   let finalSum=0
//   let dateWeek= new Date(Date.now()).setDate(new Date(Date.now()).getDate()-7)
//   let daySum=0
//   let weekSum=0
//   finalssCompanyObj[key].map(item=>{
//     finalSum+=Number(item.time)
//   })
//   finalssCompanyObj[key].map(item=>{
//     let okkdate= checkdate(item.date)
//     if(okkdate>dateWeek){
//       weekSum +=Number(item.time);
//     }
//     let okdate= checkdate(Date.now())
//     if(Number(item.date)>Number(okdate)){
//       daySum +=Number(item.time);
//     }
//   })
//   finalssCompanyObj[key][0].fullTime=finalSum
//   finalssCompanyObj[key][0].daySum=daySum
//   finalssCompanyObj[key][0].weekSum=weekSum
//   finalssCompanyObj[key]= finalssCompanyObj[key][0]
// });

// OPEN LATER END

let finalssMemberObj={}
let allmemberid=[]
members.map(item=>{
  allmemberid.push(item.company_id)      
})
allmemberid = new Set(allmemberid)
allmemberid=[...allmemberid]
console.log(allmemberid)

allmemberid.map(item=>{
  finalssMemberObj[`${item}`]=[]
  members.map(items=>{
    if(Number(item)===Number(items.company_id)){
      finalssMemberObj[`${item}`].push(items)
    }
  })

})
console.log(finalssMemberObj)
// // HEREEEEE MEMBER

let finalsaaa={}
Object.keys(finalssMemberObj).forEach(function(key){
  let bydateTime={}
  
  finalssMemberObj[key].map(item=>{
    let month= new Date(Number(item.date)).getMonth()+1
    let date= new Date(Number(item.date)).getDate()
    let year= new Date(Number(item.date)).getFullYear()
    bydateTime[`${year}-${month}-${date}`]=[]
  })
  finalssMemberObj[key].map(item=>{
    let month= new Date(Number(item.date)).getMonth()+1
    let date= new Date(Number(item.date)).getDate()
    let year= new Date(Number(item.date)).getFullYear()
    bydateTime[`${year}-${month}-${date}`].push(item)
  })
  let allitemss=[]
  let opila={}
  Object.keys(bydateTime).forEach(function(key){
    bydateTime[key].map(item=>{
      allitemss.push(item.item_id)
      opila[item.item_id]=[]
    })
    allitemss=[...new Set(allitemss)]
    allitemss.map(item=>{
      bydateTime[key].map(items=>{
      if(items.item_id=== item){
        opila[items.item_id].push(items)
      }
      })
    })
    console.log('opila')
    console.log(opila)
    bydateTime[key]=opila
  })
  finalsaaa[key]=bydateTime
})

Object.keys(finalsaaa).forEach(function(key){
  Object.keys(finalsaaa[key]).forEach(function(keytwo){
    Object.keys(finalsaaa[key][keytwo]).forEach(function(keythree){
      let finasum=0;
    console.log(finalsaaa[key][keytwo][keythree])
    finalsaaa[key][keytwo][keythree].map(item=>{
      finasum= finasum+= Number(item.time)
    })
    console.log(finalsaaa[key][keytwo][keythree][0]['time'])

    finalsaaa[key][keytwo][keythree][0]['time']= finasum;
    finalsaaa[key][keytwo][keythree] = [finalsaaa[key][keytwo][keythree][0]];
    });
  })
})
console.log(finalsaaa)  

// Object.keys(finalssMemberObj).forEach(function(key) {
//   let finalSum=0
//   let dateWeek= new Date(Date.now()).setDate(new Date(Date.now()).getDate()-7)
//   let daySum=0
//   let weekSum=0
//   finalssMemberObj[key].map(item=>{
//     finalSum+=Number(item.time)
//   })
//   finalssMemberObj[key].map(item=>{
//     let okkdate= checkdate(item.date)
//     if(okkdate>dateWeek){
//       weekSum +=Number(item.time);
//     }
//     let okdate= checkdate(Date.now())
//     if(Number(item.date)>Number(okdate)){
//       daySum +=Number(item.time);
//     }
//   })
//   finalssMemberObj[key][0].fullTime=finalSum
//   finalssMemberObj[key][0].daySum=daySum
//   finalssMemberObj[key][0].weekSum=weekSum
//   finalssMemberObj[key]= finalssMemberObj[key][0]
// });







//     console.log('Company')
//     console.log(finalssCompanyObj)
//     console.log('memberssss')
//     console.log(finalssMemberObj)
    pendinngs={companies:finalsaa,members:finalsaaa}
    let allfinalsend=[]
// pendinngs[key][keytwo]     is a member id
// pendinngs[key][keytwo][keythree]    is a date
// pendinngs[key][keytwo][keythree][keyfour]    is a task
// pendinngs[key][keytwo][keythree][keyfour][0]['time']  is a time
// pendinngs[key][keytwo][keythree][keyfour][0]['image']  is a image
// pendinngs[key][keytwo][keythree][keyfour][0]['role']  is a role
// pendinngs[key][keytwo][keythree][keyfour][0]['username']  is a username
    Object.keys(pendinngs).forEach(function(key){
      Object.keys(pendinngs[key]).forEach(function(keytwo){
        Object.keys(pendinngs[key][keytwo]).forEach(function(keythree){
          Object.keys(pendinngs[key][keytwo][keythree]).forEach(function(keyfour){
          let memberid= keytwo;
          let date = keythree;
          let taskid=  pendinngs[key][keytwo][keythree][keyfour][0]['item_name'];
          let time=  pendinngs[key][keytwo][keythree][keyfour][0]['time'];
          let image= pendinngs[key][keytwo][keythree][keyfour][0]['image'];
          let role= pendinngs[key][keytwo][keythree][keyfour][0]['role'];
          let username= pendinngs[key][keytwo][keythree][keyfour][0]['username'];
          let obj= {memberid,date,taskid,time,image,role,username}
          allfinalsend.push(obj);
          });
        });
      });
    })
    return allfinalsend;
      // if (response.data.status !== "success") {
        //   throw new Error("Sending cart data failed.");
        // }
      };
      
      try {
      let response= await sendRequest();
      console.log(response)

      

      dispatch(
        workspaceActions.updatetimes(response)
      );

      //   var today = new Date();
    //   var tomorrow = new Date();
    //   tomorrow.setDate(today.getDate()+1);
    //   setCookie("Token", `${response.data.message.remember_token}`, { path: "/",expires: tomorrow });
    //   dispatch(
    //     signinActions.signinuser({
    //       email: '',
    //       password: ''
    //     })
    //   );
    //   Store.removeNotification(loadingid)
      // Store.addNotification({
      //   title: "Get All",
      //   type: "success",
      //   insert: "top",
      //   container: "top-right",
      //   animationIn: ["animate__animated", "animate__fadeIn"],
      //   animationOut: ["animate__animated", "animate__fadeOut"],
      //   dismiss: {
      //     duration: 5000,
      //   },
      // });
    } catch (error) {
      console.log(error);


    //   Store.removeNotification(loadingid)

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  };
}
export const deleteinvitedb = (id,workspace)=>{
  return async (dispatch) => {
    const sendRequest = async () => {  
        const response = await axios({
          method: "DELETE",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/members/invite/delete/${id}`,
        });
        return response;
        // if (response.data.status !== "success") {
          //   throw new Error("Sending cart data failed.");
          // }
    };
    try{
   await sendRequest();
  let pendings= [...workspace.memberinboardpendings]

  pendings= pendings.filter(item=>{
    if(Number(item.id)===Number(id)){
      return false;
    }    
    return true;
  })
  dispatch(
    workspaceActions.openmemberspendingmodal({openmemberspendingmodal:!workspace.buttonclicks.openmemberspendingmodal})
  );
  dispatch(
    workspaceActions.setmemberinboardpendings(pendings)
  );  

} catch (error) {
  console.log(error);


//   Store.removeNotification(loadingid)

  Store.addNotification({
    title: "Something Wrong",
    message:`${error.response.data.message}`,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      // onScreen: true,
    },
  });
  console.log(error);
  // dispatch(
  //   uiActions.showNotification({
  //     status: "error",
  //     title: "Error!",
  //     message: "Sending cart data failed!",
  //   })
  // );
}

  }

}
export const getallgroupsdb = (token,id,role,navigate,setCookie) => {
  return async (dispatch) => {
    console.log(token)
    let loadingid=''
    const sendRequest = async () => {

      const response = await axios({
        method: "GET",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/groups/all_group/${id}`,
        headers:{
            jwt:token            
        }
      });
      return response;
      // if (response.data.status !== "success") {
        //   throw new Error("Sending cart data failed.");
        // }
      };
      
      try {
      let response= await sendRequest();
      console.log(response)
      let allitems=response.data.data.map(item=>{
        return  axios({
          method: "GET",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/items/all_item/${item.id}`,
          headers:{
              jwt:token            
          }
        });        
      })
      allitems=await Promise.all(allitems);
      console.log('allitems')
      console.log(allitems)
      allitems.length>0&&console.log('allitaaaaa')
      let allmembers=[];
      let allitemsss=[];
      allitems.map(item=>{
        item.data.data.map(items=>{
          allmembers.push(axios({
            method: "GET",
            url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/item/members/getitemby/${items.id}`,
          }))
          allitemsss.push(items.id);
        })
      })
      let olo=await Promise.all(allmembers)
      console.log(olo)
      allmembers=[]
      olo.map(item=>{
        item.data===''?allmembers.push([]):allmembers.push(item.data.message)
      })
      let okarray=[]
      for(let i=0;i<allitemsss.length;i++){
        okarray.push({itemid:allitemsss[i],members:allmembers[i]})
      }
      console.log('okarray');
      console.log(okarray);


      let finalgroupsdata=[...response.data.data]
      finalgroupsdata=finalgroupsdata.map((item,i)=>{
      item.items=allitems[i].data.data
      return item
      })
      let onyt=[]
      finalgroupsdata.map(item=>{
        item.items.map(items=>{
          if(items.status==='0'){
            onyt.push(items)
          }
        }
      )    
      })
      console.log('thissssssssss')
      console.log(onyt)
      if(onyt.length>0){
        dispatch(
          workspaceActions.setRestoreStatus('ok')
        ); 
      }else{
        dispatch(
          workspaceActions.setRestoreStatus('nope')
        );
      }
      finalgroupsdata = finalgroupsdata.map(item=>{
        item.items= item.items.map(items=>{
          okarray.map(itemss=>{
            if(String(items.id)===String(itemss.itemid)){
              items.members= itemss.members
            }
          })
          return items;
        })
        return item;
      })
      dispatch(
        workspaceActions.updategroups(finalgroupsdata)
      );
      if(role==='company'){
        const allinmembers = await axios({
          method: "GET",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/companies/getboard/${id}`,
          headers:{
              jwt:token            
          }
        });
        console.log('-----------oooooooooooooooooooo--------------')
        // console.log(finalgroupsdata[0].board_id);
        let nonaccepties=allinmembers.data.data.filter(item=>{
          if(item.status==='0'){
          return true
          }
          return false
        })
        allinmembers.data.data=allinmembers.data.data.filter(item=>{
          if(item.status==='1'){
          return true
          }
          return false
        })
        let allmembers= allinmembers.data.data.map(item=>{
          return item.member_id          
        })
        // console.log()
        allmembers = [...new Set(allmembers)]
        allmembers =allmembers.map(item=>{
          return axios({
            method: "GET",
            url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/members/getmember/${item}`,
            headers:{
                jwt:token            
            }
          });
        })
        allmembers = await Promise.all(allmembers);
        console.log(allmembers)
        allmembers = allmembers.map(item=>{
          return item.data.data
        })
        console.log(allmembers)
        console.log(nonaccepties)
        dispatch(
          workspaceActions.setmemberinboard(allmembers)
        );
        dispatch(
          workspaceActions.setmemberinboardpendings(nonaccepties)
        );
        
        console.log('-----------oooooooooooooooooooo--------------')
      }
      dispatch(
        workspaceActions.updatelatestgroup(finalgroupsdata.length>0?finalgroupsdata[0]:false)
      );

      dispatch(workspaceActions.selectgroups(finalgroupsdata.length>0?finalgroupsdata[0]:false));

      //   var today = new Date();
    //   var tomorrow = new Date();
    //   tomorrow.setDate(today.getDate()+1);
    //   setCookie("Token", `${response.data.message.remember_token}`, { path: "/",expires: tomorrow });
    //   dispatch(
    //     signinActions.signinuser({
    //       email: '',
    //       password: ''
    //     })
    //   );
    //   Store.removeNotification(loadingid)
      // Store.addNotification({
      //   title: "Get All",
      //   type: "success",
      //   insert: "top",
      //   container: "top-right",
      //   animationIn: ["animate__animated", "animate__fadeIn"],
      //   animationOut: ["animate__animated", "animate__fadeOut"],
      //   dismiss: {
      //     duration: 5000,
      //   },
      // });
    } catch (error) {
      console.log(error);


    //   Store.removeNotification(loadingid)

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  };
};
export const addgroup= (reqid,groupname,token,workspace)=>{
  return async (dispatch) => {

  let loadingid=''
  try {
      loadingid = Store.addNotification({
          title: "Craeting...",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          slidingExit:{
            timingFunction:'linear'
          }
      });
        console.log('Loading id',loadingid)

      let datatwo = new FormData()
      datatwo.append('name',groupname)
      datatwo.append('board_id',reqid)
      
      const responsetwo = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/groups/create`,
        data:datatwo,
        headers:{
          jwt:token
        }
      });
      console.log(responsetwo)

      dispatch(workspaceActions.newitemintogroup(true));
      dispatch(
        workspaceActions.addgroupmodal({
          showaddGroup: !workspace.buttonclicks.showaddGroup,
        })
      );
      Store.removeNotification(loadingid)
      Store.addNotification({
        title: "Group Created Successfully!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });

  } catch (error) {
      console.log(error);


      Store.removeNotification(loadingid)

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  }
}
export const checkinvitedb = (data)=>{
  return async (dispatch) => {
    let loadingid=''
    try {        
        const responsetwo = await axios({
          method: "GET",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/members/${data.secretno}`,
        });
        console.log(responsetwo);
        const responsetwoo = await axios({
          method: "GET",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/members/email/${responsetwo.data.data.email}`,
        });
        console.log(responsetwoo);
        if(responsetwoo.data.message==='Already Exists!'){
          const responsetwooo = await axios({
            method: "GET",
            url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/role/check/${responsetwo.data.data.email}`,
          });
          var today = new Date();
          var tomorrow = new Date();
          tomorrow.setDate(today.getDate()+100);
          data.setCookie('Token',`${responsetwooo.data.message.remember_token}`, { path: "/",expires: tomorrow })
          setTimeout(()=>{
            data.navigate('/workspace')          
          },2000)
          return;
        }
        if(responsetwo.data.data===null){
          dispatch(
            workspaceActions.changeInviteStatus({
              changeInviteStatus:         {
                status:'Invalid Invitation Link',
                color:"#ff3041"
            },
            })
          );
        }
        if(responsetwo.data.data.status==='1'){
          dispatch(
            workspaceActions.changeInviteStatus({
              changeInviteStatus:         {
                status:'Invalid Invitation Link',
                color:"#ff3041"
            },
            })
          );
        }else{
          data.setCookie("SendInvite", "ok");
          console.log(responsetwo)
          dispatch(
            signupActions.signupuser({
              email: responsetwo.data.data.email,
            })
          );
          dispatch(
            signupActions.signupuser({
              secretno: responsetwo.data.data.secret_code,
            })
          );
          data.navigate('/memberpassword')
        }
        // dispatch(workspaceActions.newitemintogroup(true));
        // dispatch(
        //   workspaceActions.addgroupmodal({
        //     showaddGroup: !workspace.buttonclicks.showaddGroup,
        //   })
        // );

  
    } catch (error) {
        console.log(error);
  
  
  
        Store.addNotification({
          title: "Something Wrong",
          message:`${error.response.data.message}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            // onScreen: true,
          },
        });
        console.log(error);
        // dispatch(
        //   uiActions.showNotification({
        //     status: "error",
        //     title: "Error!",
        //     message: "Sending cart data failed!",
        //   })
        // );
      }    
  }
}
export const getallmemberworkspacesdb = (token)=>{
  return async (dispatch) => {
    let loadingid=''
    try {
        const responsetwo = await axios({
          method: "GET",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/workspaces/all_workspace/member`,
          headers:{
            jwt:token
          }
        });

        console.log('------------------HAHAHHA-------------------')
        let allmemberboards= JSON.parse(JSON.stringify(responsetwo));
        console.log(allmemberboards)
        







        let memberid=''
        let allboards=allmemberboards.data.data.map(item=>{
          memberid= item.member_id;
          return item.board_id          
        })
        const responsetwoq = await axios({
          method: "GET",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/members/getmember/${memberid}`,
          headers:{
            jwt:token
          }
        });
        dispatch(
          workspaceActions.updatemyoperatemember(String(responsetwoq.data.data.operatemember))
        );
        console.log('responsetwoq')
        console.log(responsetwoq)
        allboards= [...new Set(allboards)]
        allboards = allboards.map(item=>{
          return axios({
            method: "GET",
            url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/boards/getboard/${item}`,
            headers:{
              jwt:token
            }
          });
        })
        allboards = await Promise.all(allboards)
        console.log(allboards)
        if(allboards.length>0){

          allboards = allboards.map(item=>{
            return item.data.data[0]
          })
          allboards = allboards.filter(item=>{
            if(item===undefined){
              return false;
            }
            return true;
          })
          allboards = allboards.filter(item=>{
            if(item.status==='1'){
              return true;
            }return false;
          })        
          dispatch(
            workspaceActions.updatememberboards(allboards)
          );
        }else{
          dispatch(
            workspaceActions.updatememberboards([])
          );
        }












        let allworkspaces=responsetwo.data.data.map(item=>{
          return item.workspace_id          
        })
        allworkspaces= [...new Set(allworkspaces)]
        allworkspaces = allworkspaces.map(item=>{
          return axios({
            method: "GET",
            url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/workspaces/getworkspace/${item}`,
            headers:{
              jwt:token
            }
          });
        })
        allworkspaces = await Promise.all(allworkspaces)
        console.log(allworkspaces)
        allworkspaces = allworkspaces.map(item=>{
          return item.data.data[0]
        })
        allworkspaces = allworkspaces.filter(item=>{
          if(item.status==='1'){
            return true;
          }return false;
        })        
        dispatch(
          workspaceActions.updateworkspaces(allworkspaces)
        );
        dispatch(
          workspaceActions.updatelatestworkspace(allworkspaces.length>0?allworkspaces[0]:false)
        );
  
        dispatch(workspaceActions.selectworkspaces(allworkspaces.length>0?allworkspaces[0]:false));
        console.log('-------------------HAHAHAH------------------')
        // dispatch(
        //   workspaceActions.changeworkspacerole({
        //     workspacerole: responsetwo.data.Role,
        //   })
        // );
        
        // dispatch(workspaceActions.newitemintogroup(true));
        // dispatch(
        //   workspaceActions.addgroupmodal({
        //     showaddGroup: !workspace.buttonclicks.showaddGroup,
        //   })
        // );
        // Store.removeNotification(loadingid)
        // Store.addNotification({
        //   title: "Invite Sent Successfully!",
        //   type: "success",
        //   insert: "top",
        //   container: "top-right",
        //   animationIn: ["animate__animated", "animate__fadeIn"],
        //   animationOut: ["animate__animated", "animate__fadeOut"],
        //   dismiss: {
        //     duration: 5000,
        //   },
        // });
  
    } catch (error) {
        console.log(error);
  
  
        Store.removeNotification(loadingid)
  
        Store.addNotification({
          title: "Something Wrong",
          message:`${error.response.data.message}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            // onScreen: true,
          },
        });
        console.log(error);
        // dispatch(
        //   uiActions.showNotification({
        //     status: "error",
        //     title: "Error!",
        //     message: "Sending cart data failed!",
        //   })
        // );
      }
    }  
}
export const SaveItemIntoChecklist = (id,val,workspace)=>{
  return async (dispatch) => {
    try {
      let data= new FormData();
      let DummyData={
        id:Math.random(),
        item_name:val,
        checklist_id:id,
        status:0
      }
      let workspacedata=JSON.parse(JSON.stringify(workspace.useablevariables.itemDetails.checklist));
      workspacedata = workspacedata.map(item=>{
        if(Number(item.id)===Number(id)){
          item.additem.push(DummyData);
          return item;
        }
        return item
      })
      dispatch(
        workspaceActions.setItemDetails({
          itemDetails: JSON.parse(
            JSON.stringify({
              ...JSON.parse(
                JSON.stringify(
                  workspace.useablevariables.itemDetails
                )
              ),
              checklist: workspacedata,
            })
          ),
        })
      );
      data.append('item_name',val)
      data.append('checklist_id',id)
      const responsetwo = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/checklist/item/additemintochecklist`,
        data,
      });
      console.log(responsetwo)


      console.log('TotalDetails')
      // console.log(TotalDetails)
      workspacedata = workspacedata.map(item=>{
        let okit=false;
        item.additem = item.additem.filter(itemss=>{
          console.log(Number(itemss.id),Number(DummyData.id))
          if(Number(itemss.id)===Number(DummyData.id)){
            console.log('ajajjkakajkja')
            okit=true;
            return false;
          }
          return true;
        })
        if(okit){
          console.log('ajajjkakajkja')
          item.additem.push(responsetwo.data.data)
          okit=false;
        }
        return item;
      })



      dispatch(
        workspaceActions.setItemDetails({
          itemDetails: JSON.parse(
            JSON.stringify({
              ...workspacedata,
              checklist: workspacedata,
            })
          ),
        })
      );
    }catch (error) {
      console.log(error);

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  }
}
export const updateStatusOfCheckListItem= (id,workspace)=>{
  return async (dispatch) => {
    try {

      const responsetwo = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/checklist/item/change/status/${id}`,
      });
      console.log(responsetwo)      
    }catch (error) {
      console.log(error);

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  }
}
export const CreateCheckList= (id,val,workspace)=>{
  return async (dispatch) => {
    try {
      let data= new FormData();
      let DummyData={
        id:Math.random(),
        name:val,
        additem:[]
      }
      let workspacedata=JSON.parse(JSON.stringify(workspace.useablevariables.itemDetails.checklist));
      workspacedata.push(DummyData);
      dispatch(
        workspaceActions.setItemDetails({
          itemDetails: JSON.parse(
            JSON.stringify({
              ...JSON.parse(
                JSON.stringify(
                  workspace.useablevariables.itemDetails
                )
              ),
              checklist: workspacedata,
            })
          ),
        })
      );
      data.append('name',val)
      data.append('item_id',id)
      const responsetwo = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/checklist/item/create`,
        data,
      });
      workspacedata = workspacedata.filter(item=>{
         if(Number(item.id)===Number(DummyData.id)){
          return false;
         }
         return true
      })
      workspacedata.push(responsetwo.data.data)
console.log(workspacedata)

      dispatch(
        workspaceActions.setItemDetails({
          itemDetails: JSON.parse(
            JSON.stringify({
              ...workspacedata,
              checklist: workspacedata,
            })
          ),
        })
      );
      console.log(responsetwo)
    }catch (error) {
      console.log(error);

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  }
}
export const getroledb = (token)=>{  
  return async (dispatch) => {

    let loadingid=''
    try {
        const responsetwo = await axios({
          method: "GET",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/members/invite`,
          headers:{
            jwt:token
          }
        });
        const responsetwopo = await axios({
          method: "GET",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/role`,
          headers:{
            jwt:token
          }
        });
        console.log(responsetwo)
        dispatch(
          workspaceActions.changeworkspacerole({
            workspacerole: responsetwopo.data.Role,
          })
        );
        
        // dispatch(workspaceActions.newitemintogroup(true));
        // dispatch(
        //   workspaceActions.addgroupmodal({
        //     showaddGroup: !workspace.buttonclicks.showaddGroup,
        //   })
        // );
        Store.removeNotification(loadingid)
        // Store.addNotification({
        //   title: "Invite Sent Successfully!",
        //   type: "success",
        //   insert: "top",
        //   container: "top-right",
        //   animationIn: ["animate__animated", "animate__fadeIn"],
        //   animationOut: ["animate__animated", "animate__fadeOut"],
        //   dismiss: {
        //     duration: 5000,
        //   },
        // });
  
    } catch (error) {
        console.log(error);
  
  
        Store.removeNotification(loadingid)
  
        Store.addNotification({
          title: "Something Wrong",
          message:`${error.response.data.message}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            // onScreen: true,
          },
        });
        console.log(error);
        // dispatch(
        //   uiActions.showNotification({
        //     status: "error",
        //     title: "Error!",
        //     message: "Sending cart data failed!",
        //   })
        // );
      }
    }
}
export const seninvitedb = (token,data,workspace)=>{
  return async (dispatch) => {

  let loadingid=''
  try {
      loadingid = Store.addNotification({
          title: "Sending...",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          slidingExit:{
            timingFunction:'linear'
          }
      });
      console.log('Loading id',loadingid)

      let datatwo = new FormData()
      datatwo.append('board_name',data.groupname)
      datatwo.append('board_id',data.groupid)
      datatwo.append('workspace_id',data.workspaceid)
      datatwo.append('email',data.value)
      datatwo.append('link','https://buzzuperp.dejuretechnologies.com/acceptinvite/')


      const responsetwo = await axios({
        method: "POST",
        url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/members/invite`,
        data:datatwo,
        headers:{
          jwt:token
        }
      });
      console.log(responsetwo)
      if(!responsetwo.data.data){
        Store.removeNotification(loadingid)
        Store.addNotification({
          title: responsetwo.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });

        return;        
      }
      dispatch(
        workspaceActions.addinvitemodal({
          showaddinvite: !workspace.buttonclicks.showaddinvite,
        })
      );
      // dispatch(workspaceActions.newitemintogroup(true));
      // dispatch(
      //   workspaceActions.addgroupmodal({
      //     showaddGroup: !workspace.buttonclicks.showaddGroup,
      //   })
      // );
      Store.removeNotification(loadingid)
      Store.addNotification({
        title: "Invite Sent Successfully!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });

  } catch (error) {
      console.log(error);


      Store.removeNotification(loadingid)

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  }
}
export const addboard= (reqid,boardname,token,workspace)=>{
    return async (dispatch) => {

    let loadingid=''
    try {
        loadingid = Store.addNotification({
            title: "Craeting...",
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            slidingExit:{
              timingFunction:'linear'
            }
        });
          console.log('Loading id',loadingid)

        let datatwo = new FormData()
        datatwo.append('name',boardname)
        datatwo.append('workspace_id',reqid)
        
        const responsetwo = await axios({
          method: "POST",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/boards/create`,
          data:datatwo,
          headers:{
            jwt:token
          }
        });
        console.log(responsetwo)

        dispatch(workspaceActions.newitemintoboard(true));
        dispatch(
          workspaceActions.addboardmodal({
            showaddBoard: !workspace.buttonclicks.showaddBoard,
          })
        );
        Store.removeNotification(loadingid)
        Store.addNotification({
          title: "Board Created Successfully!",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
 
    } catch (error) {
        console.log(error);
  
  
        Store.removeNotification(loadingid)
  
        Store.addNotification({
          title: "Something Wrong",
          message:`${error.response.data.message}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            // onScreen: true,
          },
        });
        console.log(error);
        // dispatch(
        //   uiActions.showNotification({
        //     status: "error",
        //     title: "Error!",
        //     message: "Sending cart data failed!",
        //   })
        // );
      }
    }
}
export const addworkspaceandgroup = (reqdata,token,workspace)=>{
    return async (dispatch) => {

    let loadingid=''
    try {
        loadingid = Store.addNotification({
            title: "Creating...",
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            slidingExit:{
              timingFunction:'linear'
            }
        });
          console.log('Loading id',loadingid)
          let data = new FormData()
          data.append('name',reqdata.workspacename)
        const response = await axios({
            method: "POST",
            url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/workspaces/create`,
            data,
            headers:{
              jwt:token
            }
        });
        console.log(response)
        let datatwo = new FormData()
        datatwo.append('name',reqdata.boardname)
        datatwo.append('workspace_id',response.data.data.id)
        
        const responsetwo = await axios({
          method: "POST",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/boards/create`,
          data:datatwo,
          headers:{
            jwt:token
          }
        });
        console.log(responsetwo)
        dispatch(workspaceActions.newitemintoworkspace(true));
        Store.removeNotification(loadingid)
        dispatch(
          workspaceActions.addboardmodal({
            showaddBoard: !workspace.buttonclicks.showaddBoard,
          })
        );
        Store.addNotification({
          title: "Workspace Created Successfully!",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
 
    } catch (error) {
        console.log(error);
  
  
        Store.removeNotification(loadingid)
  
        Store.addNotification({
          title: "Something Wrong",
          message:`${error.response.data.message}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            // onScreen: true,
          },
        });
        console.log(error);
        // dispatch(
        //   uiActions.showNotification({
        //     status: "error",
        //     title: "Error!",
        //     message: "Sending cart data failed!",
        //   })
        // );
      }
    }
}
export const getallmemberStatusItem = (ids,workspace) => {
  return async (dispatch) => {
    console.log(ids,workspace.latestitem.id);
    let getResults = function(){
      let allreesults=[];
      ids.map(item=>{        
        allreesults.push(axios({
          method: "GET",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/item/members/check/status/${workspace.latestitem.id}/${item}`,
        }))
      })
      return allreesults;
    }
    try{
      let results=getResults();
      let result = await Promise.all(results);
      let ontos=[]      
      console.log('hahjahahhahjahjahahjahaah')
      console.log(result)
      result.map((item,i)=>{
        ontos.push({status:item.data.status,member:ids[i]})
      })
      dispatch(
        workspaceActions.updateChecksOfItems(ontos)
      );
    }catch(error){
      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
    }
    
  }
}
export const getallboardsdb = (token,id,role,memberboards,navigate,setCookie) => {
    return async (dispatch) => {
      console.log(token)
      let loadingid=''
      const sendRequest = async () => {
        // loadingid = Store.addNotification({
        //   title: "Loading...",
        //   type: "warning",
        //   insert: "top",
        //   container: "top-right",
        //   animationIn: ["animate__animated", "animate__fadeIn"],
        //   animationOut: ["animate__animated", "animate__fadeOut"],
        //   slidingExit:{
        //     timingFunction:'linear'
        //   }
        // });
        // console.log('Loading id',id)
  
        const response = await axios({
          method: "GET",
          url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/boards/all_board/${id}`,
          headers:{
              jwt:token            
          }
        });
        
        return response;
        // if (response.data.status !== "success") {
          //   throw new Error("Sending cart data failed.");
          // }
        };
        
        try {
        let response= await sendRequest();
        console.log(response)
        let avm=undefined;
        if(role==='member'){
          avm=[];
          response.data.data = response.data.data.map(item=>{

            memberboards.map(item2=>{
              if(Number(item2.id)===Number(item.id)){
                avm.push(item)
              }
            })
          })
        }
if(avm===undefined){
  dispatch(
    workspaceActions.updateboards(response.data.data)
  );
  dispatch(
      workspaceActions.updatelatestboard(response.data.data.length>0?response.data.data[0]:'No Board')
  );
  dispatch(workspaceActions.selectboards(response.data.data.length>0?response.data.data[0]:'No Board'));
}else{
  console.log('----------avm---------')
  console.log(avm)
  console.log('----------avm---------')
  dispatch(
    workspaceActions.updateboards(avm)
  );
  dispatch(
      workspaceActions.updatelatestboard(avm.length>0?avm[0]:'No Board')
  );
  dispatch(workspaceActions.selectboards(avm.length>0?avm[0]:'No Board'));
}


        //   var today = new Date();
      //   var tomorrow = new Date();
      //   tomorrow.setDate(today.getDate()+1);
      //   setCookie("Token", `${response.data.message.remember_token}`, { path: "/",expires: tomorrow });
      //   dispatch(
      //     signinActions.signinuser({
      //       email: '',
      //       password: ''
      //     })
      //   );
        Store.removeNotification(loadingid)
        // Store.addNotification({
        //   title: "Get All Board",
        //   type: "success",
        //   insert: "top",
        //   container: "top-right",
        //   animationIn: ["animate__animated", "animate__fadeIn"],
        //   animationOut: ["animate__animated", "animate__fadeOut"],
        //   dismiss: {
        //     duration: 5000,
        //   },
        // });
      } catch (error) {
        console.log(error);
  
  
        Store.removeNotification(loadingid)
        if(error.response.data.message==="You are not Logged In!"){
          var today = new Date();
          var tomorrow = new Date();
          tomorrow.setDate(today.getDate() - 1);
          setCookie("Token", `logout`, {
            path: "/",
            expires: tomorrow,
          });
          navigate('/')
        }
        Store.addNotification({
          title: "Something Wrong",
          message:`${error.response.data.message}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            // onScreen: true,
          },
        });
        console.log(error);
        // dispatch(
        //   uiActions.showNotification({
        //     status: "error",
        //     title: "Error!",
        //     message: "Sending cart data failed!",
        //   })
        // );
      }
    };
  };