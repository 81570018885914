import * as React from "react";

import classes from "./WorkspaceGroupSection.module.css";
import { WorkspaceGroupItem } from "./WorkspaceGroupItem";
import { WorkspaceGroupAddItem } from "./WorkspaceGroupAddItem";
import { WorkspaceColorModal } from "./WorkspaceColorModal";
import { WorkspaceChatModal } from "./WorkspaceChatModal";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { workspaceActions } from "../../store/Workspace/workspace-slice";
import { getalltimesdb } from "../../store/Workspace/workspace-actions";
import dots from "../../images/workspace/dots.png";
import { useCookies } from "react-cookie";

export const WorkspaceGroupSection = React.memo((props) => {
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);
  const [cookies, setCookie] = useCookies(["user"]);
  let [trash, setTrash] = useState(false);
  const gettimes = (id) => {
    console.log(id);
    let ids = [];
    let dummyworkspace = JSON.parse(JSON.stringify(workspace));
    dummyworkspace.groups.map((item) => {
      if (Number(item.id) === Number(id)) {
        item.items.map((items) => {
          ids.push(items.id);
        });
      }
    });
    console.log(ids);
    dispatch(getalltimesdb(cookies.Token, ids, workspace));
    dispatch(
      workspaceActions.addtimemodal({
        showaddtimes: !workspace.buttonclicks.showaddtimes,
      })
    );
  };
  const closestatusmodal = () => {
    console.log("ahah");
    dispatch(
      workspaceActions.addstatusmodal({
        showaddstatus: !workspace.buttonclicks.showaddstatus,
      })
    );
  };

  return (
    <>
      {workspace.useablevariables.TaskSection === "main" && (
        <>
          <div className="row align-items-center my-3">
            <div className="col-md-7 d-flex justify-content-between mb-3">
              <div className="d-flex align-items-center">
                <img src={dots} className={classes.dots} alt="" />
                <p className={`${classes.mainheading} ${classes.bgblue} m-0`}>
                  {props.item.name}
                </p>
              </div>
              <div
                className={`d-flex ${classes.widper} justify-content-between`}
              >
                <div className="d-flex align-items-center">
                  <p
                    className={`${classes.mainheading} ${classes.taskid} m-0 bg-gray`}
                  >
                    Task Id
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <img
                    src={dots}
                    className={`${classes.dots}`}
                    onClick={gettimes.bind(null, props.item.id)}
                    alt=""
                  />
                  <p className={`${classes.mainheading} m-0 bg-gray`}>Time</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className={`${classes.mainheading}  m-0 bg-gray`}>Chat</p>
                </div>
              </div>
            </div>
            <div className="col-md-5 d-flex justify-content-between mb-3">
              <div className={`d-flex align-items-center`}>
                <p className={`${classes.mainheading} m-0`}>Person</p>
              </div>
              <div className="d-flex align-items-center">
                <p
                  className={`${classes.mainheading} ${classes.taskid} m-0 bg-gray`}
                >
                  Status
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className={`${classes.mainheading} m-0 bg-gray`}>Date</p>
              </div>
            </div>
          </div>
          {props.item.items.length > 0 &&
            props.item.items.map(
              (item, index) =>
                item.status === "1" && (
                  <WorkspaceGroupItem key={item.id} item={item} index={index} />
                )
            )}
        </>
      )}

      {workspace.useablevariables.TaskSection !== "main" && (
        <>
          {/* {workspace.useablevariables.TaskDelCounts.length > 0 && (
            <div
              className="d-flex justify-content-between px-4 py-2 align-items-center mb-3"
              style={{ backgroundColor: "#a1c1ef" }}
            >
              <h4 style={{ marginBottom: "2px", marginLeft: "-12px" }}>
                <b>Action</b>
              </h4>
              <button
                className="btn btn-danger"
                style={{ marginBottom: "2px", marginRight: "-12px" }}
                onClick={deleteTasksHandler}
              >
                Restore Tasks ({workspace.useablevariables.TaskDelCounts.length}
                )
              </button>
            </div>
          )} */}

          {props.item.items.length > 0 &&
            props.item.items.map(
              (item, index) =>
                item.status === "0" && (
                  <WorkspaceGroupItem key={item.id} item={item} index={index} />
                )
            )}
          {/* {!trash ? setTrash(true) : <h1>Trash is empty!</h1>} */}
        </>
      )}
      {workspace.useablevariables.TaskSection === "main" && (
        <WorkspaceGroupAddItem item={props.item} />
      )}
      {/* <WorkspaceGroupItem /> */}
      {/* <WorkspaceGroupItem /> */}

      {/* <WorkspaceChatModal/> */}

      {/* <WorkspaceTimeModal /> */}

      {workspace.buttonclicks.showaddstatus && (
        <WorkspaceColorModal onclose={closestatusmodal} />
      )}
    </>
  );
});
