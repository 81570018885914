import classes from "../../pages/Workspace/base.module.css";
import { Store } from "react-notifications-component";

import logo from "../../images/Base/Logo.png";

import user from "../../images/Base/user.png";
import userbg from "../../images/Base/iconsbackground/userbg.png";
import file from "../../images/Base/file.png";
import filebg from "../../images/Base/iconsbackground/filebg.png";
import gallary from "../../images/Base/gallary.png";
import gallarybg from "../../images/Base/iconsbackground/gallarypng.png";
import question from "../../images/Base/question.png";
import questionbg from "../../images/Base/iconsbackground/questionpng.png";
import userplus from "../../images/Base/userplus.png";
import userplusbg from "../../images/Base/iconsbackground/userplusbg.png";
import fileups from "../../images/Base/fileups.png";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { workspaceActions } from "../../store/Workspace/workspace-slice";
import { useSelector, useDispatch } from "react-redux";

import fileupsbg from "../../images/Base/iconsbackground/fileupsbg.png";
import clock from "../../images/Base/clock.png";
import clockbg from "../../images/Base/iconsbackground/clockbg.png";
import logout from "../../images/workspace/logout.png";

export const BaseSidebar = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);
  const logoutHandler = () => {
    if (workspace.useablevariables.Totalons > 0) {
      dispatch(
        workspaceActions.showtimewarning({
          showtimewarning: !workspace.buttonclicks.showtimewarning,
        })
      );
    } else {
      dispatch(
        workspaceActions.addshowmemberin({
          setmemberinboard: [],
        })
      );
      var today = new Date();
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate() - 1);
      setCookie("Token", `logout`, {
        path: "/",
        expires: tomorrow,
      });

      Store.addNotification({
        title: "Logout Successfull",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
      navigate("/");
    }
  };
  return (
    <div className="col-md-1 m-0 p-0 ">
      <div className={`${classes.sidebar}  text-center`}>
        <img src={logo} className={classes.logo} alt="" />
        <img
          src={user}
          className={classes.sideitems}
          alt=""
          onMouseOver={(e) => (e.currentTarget.src = userbg)}
          onMouseOut={(e) => (e.currentTarget.src = user)}
        />
        <img
          src={file}
          className={classes.sideitems}
          alt=""
          onMouseOver={(e) => (e.currentTarget.src = filebg)}
          onMouseOut={(e) => (e.currentTarget.src = file)}
        />
        <img
          src={gallary}
          className={classes.sideitems}
          alt=""
          onMouseOver={(e) => (e.currentTarget.src = gallarybg)}
          onMouseOut={(e) => (e.currentTarget.src = gallary)}
        />
        <img
          src={question}
          className={classes.sideitems}
          alt=""
          onMouseOver={(e) => (e.currentTarget.src = questionbg)}
          onMouseOut={(e) => (e.currentTarget.src = question)}
        />
        <img
          src={userplus}
          style={{ width: "40px", marginLeft: "10px" }}
          className={classes.sideitems}
          alt=""
          onMouseOver={(e) => (e.currentTarget.src = userplusbg)}
          onMouseOut={(e) => (e.currentTarget.src = userplus)}
        />
        {/* <img
          src={fileups}
          className={classes.sideitems}
          alt=""
          onMouseOver={(e) => (e.currentTarget.src = fileupsbg)}
          onMouseOut={(e) => (e.currentTarget.src = fileups)}
        />
        <img
          src={clock}
          className={classes.sideitems}
          alt=""
          onMouseOver={(e) => (e.currentTarget.src = clockbg)}
          onMouseOut={(e) => (e.currentTarget.src = clock)}
        /> */}
        <img
          src={logout}
          className={classes.sideitems}
          style={{ marginLeft: "10px" }}
          onClick={logoutHandler}
          alt=""
        />
      </div>
    </div>
  );
};
