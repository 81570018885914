import React, { useState } from "react";
import "./middle.css";
//COMPINENTS START
import AdvantageItems from "./AdvantageItems";

//COMPINENTS END
import advantageimageone from "../../images/export/Group1.png";
import advantageimagethree from "../../images/export/Group3.png";
import advantageimagefour from "../../images/export/Group4.png";
import advantageimagefive from "../../images/export/Group5.png";
import advantageimagesix from "../../images/export/Group6.png";
import advantageimageseven from "../../images/export/Group7.png";
import advantageimageeight from "../../images/export/Group8.png";
import advantageimagenine from "../../images/export/Group9.png";
import streamlineImg from "../../images/slider1.png";
import quoteImg from "../../images/icons/quote.png";
import Avatar from "react-avatar";
import profile from "../../images/avatar.png";
import testimonialBack1 from "../../images/testimonialBack.png";
import testimonialBack2 from "../../images/testimonialBack2.png";
import circleBack from "../../images/circleBack.png";
import boards from "../../images/icons/boardsIcon.png";
import dashboard from "../../images/icons/dashboardIcon.png";
import views from "../../images/icons/viewsIcon.png";
import integration from "../../images/icons/integrationIcon.png";
import docs from "../../images/icons/docsIcon.png";
import automation from "../../images/icons/animationIcon.png";
import boardsBlue from "../../images/icons/boardsBlue.png";
import dashboardBlue from "../../images/icons/dashboardBlue.png";
import viewsBlue from "../../images/icons/viewsBlue.png";
import integrationBlue from "../../images/icons/integrationBlue.png";
import docsBlue from "../../images/icons/docsBlue.png";
import automationBlue from "../../images/icons/automationBlue.png";
import boardIcon from "../../images/icons/boardIcon.png";
import supportingImg from "../../images/supportingImg.png";
import callIcon from "../../images/icons/call.png";
import favouriteIcon from "../../images/icons/favourite.png";
import clockIcon from "../../images/icons/clock.png";
import { NewsLetter } from "../NewsLetter/NewsLetter";
import { Footer } from "../Footer/Footer";
import teamImg from "../../images/teamImg.png";
import { useSpring, animated } from "react-spring";

const workflow = [
  {
    key: "Boards",
    value:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of printing and typesetting industry. Ipsum has been the industry's Lorem Ipsum is simply printing.",
    key2: "Dashboard",
    value2:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of printing and typesetting industry. Ipsum has been the industry's Lorem Ipsum is simply printing.",
    key3: "Views",
    value3:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of printing and typesetting industry. Ipsum has been the industry's Lorem Ipsum is simply printing.",
    key4: "Integration",
    value4:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of printing and typesetting industry. Ipsum has been the industry's Lorem Ipsum is simply printing.",
    key5: "Docs",
    value5:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of printing and typesetting industry. Ipsum has been the industry's Lorem Ipsum is simply printing.",
    key6: "Automation",
    value6:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of printing and typesetting industry. Ipsum has been the industry's Lorem Ipsum is simply printing.",
  },
];
const states = [
  {
    config: { duration: 1250 },
    from: { opacity: 0.2 },
    to: { opacity: 1 },
  },
  {
    config: { duration: 1250 },
    from: { opacity: 0.2 },
    to: { opacity: 1 },
  },
];

export const HomeMiddle = () => {
  const [state, setState] = useState(1);
  const [toggle, setToggle] = useState(false);
  const [props, set] = useSpring(() => ({
    ...states[+toggle],
  }));
  const {
    key,
    value,
    key2,
    value2,
    key3,
    value3,
    key4,
    value4,
    key5,
    value5,
    key6,
    value6,
  } = workflow[0];
  let advantages = [
    {
      photo: advantageimageone,
      name: "Digital Forms And Invoices With A Click",
      description:
        "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum",
    },
    {
      photo: advantageimagethree,
      name: "Receive Payments By Beat",
      description:
        "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum",
    },

    {
      photo: advantageimagefour,
      name: "Credit Clearing",
      description:
        "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum",
    },
    {
      photo: advantageimagefive,
      name: "Donation Pages",
      description:
        "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum",
    },
    {
      photo: advantageimagesix,
      name: "Sales Pages",
      description:
        "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum",
    },
    {
      photo: advantageimagesix,
      name: "Standing Orders",
      description:
        "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum",
    },
    {
      photo: advantageimageseven,
      name: "Digital Stores",
      description:
        "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum",
    },
    {
      photo: advantageimageeight,
      name: "Wordpress Supplements",
      description:
        "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum",
    },
    {
      photo: advantageimagenine,
      name: "Customer Service",
      description:
        "Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum",
    },
  ];
  return (
    <div className="middle-main">
      <div className="row" style={{ paddingTop: "200px", color: "white" }}>
        {advantages.map((item) => (
          <AdvantageItems
            photo={item.photo}
            name={item.name}
            description={item.description}
          />
        ))}
      </div>
      <div className="middle-back" style={{ marginTop: "-120px" }}>
        <div className="row">
          <div className="col-lg-6 middle-content">
            <p>
              Streamline your work for <br />
              <b>Maximum productivity</b>
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, Lorem Ipsum is simply dummy text of the
              printing and typesetting industry,{" "}
            </p>
          </div>
          <div className="col-lg-6 streamline-img">
            <img alt="streamline" src={streamlineImg} />
          </div>
        </div>
      </div>
      {/* testimonial */}
      <div className="container-fluid">
        <div className="row testimonial-row">
          <div className="col-lg-6 testimonial-col1">
            <div className="testimonial-back1">
              <img alt="background" src={testimonialBack1} />
            </div>
            <div className="circle-back">
              <img alt="background" src={circleBack} />
            </div>
            <div className="testimonial1">
              <img alt="quote" src={quoteImg} />
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text Lorem Ipsum has been the industry's standard dummy text
                Lorem Ipsum has been the industry's standard dummy text{" "}
              </p>
              <div className="profile-details">
                <div className="profile-avatar">
                  <Avatar src={profile} className="avatar" />
                  <p>john smith</p>
                </div>
                <p>
                  Founder of Awesomeux
                  <br /> Technology
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 testimonial-col2">
            <div className="testimonial-back2">
              <img alt="background" src={testimonialBack2} />
            </div>
            <div className="circle-back">
              <img alt="background" src={circleBack} />
            </div>
            <div className="testimonial1">
              <img alt="quote" src={quoteImg} />
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text Lorem Ipsum has been the industry's standard dummy text
                Lorem Ipsum has been the industry's standard dummy text{" "}
              </p>
              <div className="profile-details">
                <div className="profile-avatar">
                  <Avatar src={profile} className="avatar" />
                  <p>john smith</p>
                </div>
                <p>
                  Founder of Awesomeux
                  <br /> Technology
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* team */}
      <div className="row">
        <div className="col-lg-5 middle-content2">
          <p>
            Bring teams together to <br />
            <b>drive business impact</b>
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard,
          </p>
        </div>
        <div className="col-lg-7 team-img">
          <img alt="Team" src={teamImg} />
        </div>
      </div>
      {/* reach your goals */}
      <div className="container-fluid">
        <div className="row reach-goals-row">
          <div className="col-lg-6 reach-goals">
            <p>reach your goals, faster</p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              eve r since the 1500s, Lorem Ipsum is simply dummy text of the
              printing and typesetting industry,
            </p>
            <div className="getstarted-btn3">
              <button type="submit">Get Started</button>
            </div>
          </div>
          <div className="col-lg-6 streamline-img2">
            <img alt="streamline" src={streamlineImg} />
          </div>
        </div>
      </div>
      {/* workflow */}
      <div className="workflow-content">
        <p>
          <strong>Everything</strong> you need for <strong>any workflow</strong>
        </p>
        <p>
          {" "}
          Lorem Ipsum es simplemente el texto de relleno de las imprentas y
          archivos de texto.
        </p>
        <div className="workflow-back">
          <div
            onClick={() => {
              setState(1);
              set({
                ...states[+!toggle],
              });
              setToggle(!toggle);
            }}
            className={state === 1 ? "workflow-blue" : ""}
          >
            <img alt="board img" src={state === 1 ? boardsBlue : boards} />
            <p>Boards</p>
          </div>
          <div
            onClick={() => {
              setState(2);
              set({
                ...states[+!toggle],
              });
              setToggle(!toggle);
            }}
            className={state === 2 ? "workflow-blue" : ""}
          >
            <img
              alt="dashboard img"
              src={state === 2 ? dashboardBlue : dashboard}
            />
            <p>Dashboard</p>
          </div>
          <div
            onClick={() => {
              setState(3);
              set({
                ...states[+!toggle],
              });
              setToggle(!toggle);
            }}
            className={state === 3 ? "workflow-blue" : ""}
          >
            <img alt="views img" src={state === 3 ? viewsBlue : views} />
            <p>Views</p>
          </div>
          <div
            onClick={() => {
              setState(4);
              set({
                ...states[+!toggle],
              });
              setToggle(!toggle);
            }}
            className={state === 4 ? "workflow-blue" : ""}
          >
            <img
              alt="integration img"
              src={state === 4 ? integrationBlue : integration}
            />
            <p>Integration</p>
          </div>
          <div
            onClick={() => {
              setState(5);
              set({
                ...states[+!toggle],
              });
              setToggle(!toggle);
            }}
            className={state === 5 ? "workflow-blue" : ""}
          >
            <img alt="docs img" src={state === 5 ? docsBlue : docs} />
            <p>Docs</p>
          </div>
          <div
            onClick={() => {
              setState(6);
              set({
                ...states[+!toggle],
              });
              setToggle(!toggle);
            }}
            className={state === 6 ? "workflow-blue" : ""}
          >
            <img
              alt="automation img"
              src={state === 6 ? automationBlue : automation}
            />
            <p>Automation</p>
          </div>
        </div>
      </div>
      {/* video */}
      <div className="container-fluid video-player-main">
        <div className="row">
          <div className="col-lg-7">
            <video width="100%" controls className="video-player">
              <source
                src="https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="col-lg-5">
            <div className="boards-main">
              <animated.div className="board-heading" style={props}>
                <div>
                  <img alt="icon" src={boardIcon} />
                </div>
                {state === 1 ? (
                  <p>{key}</p>
                ) : state === 2 ? (
                  <p>{key2}</p>
                ) : state === 3 ? (
                  <p>{key3}</p>
                ) : state === 4 ? (
                  <p>{key4}</p>
                ) : state === 5 ? (
                  <p>{key5}</p>
                ) : (
                  <p>{key6}</p>
                )}
              </animated.div>
              <div className="boards-content">
                {state === 1 ? (
                  <p>{value}</p>
                ) : state === 2 ? (
                  <p>{value2}</p>
                ) : state === 3 ? (
                  <p>{value3}</p>
                ) : state === 4 ? (
                  <p>{value4}</p>
                ) : state === 5 ? (
                  <p>{value5}</p>
                ) : (
                  <p>{value6}</p>
                )}
                <p>Learn more...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Supporting your growth */}
      <div className="container-fluid">
        <div className="row supporting-row">
          <div className="col-lg-6">
            <div className="supporting-heading">
              <p>
                <strong>Supporting</strong> your growth
                <br />
                every step of the way
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard Lorem
                Ipsum is simply dummy text of printing and industry's
                typesetting industry.Ipsum has been the
              </p>
            </div>
            <div className="support-flex">
              <div className="support-category">
                <img alt="call" src={callIcon} />
                <div>
                  <p>24/7</p>
                  <p>
                    Support any time,
                    <br />
                    any where
                  </p>
                </div>
              </div>
              <div className="support-category">
                <img alt="call" src={favouriteIcon} />
                <div>
                  <p>Favourite</p>
                  <p>
                    Most loved by
                    <br />
                    customers
                  </p>
                </div>
              </div>
              <div className="support-category">
                <div>
                  <img alt="call" src={clockIcon} />
                </div>
                <div>
                  <p>24/7</p>
                  <p>
                    Good response <br />
                    time
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 supporting-img">
            <img alt="streamline" src={supportingImg} />
          </div>
        </div>
      </div>
      {/* news latter */}
      <NewsLetter />
      {/* footer */}
      <Footer />
    </div>
  );
};
