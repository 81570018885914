import classes from "./MemberImage.module.css";
import { SignupDetails } from "../../components/Signupdetails/SignupDetails";

import { Link } from "react-router-dom";
import PersonalDetailsImg from "../../images/Signup/PersonalDetails.png";

import BuzzerLogo from "../../images/loginLogo.png";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { signupActions } from "../../store/MemberSignup/MemberSignup-slice";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Arrow from "../../images/ForgotPassword/ArrowOne.png";
import Lock from "../../images/ForgotPassword/Lock.png";
import cross from "../../images/blackcross.png";
import UserDefault from "../../images/Signup/user.jpeg";
import Gallary from "../../images/Signup/gallary.png";
import ContactUsImg from "../../images/contactus/ContactUsImg.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputPassword } from "../../components/InputPassword/InputPassword";
import { PhoneCountry } from "../../components/PhoneWithCountry/PhoneWithCountry";
import { Button } from "react-bootstrap";
import { sendSignupData } from "../../store/MemberSignup/MemberSignup-actions";
import { useCookies } from "react-cookie";

let isInitial = true;
export const MemberImage = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.membersignup);
  useEffect(() => {
    if (cookies.SendInvite === "ok") {
      navigate("/memberimage");
    } else {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    console.log(user);
  }, [user]);

  let [image, setImage] = useState(
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjYmlp9JDeNMaFZzw9S3G1dVztGqF_2vq9nA&usqp=CAU"
  );
  console.log(UserDefault);

  const inputRef = useRef(null);

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setImage(fileObj);

    console.log("fileObj is", fileObj);

    // 👇️ reset file input
    event.target.value = null;

    // 👇️ is now empty
    console.log(event.target.files);

    // 👇️ can still access file object here
    console.log(fileObj);
    console.log(fileObj.name);
    dispatch(
      signupActions.signupuser({
        image: fileObj,
      })
    );
    // navigate("/enddetailssignup");
  };
  let saveUserData = () => {
    dispatch(sendSignupData(user, setCookie, navigate));
  };
  // useEffect(() => {
  //   if (isInitial) {
  //     isInitial = false;
  //     return;
  //   }

  //   if (user.image) {
  //     dispatch(sendSignupData(user));
  //   }
  // }, [user, dispatch]);
  return (
    <SignupDetails>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <img src={cross} className={`${classes.imagecross}`} />
          </div>
          <div className="col-md-12 text-center ">
            <img src={BuzzerLogo} />
          </div>
          <div className="col-md-12 px-5 text-center d-flex justify-content-center">
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
            />
            <div className={`${classes.upperrelative} text-center`}>
              <div className={`${classes.bgprof}`} onClick={handleClick}>
                <img src={Gallary} className={classes.gallaryimage} alt="" />
                <p className="text-light mt-3">Upload Image</p>
              </div>
              {image ===
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjYmlp9JDeNMaFZzw9S3G1dVztGqF_2vq9nA&usqp=CAU" ? (
                <img src={image} className={classes.profileimage} alt="" />
              ) : (
                <img
                  src={URL.createObjectURL(image)}
                  className={classes.profileimage}
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="col-md-12 text-center">
            <button
              className={`${classes.btnnext} btn btn-primary btn-lg`}
              onClick={saveUserData}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
