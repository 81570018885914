import React from "react";
import "./footer.css";
import footerLogo from "../../images/footerLogo2.png";
import facebookIcon from "../../images/icons/facebookIcon.png";
import twitterIcon from "../../images/icons/twitterIcon.png";
import youtubeIcon from "../../images/icons/youtubeIcon.png";
import instagramIcon from "../../images/icons/instaIcon.png";
import linkedinIcon from "../../images/icons/linkedinIcon.png";
import facebookIcon2 from "../../images/icons/social1.png";
import twitterIcon2 from "../../images/icons/social2.png";
import youtubeIcon2 from "../../images/icons/social3.png";
import instagramIcon2 from "../../images/icons/social4.png";
import linkedinIcon2 from "../../images/icons/social5.png";
import locationIcon from "../../images/icons/locationIcon.png";
import mailIcon from "../../images/icons/mailIcon.png";

export const Footer = () => {
  return (
    <div className="footer-main">
      <div className="row">
        <div className="col-12 col-lg-2 col-md-12">
          <img alt="Buzzup" src={footerLogo} className="footer-logo" />
        </div>
        <div className="col-6 col-lg-2 col-sm-4">
          <p className="footer-headings">Product</p>
          <div className="footer-pages">
            <p>Landing Page</p>
            <p>Popup Builder</p>
            <p>Web-design</p>
            <p>Content</p>
            <p>Intergrations</p>
          </div>
          <p></p>
        </div>
        <div className="col-6 col-lg-2 col-sm-4">
          <p className="footer-headings">Use Cases</p>
          <div className="footer-pages">
            <p>Web designers</p>
            <p>Marketers</p>
            <p>Small Business</p>
            <p>Website Builder</p>
          </div>
          <p></p>
        </div>
        <div className="col-6 col-lg-2 col-sm-4">
          <p className="footer-headings">Resources</p>
          <div className="footer-pages">
            <p>Academy</p>
            <p>Blog</p>
            <p>Themes</p>
            <p>Hosting</p>
            <p>Developers</p>
            <p>Support</p>
          </div>
          <p></p>
        </div>
        <div className="col">
          <p className="footer-headings">Company</p>
          <div className="footer-pages">
            <p>About Us</p>
            <p>Careers</p>
            <p>FAQs</p>
            <p>Teams</p>
            <p>Contact Us</p>
          </div>
          <p></p>
        </div>
        <div className="col">
          <p className="footer-headings">Follow us</p>
          <div className="social-icons">
            <div>
              <img alt="Facebook" src={facebookIcon} />
            </div>
            <div>
              <img alt="Twitter" src={twitterIcon} />
            </div>
            <div>
              <img alt="Youtube" src={youtubeIcon} />
            </div>
            <div>
              <img alt="LinkedIn" src={linkedinIcon} />
            </div>
            <div>
              <img alt="Instagram" src={instagramIcon} />
            </div>
          </div>
          <div className="social-icons2">
            <div className="facebook-icon">
              <img alt="Facebook" src={facebookIcon2} />
            </div>
            <div className="twitter-icon">
              <img alt="Twitter" src={twitterIcon2} />
            </div>
            <div className="youtube-icon">
              <img alt="Youtube" src={youtubeIcon2} />
            </div>
            <div className="linkedin-icon">
              <img alt="LinkedIn" src={linkedinIcon2} />
            </div>
            <div className="insta-icon">
              <img alt="Instagram" src={instagramIcon2} />
            </div>
          </div>
          <p className="footer-headings">Contact</p>
          <div className="contact-detail">
            <div>
              <img alt="Location" src={locationIcon} />
            </div>
            <p>Wisconsin Ave, Suite Chevy Chase, Maryland</p>
          </div>
          <div className="contact-detail">
            <div>
              <img alt="Mail" src={mailIcon} />
            </div>
            <p>support@figma.com</p>
          </div>
          <div className="contactus-btn">
            <button type="submit">Contact us</button>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Privacy Policy</p>
        <p>Terms of Use</p>
        <p>Sales and Refunds</p>
        <p>Legal</p>
        <p>Site Map</p>
      </div>
    </div>
  );
};
