import classes from "./EnterPhone.module.css";
import { SignupDetails } from "../../components/Signupdetails/SignupDetails";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PersonalDetailsImg from "../../images/Signup/PersonalDetails.png";

import BuzzerLogo from "../../images/loginLogo.png";
import axios from "axios";
import { useState, useEffect } from "react";
import { signupActions } from "../../store/signup-slice";

import Arrow from "../../images/ForgotPassword/ArrowOne.png";
import Lock from "../../images/ForgotPassword/Lock.png";
import cross from "../../images/contactus/Cross.png";

import ContactUsImg from "../../images/contactus/ContactUsImg.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputPassword } from "../../components/InputPassword/InputPassword";
import { PhoneCountry } from "../../components/PhoneWithCountry/PhoneWithCountry";
import { Button } from "react-bootstrap";

let code = "";
export const EnterPhone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.signup);
  let [countryCode, setCountryCode] = useState("");
  let [phoneno, setPhoneno] = useState(true);
  const [value, setValue] = useState();
  // console.log(value);
  // if (value) {
  //   setPhoneno(true);
  // } else {
  //   setPhoneno(false);
  // }
  const setValueHandler = (value) => {
    console.log(value, code);
    setValue(value);
    if (value) {
      if (value == code) {
        setPhoneno(false);
      } else {
        setPhoneno(true);
        dispatch(
          signupActions.signupuser({
            phone: `+${value}`,
          })
        );
      }
    } else {
      setPhoneno(false);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    setValue(value);
    if (value) {
      if (value == code) {
        setPhoneno(false);
      } else {
        setPhoneno(true);
        navigate("/importantinfo");
      }
    } else {
      setPhoneno(false);
    }
  };
  useEffect(() => {
    let abc = async () => {
      try {
        const response = await axios.get(
          `https://api.ipregistry.co/110.39.1.130?key=0z8t5649zclj7ah3`
        );

        setCountryCode(response.data.location.country.code);
        // console.log(response.data.location.country.calling_code);
        code = response.data.location.country.calling_code;
        console.log("111111");
      } catch (error) {
        console.log(error);
      }
    };
    abc();
  }, []);
  // console.log(countryCode);
  return (
    <SignupDetails>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <img src={cross} className={`${classes.imagecross}`} />
          </div>
        </div>
        <div className="row ">
          <div
            className={`col-md-6 d-flex flex-column align-items-center justify-content-center ${classes.bgblue}`}
          >
            <img src={PersonalDetailsImg} className="img-fluid" />
            <h5 className="mt-4">
              <b>
                Add your<span className={`${classes.bgblues}`}> details</span>{" "}
                so you can login later
              </b>
            </h5>
          </div>
          <div className={`col-lg-6 pt-5 ${classes.col2Main}`}>
            <form onSubmit={submitHandler}>
              <div className={`${classes.contactLogo}`}>
                <img alt="Buzzup" src={BuzzerLogo} />
              </div>
              <div className={`${classes.contactusContainer}`}>
                <p>
                  {/* Create <span style={{ color: "Gray" }}>New Password?</span> */}
                </p>
              </div>
              <div className={` ${classes.resetFinal}`}>
                <div className="signin-input mt-4">
                  <p style={{ fontWeight: "700" }}>Enter Your Phone</p>
                  <PhoneCountry
                    countryCode={countryCode}
                    setValue={setValue}
                    value={value}
                    setValueHandler={setValueHandler}
                  />
                  <p
                    className={`${classes.colorred} ${
                      phoneno ? "d-none" : "d-block"
                    }`}
                  >
                    Please Enter your phone number
                  </p>
                </div>
              </div>

              <div className="d-flex mt-3 justify-content-end">
                <button
                  className="btn btn-primary  btn-lg"
                  type={"submit"}
                  style={{
                    fontSize: "16px",
                    width: "90px",
                    borderRadius: "12px",
                  }}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
