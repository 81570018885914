import "./App.css";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { Navbar } from "./components/Navbar/Navbar";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login/Login";
import { Signup } from "./pages/Signup/Signup";
import { MemberSignup } from "./pages/MemberSignup/MemberSignup";
import Contactus from "./pages/Contactus/Contactus";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { Verify } from "./pages/Verify/Verify";
import {Invite} from './pages/Invite/Invite';
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import {MemberPassword} from './pages/MemberPassword/MemberPassword';
import {MemberName} from './pages/MemberName/MemberName';
import { Password } from "./pages/Password/Password";
import { EnterPhone } from "./pages/EnterPhone/EnterPhone";
import { PersonalDetails } from "./pages/PersonalDetails/PersonalDetails";
import { Whyyouhere } from "./pages/WhyYouHere/WhyYouHere";
import { Chooseyourfield } from "./pages/ChooseYourField/ChooseYourField";
import { Peoplesurvey } from "./pages/PeopleSurvey/PeopleSurvey";
import { Trackfocus } from "./pages/TrackFocus/TrackFocus";
import {ImportantInfo} from './pages/Importantinfo/Importantinfo'
import { InviteTeamates } from "./pages/InviteTeamates/InviteTeamates";
import { BusinessType } from "./pages/BusinessType/BusinessType";
import {BusinessIdNumber} from './pages/BusniessIdNumber/BusinessIdNumber'
import {BusinessLocationAddress} from './pages/BusinessLocationAddress/BusinessLocationAddress'
import {CityName} from './pages/CityName/CityName'
import {BankName} from'./pages/BankName/BankName'
import {OfficeName} from './pages/OfficeName/OfficeName'
import {BusinessName} from './pages/BusinessName/BusinessName'
import { EndDetailsSignup } from "./pages/EndDetailsSignup/EndDetailsSignup";
import { Logo } from "./pages/Logo/Logo";
import { MemberImage } from './pages/MemberImage/MemberImage';
import { Workspace } from './pages/Workspace/Workspace';
function App() {
  return (

<div className="app-container">
      <ReactNotifications />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home />
              </>
            }
          />

          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/membersignup" element={<MemberSignup />} />
          <Route path="/password" element={<Password />} />
          <Route path="/memberpassword" element={<MemberPassword />} />
          <Route path="/membername" element={<MemberName />} />
          <Route path="/memberimage" element={<MemberImage />} />
          <Route path="/enterphone" element={<EnterPhone />} />
          <Route path="/importantinfo" element={<ImportantInfo />} />
          <Route path="/businesstype" element={<BusinessType />} />
          <Route path="/businessidnumber" element={<BusinessIdNumber />} />
          <Route path="/businesslocationaddress" element={<BusinessLocationAddress />} />
          <Route path="/cityname" element={<CityName />} />
          <Route path="/bankname" element={<BankName />} />
          <Route path="/officename" element={<OfficeName />} />
          <Route path="/businessname" element={<BusinessName />} />
          <Route path="/enddetailssignup" element={<EndDetailsSignup />} />
          <Route path="/logo" element={<Logo />} />
          <Route path="/workspace" element={<Workspace />} />
          <Route path="/acceptinvite/:id" element={<Invite />} />
          
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/personaldetails" element={<PersonalDetails />} />
          <Route path="/whyyouhere" element={<Whyyouhere />} />
          <Route path="/chooseyourfield" element={<Chooseyourfield />} />
          <Route path="/peoplesurvey" element={<Peoplesurvey />} />
          <Route path="/trackfocus" element={<Trackfocus />} />
          <Route path="/inviteteamates" element={<InviteTeamates />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
