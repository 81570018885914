import { useParams } from "react-router-dom";
import classes from "./Invite.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { workspaceActions } from "../../store/Workspace/workspace-slice";

import TextLoop from "react-text-loop";
import { useEffect } from "react";
import { checkinvitedb } from "../../store/Workspace/workspace-actions";
import { useCookies } from "react-cookie";

export const Invite = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inviteStatus = useSelector(
    (state) => state.workspace.useablevariables.inviteStatus
  );
  console.log(inviteStatus);

  let { id } = useParams();
  console.log(id);
  useEffect(() => {
    dispatch(
      workspaceActions.changeworkspacerole({
        workspacerole: "member",
      })
    );
    dispatch(
      checkinvitedb({ token: cookies.Token, secretno: id, navigate, setCookie })
    );
  }, []);
  return (
    <>
      <div
        className={`d-flex justify-content-center align-items-center ${classes.ultramain}`}
      >
        <h2
          className={`${classes.main}`}
          style={{ backgroundColor: `${inviteStatus.color}` }}
        >
          <span>{inviteStatus.status}</span>
          {inviteStatus.color === "#adff2f" && (
            <TextLoop interval={1000} fade={true}>
              <span>.</span>
              <div>..</div>
              <div>...</div>
            </TextLoop>
          )}
        </h2>
      </div>
    </>
  );
};
