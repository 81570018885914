import classes from "./WorkspaceGroupAddItem.module.css";
import dots from "../../images/workspace/dots.png";
import { CheckBox } from "./Checkbox";
import Add from "../../images/workspace/plus.png";

import play from "../../images/workspace/play.png";
import conversation from "../../images/workspace/conversation.png";
import groupleftborder from "../../images/workspace/groupleftborder.png";
import Girlone from "../../images/workspace/girlone.jpeg";
import { additemingtodb } from "../../store/Workspace/workspace-actions";
import Girltwo from "../../images/workspace/Girltwo.jpg";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

export const WorkspaceGroupAddItem = (props) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);

  const addnewitem = (groupid) => {
    console.log(groupid);
    dispatch(additemingtodb(cookies.Token, groupid, workspace));
  };
  return (
    <div
      className={`row ${classes.itemborder}`}
      onClick={addnewitem.bind(null, props.item.id)}
    >
      <div
        className={`col-md-12 d-flex justify-content-between ${classes.bggroupleft}`}
      >
        <div className="d-flex align-items-center">
          <img
            src={groupleftborder}
            className={classes.groupleftborder}
            alt=""
          />

          <div className="d-flex align-items-center">
            <img src={Add} className={classes.additem} alt="" />

            <p className={`m-0 p-0 ${classes.newtask}`}>New Task</p>
          </div>
          {/* <CheckBox /> */}
        </div>

        <div className="d-flex align-items-center">
          <img src={conversation} className={classes.play} alt="" />
        </div>
      </div>
    </div>
  );
};
