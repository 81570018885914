import classes from "./WorkspaceGroupItem.module.css";
import { useCookies } from "react-cookie";
import dots from "../../images/workspace/dots.png";
import { CheckBox } from "./Checkbox";

import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import play from "../../images/workspace/play.png";
import stop from "../../images/workspace/stop.png";
import conversation from "../../images/workspace/conversation.png";
import groupleftborder from "../../images/workspace/groupleftborder.png";
import { updateitemindb } from "../../store/Workspace/workspace-actions";
import { workspaceActions } from "../../store/Workspace/workspace-slice";
import WorkspaceTaskTooltip from "./WorkspaceTaskTooltip";

import Girlone from "../../images/workspace/girlone.jpeg";
import Girltwo from "../../images/workspace/Girltwo.jpg";
import {
  createtimesdb,
  getalltimesdb,
} from "../../store/Workspace/workspace-actions";
import { useState } from "react";
import { useEffect } from "react";

export const WorkspaceGroupItem = (props) => {
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);
  const [cookies, setCookie] = useCookies(["user"]);
  const [switchplay, setSwitchPlay] = useState(false);
  const [timer, setTimer] = useState(1);
  const [currentTime, setCurrentTime] = useState(1);
  const [actualTimer, setActualTimer] = useState("00:00");
  const [initialOfDetails, setinitialOfDetails] = useState(false);
  const openItemMembers = (item) => {
    dispatch(workspaceActions.setLatestItem(item));
    dispatch(
      workspaceActions.openItemMembers({
        openItemMembers: !workspace.buttonclicks.showItemMembers,
      })
    );
  };
  const openTaskDetails = (id) => {
    dispatch(
      workspaceActions.openTaskDetails({
        openTaskDetails: !workspace.buttonclicks.openTaskDetails,
      })
    );
    dispatch(
      workspaceActions.setItemDetailsStatusId({
        itemDetailsStatusId: id,
      })
    );
  };
  const showcolormodal = ([id, status, index]) => {
    dispatch(
      workspaceActions.changeItemStatusId({
        itemStatusId: id,
        itemStatus: status,
      })
    );
    dispatch(
      workspaceActions.addstatusmodal({
        showaddstatus: !workspace.buttonclicks.showaddstatus,
      })
    );
    dispatch(
      workspaceActions.setItemIndex({
        index,
      })
    );
  };

  const updateitem = (data) => {
    dispatch(updateitemindb(cookies.Token, data, workspace));
  };
  const reversetimer = (id, extrastatus) => {
    console.log("-----------------------------");
    if (extrastatus !== undefined) {
      if (switchplay) {
        if (
          Number(workspace.useablevariables.ItemIndex) === Number(props.index)
        ) {
          console.log("ooooooppppppppp");
          setSwitchPlay((previous) => {
            if (!previous) {
              setCurrentTime(performance.now());
              setActualTimer(`00:00`);
            } else {
              console.log(timer);
              console.log(id);
              console.log();
              let date = Date.now();
              dispatch(
                createtimesdb(
                  cookies.Token,
                  { id, time: timer, date },
                  workspace
                )
              );
              setTimeout(() => {
                let ids = [];
                let dummyworkspace = JSON.parse(JSON.stringify(workspace));
                dummyworkspace.groups.map((item) => {
                  item.items.map((items) => {
                    ids.push(items.id);
                  });
                });
                dispatch(getalltimesdb(cookies.Token, ids, workspace));
              }, 2000);
            }
            return !previous;
          });
          setTimer((previous) => {
            return previous + 1;
          });
          dispatch(workspaceActions.setcheckAutoTimeOff({ act: false }));
          dispatch(
            workspaceActions.setTotalons({
              count: Number(workspace.useablevariables.Totalons) - 1,
            })
          );
        }
      }
    } else {
      setSwitchPlay((previous) => {
        if (!previous) {
          console.log("jkjjkkjjkjkkjjkkjjkjkjkkjkjkj");
          dispatch(
            workspaceActions.setTotalons({
              count: Number(workspace.useablevariables.Totalons) + 1,
            })
          );
          setCurrentTime(performance.now());
          setActualTimer(`00:00`);
        } else {
          dispatch(
            workspaceActions.setTotalons({
              count: Number(workspace.useablevariables.Totalons) - 1,
            })
          );
          console.log(timer);
          console.log(id);
          console.log();
          let date = Date.now();
          dispatch(
            createtimesdb(cookies.Token, { id, time: timer, date }, workspace)
          );
          setTimeout(() => {
            let ids = [];
            let dummyworkspace = JSON.parse(JSON.stringify(workspace));
            dummyworkspace.groups.map((item) => {
              item.items.map((items) => {
                ids.push(items.id);
              });
            });
            dispatch(getalltimesdb(cookies.Token, ids, workspace));
          }, 2000);
        }
        return !previous;
      });
      setTimer((previous) => {
        return previous + 1;
      });
    }
  };
  if (workspace.useablevariables.checkAutoTimeOff) {
    reversetimer(
      workspace.useablevariables.itemStatusId,
      workspace.useablevariables.itemStatusId
    );
  }
  useEffect(() => {
    if (switchplay) {
      let myInterval = setInterval(() => {
        let millis = Math.floor(
          Number(performance.now()) - Number(currentTime)
        );
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        var hours = Math.floor(millis / (1000 * 60 * 60));
        setTimer(millis);
        setActualTimer(
          `${hours === 0 ? "" : `${hours}:`}${
            minutes > 59 ? minutes % 60 : minutes
          }:${seconds}`
        );
      }, 1000);
      setTimeout(() => {
        clearInterval(myInterval);
      }, 1000);
    }
  }, [timer, actualTimer, currentTime]);
  // console.log(initialOfDetails);
  return (
    <div
      className={`row ${classes.itemborder}`}
      onMouseEnter={() => {
        setinitialOfDetails((previous) => {
          return !previous;
        });
      }}
      onMouseLeave={() => {
        setinitialOfDetails((previous) => {
          return !previous;
        });
      }}
    >
      <div
        className={`col-md-7 d-flex justify-content-between ${classes.bggroupleft}`}
      >
        <div className="d-flex align-items-center">
          <img
            src={groupleftborder}
            className={classes.groupleftborder}
            alt=""
          />
          <CheckBox name={props.item.name} id={props.item.id} />
          <p className={`${classes.fontweights}  ${classes.colorblack} m-0`}>
            <WorkspaceTaskTooltip name={props.item.name}>
              <EditText
                defaultValue={`${props.item.name}`}
                className={`${classes.totalweights}`}
                onSave={function (e) {
                  console.log(e.value);
                  console.log(props.item.id);
                  updateitem({ val: e.value, id: props.item.id });
                }}
              />
            </WorkspaceTaskTooltip>
          </p>
        </div>
        <div className="d-flex align-items-center">
          <p className={`${classes.fontweights}  ${classes.colorblack} m-0`}>
            {props.item.task_id}
          </p>
        </div>

        {switchplay && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={reversetimer.bind(null, props.item.id, undefined)}
          >
            <div className={`${classes.timer} d-flex`}>
              <p
                className={`${classes.fontweights} m-0`}
              >{`${actualTimer} `}</p>
              <img src={stop} className={classes.play} alt="" />
              <p className={`${classes.fontweights} m-0`}>Stop</p>
            </div>
          </div>
        )}
        {!switchplay && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={reversetimer.bind(null, props.item.id, undefined)}
          >
            <div className={`${classes.timer} d-flex`}>
              <p className={`${classes.fontweights} m-0`}></p>
              <img src={play} className={classes.play} alt="" />
              <p className={`${classes.fontweights} m-0`}>Start</p>
            </div>
          </div>
        )}
        <div className="d-flex align-items-center">
          <img src={conversation} className={classes.play} alt="" />
        </div>
      </div>
      <div
        className={`col-md-5 d-flex justify-content-between ${classes.bggroupright}`}
      >
        <div
          className={`d-flex align-items-center`}
          onClick={openItemMembers.bind(null, props.item)}
        >
          {props.item.members.slice(0, 3).map((item) => (
            <img
              key={item.id}
              src={`https://buzzuperpbackend.dejuretechnologies.com${item.image}`}
              className={classes.members}
            />
          ))}
          {!(props.item.members.length === 0) && (
            <div className={`${classes.memberscount}`}>
              <div
                style={{ marginTop: "-2px" }}
              >{`+${props.item.members.length}`}</div>
            </div>
          )}
          {props.item.members.length === 0 && (
            <div className={`${classes.memberscount}`}>
              <div style={{ marginTop: "-2px" }}>{`+`}</div>
            </div>
          )}
        </div>

        <div className="d-flex align-items-center">
          {/* <div className={`${classes.workstatus} ${classes.bgblues}`}>
            {" "}
            Working on it
          </div> */}
          {/* <div className={`${classes.workstatus} ${classes.bggray}`}>
            {" "}
            Working on it
          </div> */}
          {/* <div className={classes.backgrounds}>
            <div className={classes.note}>Sample note</div>
          </div> */}

          {props.item.task_status === "Working" && (
            <div
              className={classes.workstatusarea}
              onClick={showcolormodal.bind(null, [
                props.item.id,
                props.item.task_status,
                props.index,
              ])}
            >
              <button className={classes.btnworkingonit}>Working on it</button>
            </div>
          )}

          {props.item.task_status === "Stuck" && (
            <div
              className={classes.workstatusarea}
              onClick={showcolormodal.bind(null, [
                props.item.id,
                props.item.task_status,
                props.index,
              ])}
            >
              <button className={classes.btnstuck}>Stuck</button>
            </div>
          )}

          {props.item.task_status === "Done" && (
            <div
              className={classes.workstatusarea}
              onClick={showcolormodal.bind(null, [
                props.item.id,
                props.item.task_status,
                props.index,
              ])}
            >
              <button className={classes.btndone}>Done</button>
            </div>
          )}
          {props.item.task_status === "none" && (
            <div
              className={classes.workstatusarea}
              onClick={showcolormodal.bind(null, [
                props.item.id,
                props.item.task_status,
                props.index,
              ])}
            >
              <button className={classes.btnwork}>none</button>
            </div>
          )}
        </div>
        <div
          className={`d-flex align-items-center ${
            initialOfDetails ? classes.poiuyt : "d-none"
          }`}
          id="aaa"
          onClick={openTaskDetails.bind(null, props.item.id)}
        >
          <button
            type="button"
            className={`btn btn-success ${classes.btnDetails}`}
          >
            Details
          </button>
        </div>
        <div className="d-flex align-items-center">
          <p className={`${classes.mainheading} ${classes.colorblack} m-0`}>
            {`${new Date(props.item.created_at).getFullYear()}-${
              new Date(props.item.created_at).getMonth() + 1
            }-${new Date(props.item.created_at).getDate()}`}
          </p>
        </div>
      </div>
    </div>
  );
};
