import classes from "./WorkspaceMembersModal.module.css";
import Modal from "react-bootstrap/Modal";
import { CheckboxItemDetails } from "./CheckboxItemDetails";
import ModalBody from "react-bootstrap/ModalBody";
import DatePicker from "react-datepicker";
import CustomPaginationActionsTableMembersItem from "./CustomPaginationActionsTableMembersItem";
import cross from "../../images/blackcross.png";
import attachment from "../../images/workspace/attachment.png";
import { workspaceActions } from "../../store/Workspace/workspace-slice";
import { useSelector, useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { SaveItemIntoChecklist } from "../../store/Workspace/workspace-actions";
import "react-datepicker/dist/react-datepicker.css";
import todolist from "../../images/workspace/to-do-list.png";
import { useState, useEffect, useRef } from "react";

export const WorkspaceAddChecklistItem = (props) => {
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);

  const [MyChecklistNames, setMyChecklistNames] = useState("");
  console.log(workspace.useablevariables);
  const saveChecklistS = (data) => {
    console.log(data[1], data[0]);

    dispatch(SaveItemIntoChecklist(data[1], data[0], workspace));
  };
  return (
    <div
      className={`d-flex align-items-center p-2 mt-4`}
      // onClick={onButtonClick}
    >
      <input
        type="text"
        placeholder="To Do List Name"
        className="form-control"
        onChange={(e) => {
          setMyChecklistNames(e.target.value);
        }}
      />
      {MyChecklistNames.length > 0 && (
        <button
          type="button"
          className="btn btn-success"
          onClick={saveChecklistS.bind(null, [MyChecklistNames, props.id])}
        >
          Save
        </button>
      )}
      {MyChecklistNames.length === 0 && (
        <button type="button" disabled className="btn btn-success">
          Save
        </button>
      )}
    </div>
  );
};
