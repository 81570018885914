import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import { useState, useEffect } from "react";

export const PhoneCountry = (props) => {
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".

  // const [value, setValue] = useState();
  // console.log(value);
  return (
    <PhoneInput
      country={props.countryCode.toLowerCase()}
      value={props.value}
      inputStyle={{
        width: "100%",
        height: "40px",
      }}
      onChange={props.setValueHandler}
    />
  );
};
