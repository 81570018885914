import classes from "./ImportantInfo.module.css";
import { SignupDetails } from "../../components/Signupdetails/SignupDetails";

import { Link } from "react-router-dom";
import PersonalDetailsImg from "../../images/Signup/PersonalDetails.png";

import BuzzerLogo from "../../images/loginLogo.png";
import axios from "axios";
import { useState, useEffect } from "react";

import Arrow from "../../images/ForgotPassword/ArrowOne.png";
import Lock from "../../images/ForgotPassword/Lock.png";
import cross from "../../images/blackcross.png";

import ContactUsImg from "../../images/contactus/ContactUsImg.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputPassword } from "../../components/InputPassword/InputPassword";
import { PhoneCountry } from "../../components/PhoneWithCountry/PhoneWithCountry";
import { Button } from "react-bootstrap";

export const ImportantInfo = () => {
  let [countryCode, setCountryCode] = useState("");
  useEffect(() => {
    let abc = async () => {
      try {
        const response = await axios.get(
          `https://api.ipregistry.co/110.39.1.130?key=0z8t5649zclj7ah3`
        );

        setCountryCode(response.data.location.country.code);
      } catch (error) {
        console.log(error);
      }
    };
    abc();
  });
  console.log(countryCode);
  return (
    <SignupDetails>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <img src={cross} className={`${classes.imagecross}`} />
          </div>
          <div className="col-md-12 text-center">
            <img src={BuzzerLogo} />
          </div>
          <div className="col-md-12 px-5">
            <p className={`${classes.infoheading}`}>Important Information!</p>
            <div className={`${classes.content}`}>
              The digital sign-up process allows the business to set up a
              clearing account within a few hours without the need for a
              representative. Please prepare an ID of the business owner(up -to-
              date) and a photocopy of a check of the bank account (or an
              account management certificate). Please note the name of the
              business will appear in the credit details of the customers and
              therefore must be proper and clear for the customers will appear
              in the credit details of the customers and therefore must be
              proper and clear for the customers to recognize the deal.
              Customers who do not recognize transactions may cancel them.
            </div>
          </div>

          <div className="col-md-12 text-center">
            <Link to="/businesstype" style={{ textDecoration: "none" }}>
              <button className={`${classes.btnskip} btn btn-primary btn-lg`}>
                Skip
              </button>
            </Link>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
