import { configureStore } from "@reduxjs/toolkit";

import signupSlice from "./signup-slice";
import signinSlice from "./signin-slice";
import workspaceSlice from "./Workspace/workspace-slice";
import MemberSignupSlice from "./MemberSignup/MemberSignup-slice";


const store = configureStore({
  reducer: { signup: signupSlice.reducer,signin: signinSlice.reducer, workspace: workspaceSlice.reducer,membersignup:MemberSignupSlice.reducer },
});

export default store;
