import React from "react";
import { useNavigate } from "react-router-dom";

import classes from "./Contactus.module.css";
import contactLogo from "../../images/contactus/logocontactus.png";
import twitter from "../../images/contactus/socialicons/social.png";
import linkedin from "../../images/contactus/socialicons/social-1.png";
import cross from "../../images/contactus/Cross.png";
import instagram from "../../images/contactus/socialicons/social-2.png";
import facebook from "../../images/contactus/socialicons/Vector.png";

// IMAGES IMPORT
import whatsappicon from "../../images/contactus/whatsapp.png";
import phoneicon from "../../images/contactus/phone.png";

import ContactUsImg from "../../images/contactus/ContactUsImg.png";

const Contactus = () => {
  const navigate = useNavigate();
  let GoBackToLandingPage = () => {
    navigate("/");
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <img
            src={cross}
            className={`${classes.imagecross}`}
            onClick={GoBackToLandingPage}
          />
        </div>
      </div>
      <div className="row ">
        <div className={`col-lg-7 ${classes.col1Main}`}>
          <div className={`${classes.loginImg}`}>
            <div className={`${classes.ContactText}`}>
              <p className={`${classes.ContactTextHeading}`}>
                Lets Get In Touch With Us!
              </p>
              <p className={`${classes.ContactTextPara}`}>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Quisquam odio dignissimos. Quisquam odio dignissimos. Quisquam
                odio.
              </p>
            </div>
            <img className={`${classes.loginImga}`} src={ContactUsImg} />
          </div>
        </div>
        <div className={`col-lg-5 pt-5 ${classes.col2Main}`}>
          <div className={`${classes.contactLogo}`}>
            <img alt="Buzzup" src={contactLogo} />
          </div>

          <div className={`${classes.contactusContainer}`}>
            <p className="text-center">Contact Us</p>
            <p>
              Want to talk someone now? Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Nesciunt dolores cumque placeat exercitationem
              error.
            </p>
            <p className={`text-center my-5 ${classes.letstalk}`}>Lets Talk</p>
            <button className={`${classes.phones}`}>
              <img src={phoneicon} width="30px" />
              <p className="m-0">(012) 123 4556</p>
            </button>
            <button className={`mt-5 ${classes.phones}`}>
              <img src={whatsappicon} width="30px" />
              <p className="m-0">(012) 123 4556</p>
            </button>
            <div className="d-flex justify-content-start">
              <div style={{ width: "10px", marginLeft: "-17px" }}>
                <img
                  alt="facebook"
                  className={classes.socialiconssetup}
                  src={facebook}
                />
              </div>
              <div style={{ width: "40px" }}>
                <img
                  alt="twitter"
                  className={classes.socialiconssetup}
                  src={twitter}
                />
              </div>
              <div style={{ width: "40px" }}>
                <img
                  alt="facebook"
                  className={classes.socialiconssetup}
                  src={linkedin}
                />
              </div>
              <div style={{ width: "30px" }}>
                <img
                  alt="facebook"
                  className={classes.socialiconssetup}
                  src={instagram}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contactus;
