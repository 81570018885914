import classes from "./Workspace.module.css";
import { BaseNavbar } from "../../components/BaseNavbar/BaseNavbar";
import { BaseSidebar } from "../../components/BaseSidebar/BaseSidebar";
import { WorkspacesDropdown } from "../../components/Workspace/WorkspacesDropdown";
import { WorkspacesBoardDropdown } from "../../components/Workspace/WorkspacesBoardDropdown";
import { WorkspaceMembersModal } from "../../components/Workspace/WorkspaceMembersModal";
import { WorkspaceMembersModalPending } from "../../components/Workspace/WorkspaceMembersModalPending";

import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { workspaceActions } from "../../store/Workspace/workspace-slice";

import { WorkspaceSideContent } from "../../components/Workspace/WorkspaceSideContent";
import { WorkspaceAddModal } from "../../components/Workspace/WorkspaceAddModal";
import { WorkspaceTaskDetailsModel } from "../../components/Workspace/WorkspaceTaskDetailsModel";
import { TimeWarningPopup } from "../../components/Workspace/TimeWarningPopup";
import { WorkspaceAddBoard } from "../../components/Workspace/WorkspaceAddBoard";
import { WorkspaceAddGroup } from "../../components/Workspace/WorkspaceAddGroup";
import dropdowns from "../../images/workspace/dropdown.png";
import board from "../../images/workspace/boards.png";
import form from "../../images/workspace/form.png";
import folder from "../../images/workspace/folder.png";
import document from "../../images/workspace/document.png";
import restoreicon from "../../images/workspace/restoreicon.png";
import impicon from "../../images/workspace/importicon.png";
import Modal from "react-bootstrap/Modal";
import choose from "../../images/workspace/choosetemp.png";
import { WorkspaceItemMembersModel } from "../../components/Workspace/WorkspaceItemMembersModel";
import { WorkspaceSearchTimeModal } from "../../components/Workspace/WorkspaceSearchTimeModal";
import {
  getallworkspacesdb,
  addworkspaceandgroup,
  getallboardsdb,
  getallgroupsdb,
  addboard,
  addgroup,
  getroledb,
  getallmemberworkspacesdb,
} from "../../store/Workspace/workspace-actions";
import { useCookies } from "react-cookie";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
export const Workspace = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [ErrorNames, setErrorNames] = useState(false);
  const [BoardError, setBoardError] = useState(false);
  const [GroupError, setGroupError] = useState(false);
  const [GroupName, setGroupName] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    console.log(cookies.Token);
    if (cookies.Token === undefined) {
      navigate("/");
    } else {
      dispatch(getroledb(cookies.Token));
    }
  }, []);
  let [whichboard, setwhichboard] = useState(false);
  console.log(cookies);
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);

  console.log(workspace);
  const changeBoard = (recordid) => {
    console.log(recordid);
    let record = workspace.boards.filter((item) => {
      if (item.id === recordid) {
        return true;
      } else {
        return false;
      }
    });
    console.log(record);
    dispatch(workspaceActions.selectboards(record[0] ? record[0] : false));
  };
  const changestatus = () => {
    let status =
      workspace.useablevariables.TaskSection === "main" ? "nomain" : "main";
    dispatch(workspaceActions.setTaskSection(status));
  };
  const changeWorkspace = (recordid) => {
    console.log(recordid);
    let record = workspace.workspaces.filter((item) => {
      if (item.id === recordid) {
        return true;
      } else {
        return false;
      }
    });
    dispatch(
      workspaceActions.addworkspacedropdown({
        addworkspace: !workspace.buttonclicks.addworkspace,
      })
    );
    dispatch(
      workspaceActions.addboarddropdown({
        addboard: !workspace.buttonclicks.addboard,
      })
    );

    console.log(record);
    dispatch(workspaceActions.selectworkspaces(record[0] ? record[0] : false));
  };
  useEffect(() => {
    if (workspace.useablevariables.workspacerole === "member") {
      dispatch(getallmemberworkspacesdb(cookies.Token));
    } else if (workspace.useablevariables.workspacerole === "") {
    } else {
      dispatch(workspaceActions.newitemintoworkspace(false));
      if (!workspace.getallworkspaces) {
        dispatch(getallworkspacesdb(cookies.Token));
      }
    }
  }, [
    workspace.getallworkspaces,
    workspace.useablevariables.workspacerole,
    getallworkspacesdb,
    getallmemberworkspacesdb,
    dispatch,
    cookies.Token,
  ]);
  useEffect(() => {
    dispatch(workspaceActions.newitemintoboard(false));
    dispatch(workspaceActions.newitemintogroup(false));

    if (!workspace.getallboards) {
      dispatch(
        getallboardsdb(
          cookies.Token,
          workspace.latestworkspace.id,
          workspace.useablevariables.workspacerole,
          workspace.memberboards,
          navigate,
          setCookie
        )
      );
    }
  }, [
    workspace.getallboards,
    workspace.latestworkspace,
    workspace.useablevariables.workspacerole,
    workspace.memberboards,
    dispatch,
    cookies.Token,
  ]);
  useEffect(() => {
    dispatch(workspaceActions.newitemintoboard(false));
    dispatch(workspaceActions.newitemintogroup(false));

    if (!workspace.getallgroups) {
      dispatch(
        getallgroupsdb(
          cookies.Token,
          workspace.latestboard.id,
          workspace.useablevariables.workspacerole,
          navigate,
          setCookie
        )
      );
    }
  }, [
    workspace.getallgroups,
    workspace.useablevariables.workspacerole,
    workspace.latestboard,
    dispatch,
    cookies.Token,
  ]);
  const openaddboard = () => {
    console.log("[ahaah");
    dispatch(
      workspaceActions.addboarddropdown({
        addboard: !workspace.buttonclicks.addboard,
      })
    );
    workspace.buttonclicks.addworkspace &&
      dispatch(
        workspaceActions.addworkspacedropdown({
          addworkspace: !workspace.buttonclicks.addworkspace,
        })
      );
  };
  const openaddworkspace = () => {
    setwhichboard(false);

    console.log("[ahaah");
    dispatch(
      workspaceActions.addworkspacedropdown({
        addworkspace: !workspace.buttonclicks.addworkspace,
      })
    );
    workspace.buttonclicks.addboard &&
      dispatch(
        workspaceActions.addboarddropdown({
          addboard: !workspace.buttonclicks.addboard,
        })
      );
  };
  const showboardmodal = () => {
    setwhichboard(true);

    dispatch(
      workspaceActions.addboardmodal({
        showaddBoard: !workspace.buttonclicks.showaddBoard,
      })
    );
  };

  const backtoworkspace = () => {
    dispatch(
      workspaceActions.addboardmodal({
        showaddBoard: !workspace.buttonclicks.showaddBoard,
      })
    );
    dispatch(
      workspaceActions.addworkspacemodal({
        showaddworkspace: !workspace.buttonclicks.showaddworkspace,
      })
    );
  };
  const showworkspacemodal = () => {
    dispatch(
      workspaceActions.addworkspacemodal({
        showaddworkspace: !workspace.buttonclicks.showaddworkspace,
      })
    );
  };
  const showaddboard = () => {
    if (workspacename.length > 0) {
      setwhichboard(false);

      dispatch(
        workspaceActions.addworkspacemodal({
          showaddworkspace: !workspace.buttonclicks.showaddworkspace,
        })
      );
      dispatch(
        workspaceActions.addboardmodal({
          showaddBoard: !workspace.buttonclicks.showaddBoard,
        })
      );
    } else {
      setErrorNames(true);
    }
  };
  const showaddsignleboard = () => {
    dispatch(
      workspaceActions.addboardmodal({
        showaddBoard: !workspace.buttonclicks.showaddBoard,
      })
    );
  };
  const closeTaskDetailsModal = () => {
    dispatch(
      workspaceActions.openTaskDetails({
        openTaskDetails: !workspace.buttonclicks.openTaskDetails,
      })
    );
  };
  const closeSearchModal = () => {
    dispatch(
      workspaceActions.showaSearchTime({
        showaSearchTime: !workspace.buttonclicks.showaSearchTime,
      })
    );
  };
  const closeItemMemberModal = () => {
    dispatch(
      workspaceActions.openItemMembers({
        openItemMembers: !workspace.buttonclicks.showItemMembers,
      })
    );
  };
  const closeaddboard = () => {
    console.log("ahah");
    dispatch(
      workspaceActions.addboardmodal({
        showaddBoard: !workspace.buttonclicks.showaddBoard,
      })
    );
    setErrorNames(false);
    setBoardError(false);
  };
  const closeaddgroup = () => {
    // console.log("ahah");
    dispatch(
      workspaceActions.addgroupmodal({
        showaddBoard: !workspace.buttonclicks.showaddBoard,
      })
    );
    // setErrorNames(false);
    // setBoardError(false);
  };
  const closeaddmodal = () => {
    console.log("ahah");
    dispatch(
      workspaceActions.addworkspacemodal({
        showaddworkspace: !workspace.buttonclicks.showaddworkspace,
      })
    );
    setErrorNames(false);
    setBoardError(false);
    setworkspacename("");
    setboardname("");
  };
  let [workspacename, setworkspacename] = useState("");

  const getWorkspaceName = (e) => {
    setworkspacename(e.target.value);
  };
  let [boardname, setboardname] = useState("");

  const getGroupName = (e) => {
    setGroupName(e.target.value);
  };

  const getBoardName = (e) => {
    setboardname(e.target.value);
  };

  const oncloses = (e) => {
    dispatch(
      workspaceActions.openmemberspendingmodal({
        openmemberspendingmodal:
          !workspace.buttonclicks.openmemberspendingmodal,
      })
    );
  };
  const closess = (e) => {
    dispatch(
      workspaceActions.addshowmemberin({
        showmemberin: !workspace.buttonclicks.showmemberin,
      })
    );
  };
  const closesss = (e) => {
    dispatch(
      workspaceActions.showtimewarning({
        showtimewarning: !workspace.buttonclicks.showtimewarning,
      })
    );
  };
  // it will save the workspace and board data
  const saveData = () => {
    console.log(workspacename, boardname);
    if (boardname.length > 0) {
      dispatch(
        addworkspaceandgroup(
          { workspacename, boardname },
          cookies.Token,
          workspace
        )
      );
      setworkspacename("");
      setboardname("");
    } else {
      setBoardError(true);
    }
  };

  const saveGroupData = () => {
    if (GroupName.length > 0) {
      dispatch(
        addgroup(workspace.latestboard.id, GroupName, cookies.Token, workspace)
      );
      setGroupError(false);
      setGroupName("");
    } else {
      setGroupError(true);
    }
  };
  const saveBoardData = () => {
    if (boardname.length > 0) {
      dispatch(
        addboard(
          workspace.latestworkspace.id,
          boardname,
          cookies.Token,
          workspace
        )
      );
      setBoardError(false);

      setworkspacename("");
      setboardname("");
    } else {
      setBoardError(true);
    }
  };
  return (
    <div className="">
      <div className="container-fluid row m-0 p-0">
        <BaseSidebar />
        <BaseNavbar>
          <div className="row m-0">
            <div className={`col-md-2 p-4 m-0 ${classes.sidebar}`}>
              <h5 className={classes.title}>
                {workspace.latestworkspace.name
                  ? workspace.latestworkspace.name
                  : "No Workspaces"}
              </h5>
              <div className={classes.parentdropdown}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={openaddworkspace}
                  className="d-flex align-items-center justify-content-between"
                >
                  <p className={`${classes.defaultworkspace} m-0`}>
                    Workspaces
                  </p>
                  <img src={dropdowns} className={classes.dropdown} alt="" />
                </div>
                {workspace.workspaces && (
                  <WorkspacesDropdown
                    status={workspace.buttonclicks.addworkspace}
                    showworkspacemodal={showworkspacemodal}
                    workspaces={workspace.workspaces}
                    changeWorkspace={changeWorkspace}
                  />
                )}
                {workspace.boards && (
                  <WorkspacesBoardDropdown
                    status={workspace.buttonclicks.addboard}
                    showboardmodal={showboardmodal}
                    boards={workspace.boards}
                    latestworkspace={workspace.latestworkspace}
                    changeBoard={changeBoard}
                  />
                )}

                {/* <WorkspaceAddBoard /> */}
                {/* <WorkspaceAddGroup /> */}
              </div>
              {workspace.buttonclicks.showaddworkspace && (
                <WorkspaceAddModal
                  showaddboard={showaddboard}
                  onclose={closeaddmodal}
                  getWorkspaceName={getWorkspaceName}
                  ErrorNames={ErrorNames}
                />
              )}
              {workspace.buttonclicks.showaddBoard && (
                <WorkspaceAddBoard
                  getBoardName={getBoardName}
                  onclose={closeaddboard}
                  saveData={saveData}
                  whichboard={whichboard}
                  saveBoardData={saveBoardData}
                  BoardError={BoardError}
                  backtoworkspace={backtoworkspace}
                />
              )}
              {workspace.buttonclicks.showItemMembers && (
                <WorkspaceItemMembersModel
                  onclose={closeItemMemberModal}
                  membersin={workspace.memberinboard}
                />
              )}
              {workspace.buttonclicks.openTaskDetails && (
                <WorkspaceTaskDetailsModel onclose={closeTaskDetailsModal} />
              )}
              {workspace.buttonclicks.showaSearchTime && (
                <WorkspaceSearchTimeModal onclose={closeSearchModal} />
              )}
              {workspace.buttonclicks.showaddGroup && (
                <WorkspaceAddGroup
                  getGroupName={getGroupName}
                  onclose={closeaddgroup}
                  saveGroupData={saveGroupData}
                  GroupError={GroupError}
                  // whichboard={whichboard}
                  // saveBoardData={saveBoardData}
                  // backtoworkspace={backtoworkspace}
                />
              )}
              {workspace.buttonclicks.showtimewarning && (
                <TimeWarningPopup onclose={closesss} />
              )}
              {workspace.buttonclicks.showmemberin && (
                <WorkspaceMembersModal
                  membersin={workspace.memberinboard}
                  onclose={closess}
                />
              )}
              {workspace.buttonclicks.openmemberspendingmodal && (
                <WorkspaceMembersModalPending
                  membersin={workspace.memberinboardpendings}
                  onclose={oncloses}
                />
              )}
              <div
                className="d-flex align-items-center mt-4"
                onClick={openaddboard}
                style={{ cursor: "pointer" }}
              >
                <img src={board} className={classes.dropdown} alt="" />
                <p className={`${classes.sideitems} p-0 m-0`}>Boards</p>
              </div>
              <div className="d-flex align-items-center mt-3">
                <img src={impicon} className={classes.dropdown} alt="" />
                <p className={`${classes.sideitems} p-0 m-0`}>Import Data</p>
              </div>
              <div
                className="d-flex align-items-center mt-3"
                onClick={changestatus}
              >
                <img src={restoreicon} className={classes.dropdown} alt="" />
                <p className={`${classes.sideitems} p-0 m-0`}>Restore Tasks</p>
              </div>
              <div className="d-flex align-items-center mt-3">
                <img src={choose} className={classes.dropdown} alt="" />
                <p className={`${classes.sideitems} p-0 m-0`}>
                  Choose from template
                </p>
              </div>
            </div>
            <WorkspaceSideContent />
          </div>
        </BaseNavbar>
      </div>
    </div>
  );
};
