import OpenEye from "../../images/ResetPassword/openeye.png";
import CloseEye from "../../images/ResetPassword/closeeye.png";
import { useState } from "react";

export const InputPassword = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  return (
    <>
      <div className="input-group" style={{ position: "relative" }}>
        <input
          type={passwordShown ? "text" : "password"}
          placeholder="********"
          required
          className={`form-control ${props.classes ? props.classes : ""}  mt-2`}
          id={props.password ? "password" : "finalpassword"}
          onChange={props.formik.handleChange}
          value={
            props.password
              ? props.formik.values.password
              : props.formik.values.finalpassword
          }
        />

        <div className="input-group-append" style={{ zIndex: "20" }}>
          <img
            src={passwordShown ? OpenEye : CloseEye}
            style={{
              position: "absolute",
              top: "40%",
              right: "6%",
              width: "30px",
            }}
            onClick={togglePassword}
          />
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {props.password ? (
          props.formik.errors.password ? (
            <div className="error">{props.formik.errors.password}</div>
          ) : (
            ""
          )
        ) : props.formik.errors.finalpassword ? (
          <div className="error">{props.formik.errors.finalpassword}</div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
