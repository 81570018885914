import { Workspaces } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const workspaceSlice = createSlice({
  name: "workspace",
  initialState: {
    buttonclicks: {
        addworkspace:false,    
        addboard:false,    
        showaddworkspace:false,
        showaddBoard:false,
        showaddGroup:false,
        showaddstatus:false,
        showaddtimes:false,
        showaddinvite:false,
        openmemberspendingmodal:false,
        showmemberin:false,
        showtimewarning:false,
        showItemMembers:false,
        openTaskDetails:false,
        showaSearchTime:false
    },
    useablevariables:{
        itemStatusId:'',
        itemDetailsStatusId:'',
        itemStatus:'',
        inviteStatus:{
            status:'Processing',
            color:"#adff2f"
        },
        workspacerole:'',
        updatemyoperatemember:'',
        checkAutoTimeOff:false,
        ItemIndex:'',
        Totalons:0,
        TaskDelCounts:[],
        TaskSection:'main',
        RestoreStatus:false,
        checksOfItems:[],
        itemDetails:{}
    },
    latestworkspace:false,
    latestboard:false,
    latestgroup:false,
    latestitem:false,
    getallworkspaces:false,
    workspaces:'',
    getallboards:false,
    boards:'',
    getallgroups:false,
    groups:[],
    searchGroups:[],
    searchZone:false,
    getalltimes:false,
    times:[],
    memberboards:[],
    memberinboard:[],
    memberinboardpendings:[]
},
  reducers: {
    addworkspacedropdown(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.addworkspace){
            state.buttonclicks.addworkspace = buttonclicks.addworkspace
        }else{
            state.buttonclicks.addworkspace = buttonclicks.addworkspace
        }
    },
    updateTaskDelCounts(state,action){
        const payload = action.payload;
        state.useablevariables.TaskDelCounts= payload.count
    },
    updatememberboards(state,action){
        const boards = action.payload;
        state.memberboards= boards
    },
    setLatestItem(state,action){
        const item = action.payload;
        state.latestitem= item
    },
    setmemberinboardpendings(state,action){
        const memberinboardpendings = action.payload;
        state.memberinboardpendings = memberinboardpendings;
    },
    setmemberinboard(state,action){
        const memberinboard = action.payload;
        state.memberinboard = memberinboard;
    },
    setTaskSection(state,action){
        const payload = action.payload;
        state.useablevariables.TaskSection = payload;        
    },
    setRestoreStatus(state,action){
        const payload = action.payload;
        state.useablevariables.RestoreStatus = payload;        
    },
    setcheckAutoTimeOff(state,action){
        const status = action.payload;
        state.useablevariables.checkAutoTimeOff = status.act;        
    },
    setItemDetailsStatusId(state,action){
        const status = action.payload;
        state.useablevariables.itemDetailsStatusId = status.itemDetailsStatusId;        
    },
    setItemDetails(state,action){
        const status = action.payload;
        state.useablevariables.itemDetails = status.itemDetails;
    },
    setItemIndex(state,action){
        const status = action.payload;
        state.useablevariables.ItemIndex = status.index;        
    },
    openItemMembers(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.openItemMembers){
            state.buttonclicks.showItemMembers = buttonclicks.openItemMembers
        }else{
            state.buttonclicks.showItemMembers = buttonclicks.openItemMembers
        }
    },
    addboarddropdown(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.addboard){
            state.buttonclicks.addboard = buttonclicks.addboard
        }else{
            state.buttonclicks.addboard = buttonclicks.addboard
        }
    },
    changeItemStatusId(state, action) {
        const useablevariables = action.payload;
        state.useablevariables.itemStatusId = useablevariables.itemStatusId;
        state.useablevariables.itemStatus = useablevariables.itemStatus;
    },
    changeworkspacerole(state,action){
        const useablevariables = action.payload;
        console.log('--------------------role--------------------');
        console.log(useablevariables.workspacerole);
        console.log('--------------------role--------------------');
        state.useablevariables.workspacerole = useablevariables.workspacerole;
    },
    setTotalons(state,action){
        const obj = action.payload;
        state.useablevariables.Totalons = obj.count;
    },
    changeInviteStatus(state, action) {
        const obj = action.payload;
        state.useablevariables.inviteStatus = obj.changeInviteStatus;
        state.useablevariables.inviteStatus = obj.changeInviteStatus;
    },
    addshowmemberin(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.showmemberin){
            state.buttonclicks.showmemberin = buttonclicks.showmemberin
        }else{
            state.buttonclicks.showmemberin = buttonclicks.showmemberin
        }
    },
    updatemyoperatemember(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks){
            state.useablevariables.updatemyoperatemember = buttonclicks
        }else{
            state.useablevariables.updatemyoperatemember = buttonclicks
        }
    },
    openmemberspendingmodal(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.openmemberspendingmodal){
            state.buttonclicks.openmemberspendingmodal = buttonclicks.openmemberspendingmodal
        }else{
            state.buttonclicks.openmemberspendingmodal = buttonclicks.openmemberspendingmodal
        }
    },
    openTaskDetails(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.openTaskDetails){
            state.buttonclicks.openTaskDetails = buttonclicks.openTaskDetails
        }else{
            state.buttonclicks.openTaskDetails = buttonclicks.openTaskDetails
        }
    },
    showaSearchTime(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.showaSearchTime){
            state.buttonclicks.showaSearchTime = buttonclicks.showaSearchTime
        }else{
            state.buttonclicks.showaSearchTime = buttonclicks.showaSearchTime
        }
    },
    showtimewarning(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.showtimewarning){
            state.buttonclicks.showtimewarning = buttonclicks.showtimewarning
        }else{
            state.buttonclicks.showtimewarning = buttonclicks.showtimewarning
        }
    },
    addinvitemodal(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.showaddinvite){
            state.buttonclicks.showaddinvite = buttonclicks.showaddinvite
        }else{
            state.buttonclicks.showaddinvite = buttonclicks.showaddinvite
        }
    },
    addworkspacemodal(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.showaddworkspace){
            state.buttonclicks.showaddworkspace = buttonclicks.showaddworkspace
        }else{
            state.buttonclicks.showaddworkspace = buttonclicks.showaddworkspace
        }
    },
    addstatusmodal(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.showaddstatus){
            state.buttonclicks.showaddstatus = buttonclicks.showaddstatus
        }else{
            state.buttonclicks.showaddstatus = buttonclicks.showaddstatus
        }
    },
    addtimemodal(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.showaddtimes){
            state.buttonclicks.showaddtimes = buttonclicks.showaddtimes
        }else{
            state.buttonclicks.showaddtimes = buttonclicks.showaddtimes
        }
    },
    addboardmodal(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.showaddBoard){
            state.buttonclicks.showaddBoard = buttonclicks.showaddBoard
        }else{
            state.buttonclicks.showaddBoard = buttonclicks.showaddBoard
        }
    },
    addgroupmodal(state, action) {
        const buttonclicks = action.payload;
        if(buttonclicks.showaddGroup){
            state.buttonclicks.showaddGroup = buttonclicks.showaddGroup
        }else{
            state.buttonclicks.showaddGroup = buttonclicks.showaddGroup
        }
    },
    newitemintoworkspace(state, action) {
        const workspacestatus = action.payload;
        state.getallworkspaces = workspacestatus
    },
    newitemintoboard(state, action) {
        const boardstatus = action.payload;
        state.getallboards = boardstatus
    },
    newitemintogroup(state, action) {
        const groupstatus = action.payload;
        state.getallgroups = groupstatus
    },
    // newitemintotime(state, action) {
    //     const timetatus = action.payload;
    //     state.getalltimes = timestatus
    // },
    updateboards(state, action) {
        const boards = action.payload;
        if(boards){
            state.boards = boards
        }else{
            state.boards = boards
        }
    },
    updatetimes(state, action) {
        const times = action.payload;
        state.times = times
    },
    updatelatestgroup(state, action) {
        const group = action.payload;
        if(group){
            state.latestgroup = group
        }else{
            state.latestgroup = group
        }
    },
    searchGroups(state, action) {
        const group = action.payload;
        if(group){
            state.searchGroups = group.searchGroups
        }else{
            state.searchGroups = group.searchGroups
        }
    },
    searchZone(state, action) {
        const group = action.payload;
        if(group){
            state.searchZone = group.searchZone
        }else{
            state.searchZone = group.searchZone
        }
    },
    updatelatestworkspace(state, action) {
        const workspace = action.payload;
        if(workspace){
            state.latestworkspace = workspace
        }else{
            state.latestworkspace = workspace
        }
    },
    updatelatestboard(state, action) {
        const board = action.payload;
        if(board){
            state.latestboard = board
        }else{
            state.latestboard = board
        }
    },
    updateworkspaces(state, action) {
        const workspaces = action.payload;
        if(workspaces){
            state.workspaces = workspaces
        }
    },
    updategroups(state, action) {
        const groups = action.payload;
        if(groups){
            state.groups = groups
        }
    },
    updateChecksOfItems(state, action) {
        const checksOfItems = action.payload;
        state.useablevariables.checksOfItems=checksOfItems;
    },
    selectworkspaces(state, action) {
        const workspace = action.payload;
        if(workspace){
            state.latestworkspace = workspace
        }
    },
    selectboards(state, action) {
        const board = action.payload;
        if(board){
            state.latestboard = board
        }
    },
    selectgroups(state, action) {
        const group = action.payload;
        if(group){
            state.latestgroup = group
        }
    },
},
});

export const workspaceActions = workspaceSlice.actions;

export default workspaceSlice;
