import classes from "./WorkspaceInviteModal.module.css";
import Modal from "react-bootstrap/Modal";
import React, { memo } from "react";
import ModalBody from "react-bootstrap/ModalBody";
import CustomPaginationActionsTableMembers from "./CustomPaginationActionsTableMembers";
import { useSelector, useDispatch } from "react-redux";
import cross from "../../images/blackcross.png";
import { useCookies } from "react-cookie";
import { seninvitedb } from "../../store/Workspace/workspace-actions";
export const WorkspaceInviteModal = React.memo((props) => {
  let vala = "";
  const [cookies, setCookie] = useCookies(["user"]);

  const dispatch = useDispatch();
  const getinviteemail = (e) => {
    vala = e.target.value;
  };
  const sendinvite = async () => {
    dispatch(
      seninvitedb(
        cookies.Token,
        {
          value: vala,
          groupid: props.workspace.latestboard.id,
          workspaceid: props.workspace.latestworkspace.id,
          groupname: props.workspace.latestboard.name,
        },
        props.workspace
      )
    );
  };
  return (
    <div className={classes.abs}>
      <Modal
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${classes.mymodal} ${classes.margins}`}
        centered
      >
        <img src={cross} className={classes.relcross} onClick={props.onclose} />
        <ModalBody className={`${classes.modalbody}`}>
          <div className="row">
            <div className="col-md-12">
              <div className="px-5">
                <h1 className={classes.timeheading}>Invite Member</h1>
                <p className={classes.para}>
                  Invite member to your board by email!
                </p>
                <div className={`input-group ${classes.forminput}`}>
                  <input
                    className={`form-control py-2 ${classes.searchbar}`}
                    type="text"
                    placeholder="Please Enter Email"
                    onChange={getinviteemail}
                  />
                  {/* <span className="input-group-append">
                    <button
                      className={`btn btn-outline-secondary border-0 border ${classes.search}`}
                      type="button"
                    >
                      <i className={`fa fa-search ${classes.searchicon}`}></i>
                    </button>
                  </span> */}
                </div>
              </div>
              <div className="d-flex justigy-content-center">
                <button
                  className={`btn-lg btn-primary mx-auto ${classes.btnresult}`}
                  onClick={sendinvite}
                >
                  Invite
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
});
