import { SignupDetails } from "../../components/Signupdetails/SignupDetails";
import { InputPassword } from "../../components/InputPassword/InputPassword";
import { Link } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

import PersonalDetailsImg from "../../images/Signup/PersonalDetails.png";
import classes from "./PersonalDetails.module.css";
export const PersonalDetails = (props) => {
  const formik = useFormik({
    initialValues: {
      password: "",
      finalpassword: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .required("Password is Required")
        .min(8, "Password is too short - should be 8 chars minimum."),
      finalpassword: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "Password and Confrm Password must match"
        ),
    }),
  });
  return (
    <SignupDetails>
      <div className={`row ${classes.contentheight}`}>
        <div
          className={`col-md-6 d-flex flex-column align-items-center justify-content-center ${classes.bgblue}`}
        >
          <img src={PersonalDetailsImg} className="img-fluid" />
          <h5 className="mt-4">
            <b>
              Add your<span className={`${classes.bgblues}`}> details</span> so
              you can login later
            </b>
          </h5>
        </div>
        <div className={`col-md-6 pt-5 px-4 ${classes.inputmargin}`}>
          <h4 className="mt-5">
            <b>Enter Your Profile Details</b>
          </h4>
          <div className="form-group">
            <label className="my-2 mt-3">
              <b>Full Name</b>
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter your name"
            ></input>
          </div>
          <div className="form-group">
            <label className="my-2 mt-3">
              <b>Account Name</b>
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter your name"
            ></input>
          </div>
          <label className="my-2 mt-3">
            <b>Password</b>
          </label>
          <InputPassword
            formik={formik}
            password={true}
            classes="form-control-lg"
          />
          <div className="d-flex align-items-center  mt-3">
            <input
              type="radio"
              style={{ width: "20px", height: "20px" }}
            ></input>

            <div
              style={{
                fontSize: "15px",
                color: "gray",
              }}
              className={`${classes.paraa}`}
            >
              I agree to the terms of services and privacy policies
            </div>
          </div>
          <div className="d-flex mt-3 justify-content-end">
            <button
              className="btn btn-primary  btn-lg"
              style={{ fontSize: "16px", width: "90px", borderRadius: "12px" }}
            >
              <Link
                to="/whyyouhere"
                style={{ textDecoration: "none", color: "white" }}
              >
                Next
              </Link>
            </button>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
