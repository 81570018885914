import classes from "./AdvantageItems.module.css";
let AdvantageItems = (props) => {
  return (
    <div className="col-lg-4 col-md-6" style={{ padding: "80px" }}>
      <div className={`${classes.totalheight}`}>
        <div className={`${classes.imagecover}`}>
          <img
            className={`${classes.advatagesphotos}`}
            src={props.photo}
            width="120px"
          />
        </div>
        <h3 style={{ marginTop: "120px" }}>{props.name}</h3>
        <p
          className={classes.descs}
          style={{ textAlign: "left", margin: "20px", fontSize: "20px" }}
        >
          {props.description}
        </p>
      </div>
    </div>
  );
};
export default AdvantageItems;
