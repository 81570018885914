import React, { useState } from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { workspaceActions } from "../../store/Workspace/workspace-slice";
import { changeRole } from "../../store/Workspace/workspace-actions";

export const RoleCheckbox = (props) => {
  let finalval = props.val === "1" ? true : false;
  const [val, setVal] = useState(finalval);
  const workspace = useSelector((state) => state.workspace);
  const dispatch = useDispatch();
  return (
    <div className="d-flex align-items-center justify-content-center">
      <FormControlLabel
        control={
          <Checkbox
            checked={val}
            onChange={(e) => {
              setVal(e.target.checked);
              dispatch(changeRole("operatemember", props.id));
            }}
            style={{
              color: "#1565d8",
            }}
            value="task1"
          />
        }
        label={
          <Typography
            variant="h6"
            style={{ color: "black", fontSize: "16px", fontWeight: "400" }}
          ></Typography>
        }
      />
    </div>
  );
};
