import { SignupDetails } from "../../components/Signupdetails/SignupDetails";
import { useState } from "react";
import BuzzupLogo from "../../images/buzzup_logo.png";
import classes from "./WhyYouHere.module.css";
import Button from "../../components/SpecialComponents/Button";
import { Link } from "react-router-dom";
export const Whyyouhere = (props) => {
  let objCheck = [
    { name: "Work", active: true, data: ["Business Owner", "Team Lead"] },
    { name: "Personal", active: false, data: [] },
    {
      name: "School",
      active: false,
      data: ["Undergraduate student", "Graduate Student", "Others"],
    },
    {
      name: "Nonprofit",
      active: false,
      data: [
        "Board Member",
        "Executive",
        "Employee",
        "Volunteer",
        "It Staff",
        "Others",
      ],
    },
  ];
  let [whyusehere, setWhyyouhere] = useState(objCheck);
  let onButClick = (newItems) => {
    setWhyyouhere(newItems);
  };
  let renderedbuttons = [];
  whyusehere.map(function (item) {
    if (item.active === true) {
      // console.log(item.data);
      item.data.map(function (item2, i) {
        console.log(item2);

        renderedbuttons.push(
          <Button
            text={item2}
            classeformargin={classes.classformargin}
          ></Button>
        );
      });
    } else {
    }
  });
  console.log(renderedbuttons);
  return (
    <SignupDetails>
      <div className={` ${classes.contentheight}`}>
        <div className={`row`}>
          <div className={`col-md-12 ${classes.paddings}`}>
            <div className="text-center">
              <img src={BuzzupLogo} width="300px" />
            </div>
            <h4>
              <b>Why are you here Today?</b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div
            className={`col-md-12 d-flex justify-content-between ${classes.paddingss}`}
          >
            {objCheck.map(function (item, i) {
              return (
                <Button
                  text={item.name}
                  onchangeval={onButClick}
                  classeformargin={classes.classformargins}
                ></Button>
              );
            })}
          </div>
        </div>
        <div className={`row`}>
          <div className={`col-md-12 ${classes.paddings}`}>
            <h4>
              <b>
                <span style={{ color: "gray" }}>What is your </span> Current
                Role?
              </b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div
            className={`col-md-12 d-flex flex-wrap justify-content-start ${classes.paddingss}`}
          >
            {renderedbuttons}
          </div>
          <div
            className={`col-md-12 d-flex justify-content-between mt-5 mb-5 ${classes.paddingss}`}
          >
            <button
              className="btn"
              style={{
                border: "1px solid black",
                width: "100px",
                height: "50px",
                borderRadius: "10px",
              }}
            >
              Back
            </button>
            <button
              className="btn"
              style={{
                border: "1px solid white",
                backgroundColor: "blue",
                borderRadius: "10px",
                color: "white",
                width: "100px",
                height: "50px",
              }}
            >
              <Link
                to="/chooseyourfield"
                style={{ textDecoration: "none", color: "white" }}
              >
                Next
              </Link>
            </button>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
