import classes from "./WorkspaceMembersModal.module.css";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import DatePicker from "react-datepicker";
import CustomPaginationActionsTableMembersItem from "./CustomPaginationActionsTableMembersItem";
import cross from "../../images/blackcross.png";
import attachment from "../../images/workspace/attachment.png";
import { workspaceActions } from "../../store/Workspace/workspace-slice";
import { useSelector, useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect, useRef } from "react";

export const WorkspaceSearchTimeModal = (props) => {
  const workspace = useSelector((state) => state.workspace);
  const dispatch = useDispatch();

  let [start, setStart] = useState(false);
  let [end, setEnd] = useState(false);
  const updateVal = (arr) => {
    console.log(arr);
    let okz = `${new Date(arr[0]).getFullYear()}-${
      new Date(arr[0]).getMonth() + 1
    }-${new Date(arr[0]).getDate()}`;
    if (arr[1] === "end") {
      console.log(
        `${new Date(arr[0]).getFullYear()}-${
          new Date(arr[0]).getMonth() + 1
        }-${new Date(arr[0]).getDate()}`
      );
      end = `${new Date(arr[0]).getFullYear()}-${
        new Date(arr[0]).getMonth() + 1
      }-${new Date(arr[0]).getDate()}`;
      setEnd(
        `${new Date(arr[0]).getFullYear()}-${
          new Date(arr[0]).getMonth() + 1
        }-${new Date(arr[0]).getDate()}`
      );
    }
    if (arr[1] === "start") {
      start = `${new Date(arr[0]).getFullYear()}-${
        new Date(arr[0]).getMonth() + 1
      }-${new Date(arr[0]).getDate()}`;
      setStart(
        `${new Date(arr[0]).getFullYear()}-${
          new Date(arr[0]).getMonth() + 1
        }-${new Date(arr[0]).getDate()}`
      );
    }

    if (end !== false && start !== false) {
      dispatch(
        workspaceActions.searchZone({
          searchZone: workspace.searchZone === false ? true : true,
        })
      );
      let groupsToProcess = JSON.parse(JSON.stringify(workspace.groups));

      groupsToProcess = groupsToProcess.map((item) => {
        item.items = item.items.filter((items) => {
          console.log("new2");
          console.log(
            Number(new Date(items.created_at).getTime()),
            Number(new Date(start).getTime()),
            Number(new Date(items.created_at).getTime()),
            Number(new Date(end).getTime()) + 86400000
          );
          console.log(
            Number(new Date(items.created_at).getTime()) >=
              Number(new Date(start).getTime()),
            Number(new Date(items.created_at).getTime()) <=
              Number(new Date(end).getTime()) + 86400000
          );
          if (
            Number(new Date(items.created_at).getTime()) >=
              Number(new Date(start).getTime()) &&
            Number(new Date(items.created_at).getTime()) <=
              Number(new Date(end).getTime()) + 86400000
          ) {
            return true;
          } else {
            return false;
          }
        });
        return item;
      });
      console.log(groupsToProcess);
      dispatch(
        workspaceActions.searchGroups({
          searchGroups: groupsToProcess,
        })
      );
    }
  };
  return (
    <div className={classes.abs}>
      <Modal
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${classes.mymodal} ${classes.margins}`}
        centered
      >
        <img src={cross} className={classes.relcross} onClick={props.onclose} />
        <ModalBody className={`${classes.modalbody}`}>
          <div className="row justify-content-center">
            <div className="col-md-4 mt-5">
              <div className="mb-2">
                <h1>Start Date:</h1>
                {start === false && (
                  <DatePicker
                    maxDate={new Date(Date.now())}
                    selected={false}
                    onChange={(date) => {
                      updateVal([date, "start"]);
                    }}
                  />
                )}
                {start !== false && (
                  <DatePicker
                    maxDate={new Date(Date.now())}
                    selected={
                      start !== false
                        ? new Date(start)
                        : new Date(
                            `${new Date(Date.now()).getFullYear()}-${
                              new Date(Date.now()).getMonth() + 1
                            }-${new Date(Date.now()).getDate()}`
                          )
                    }
                    onChange={(date) => {
                      updateVal([date, "start"]);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="mb-2">
                <h1>End Date:</h1>
                {end === false && (
                  <DatePicker
                    maxDate={new Date(Date.now())}
                    selected={false}
                    onChange={(date) => {
                      updateVal([date, "end"]);
                    }}
                  />
                )}
                {end !== false && (
                  <DatePicker
                    maxDate={new Date(Date.now())}
                    selected={
                      end !== false
                        ? new Date(end)
                        : new Date(
                            `${new Date(Date.now()).getFullYear()}-${
                              new Date(Date.now()).getMonth() + 1
                            }-${new Date(Date.now()).getDate()}`
                          )
                    }
                    onChange={(date) => {
                      updateVal([date, "end"]);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
