import React from 'react';
import './newsletter.css';

export const NewsLetter = () => {
  return (
    <div className='newsletter-main'>
      <div className='newsletter'>
        <input type="text" placeholder='Enter your email Address' />
        <div className='subscribe-btn'>
          <button type='submit'>Subscribe</button>
        </div>
      </div>
    </div>
  )
}
