import classes from "./TimeWarningPopup.module.css";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import CustomPaginationActionsTable from "./CustomPaginationActionsTable";
import cross from "../../images/blackcross.png";

export const TimeWarningPopup = (props) => {
  return (
    <div className={classes.abs}>
      <Modal
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${classes.mymodal} ${classes.margins}`}
        centered
      >
        <img src={cross} className={classes.relcross} onClick={props.onclose} />
        <ModalBody className={`${classes.modalbody}`}>
          <div className="row my-2">
            <div className="col-md-12 text-center">
              <h5>Please Turn off your time logs before leaving a system</h5>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
