import React from "react";
import "./banner.css";
import HorizontalScroll from "react-scroll-horizontal";
import bannerImg from "../../images/banner-img.png";
import category1 from "../../images/icons/category1.png";
import category2 from "../../images/icons/category2.png";
import category3 from "../../images/icons/category3.png";
import category4 from "../../images/icons/category4.png";
import category5 from "../../images/icons/category5.png";
import category6 from "../../images/icons/category6.png";
import category7 from "../../images/icons/statisticsslider.png";
import category8 from "../../images/icons/nfcslider.png";
import category9 from "../../images/icons/contractslider.png";

export const HomeBanner = () => {
  const child = { width: `30em`, height: `100%`, baclgroundColor: `red` };
  const parent = { width: `60em`, height: `100%` };
  return (
    <div className="banner-main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="banner-title">
              <p>
                A platform Built
                <br /> foR a new way of
                <br /> working
              </p>
              <p>
                What would you like to manage with
                <br /> BuzzUp.com
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
                <br /> Lo rem Ipsum has been the industry's standard dummy text
                ever since.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-img">
              <img alt="img" src={bannerImg} />
            </div>
          </div>
        </div>
      </div>
      <div className="curve-back"></div>

      <div className={"row"} style={{ width: "100%", height: "300px" }}>
        {/* <HorizontalScroll className={"scroller"} style={{ overflowX: "auto" }}> */}
        <HorizontalScroll className={"scroller"} style={{ overflowX: "auto" }}>
          <div className={"col-md-2 p-2 px-5  hover1 containerwidth"}>
            <div className={"category-flex itemheight"}>
              <div className={"category"}>
                <img alt={"design"} src={category1} />
              </div>
              <p className={"category-title"}>Design</p>
            </div>
          </div>
          <div className="col-md-2 p-2 px-5 containerwidth">
            <div className="category-flex itemheight">
              <div className="category hover2">
                <img alt="management" src={category2} />
              </div>
              <p className="category-title">Project Management</p>
            </div>
          </div>
          <div className="col-md-2 p-2 px-5 hover3 containerwidth">
            <div className="category-flex itemheight">
              <div className="category">
                <img alt="sales" src={category3} />
              </div>
              <p className="category-title">Sales</p>
            </div>
          </div>
          <div className="col-md-2 hover4 p-2 px-5 containerwidth">
            <div className="category-flex itemheight">
              <div className="category">
                <img alt="marketing" src={category4} />
              </div>
              <p className="category-title">Marketing</p>
            </div>
          </div>
          <div className="col-md-2 hover5 p-2 px-5 containerwidth">
            <div className="category-flex itemheight">
              <div className="category">
                <img alt="software" src={category5} />
              </div>
              <p className="category-title">
                Software
                <br /> Development
              </p>
            </div>
          </div>

          <div className="col-md-2 p-2 px-5 containerwidth">
            <div className="category-flex itemheight">
              <div className="category hover6">
                <img alt="hr" src={category6} />
              </div>
              <p className="category-title">Hr</p>
            </div>
          </div>
          <div className="col-md-2 p-2 px-5 containerwidth">
            <div className="category-flex itemheight">
              <div className="category">
                <img
                  className="my-5"
                  alt="Digital Business Card"
                  src={category7}
                />
              </div>
              <p className="category-title">Digital Business Card</p>
            </div>
          </div>
          <div className="col-md-2 p-2 px-5 containerwidth">
            <div className="category-flex itemheight">
              <div className="category hover6">
                <img alt="Digital Business Card" src={category8} />
              </div>
              <p className="category-title">NFC Shop</p>
            </div>
          </div>
          <div className="col-md-2 p-2 px-5 containerwidth">
            <div className="category-flex itemheight">
              <div className="category hover6">
                <img alt="Digital Business Card" src={category9} />
              </div>
              <p className="category-title">Digital Forms</p>
            </div>
          </div>
        </HorizontalScroll>
      </div>
      {/* <div className="categories-main">
        <div className="category-flex hover1">
          <div className="category">
            <img alt="design" src={category1} />
          </div>
          <p className="category-title">Design</p>
        </div>
        <div className="category-flex hover2">
          <div className="category">
            <img alt="management" src={category2} />
          </div>
          <p className="category-title">
            Project
            <br /> Management
          </p>
        </div>
        <div className="category-flex hover3">
          <div className="category">
            <img alt="sales" src={category3} />
          </div>
          <p className="category-title">Sales</p>
        </div>
        <div className="category-flex hover4">
          <div className="category">
            <img alt="marketing" src={category4} />
          </div>
          <p className="category-title">Marketing</p>
        </div>
        <div className="category-flex hover5">
          <div className="category">
            <img alt="software" src={category5} />
          </div>
          <p className="category-title">
            Software
            <br /> Development
          </p>
        </div>

        <div className="category-flex hover6">
          <div className="category">
            <img alt="hr" src={category6} />
          </div>
          <p className="category-title">Hr</p>
        </div>
      </div> */}
      <div className="get-started-btn">
        <button>
          Get Started <i className="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};
