import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import classes from "./SignupDetails.module.css";
export const SignupDetails = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <div className={classes.mainimg}></div>
      <div style={{ position: "absolute", color: "white" }}>
        <Modal
          show={true}
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName={`${classes.mymodal} ${classes.margins}`}
          centered
        >
          <ModalBody className={`${classes.modalbody}`}>
            {props.children}
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};
