import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { RoleCheckbox } from "./RoleCheckbox";
import Paper from "@mui/material/Paper";
import dots from "../../images/workspace/dots.png";
import Girlone from "../../images/workspace/girlone.jpeg";
import Girltwo from "../../images/workspace/Girltwo.jpg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F3F6F9",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "white",
    zIndex: 999,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "td , th": {
    border: 1,
  },
}));

export default function CustomizedTables(props) {
  function createData(name, calories, fat, carbs, protein, total) {
    return { name, calories, fat, carbs, protein, total };
  }
  const rows = [];
  props.members.map((item) => {
    let abc = createData(
      `https://buzzuperpbackend.dejuretechnologies.com${item.image}`,
      item.name,
      item.email,
      item.operatemember,
      item.id
    );
    rows.push(abc);
  });
  return (
    <TableContainer style={{ backgroundColor: "white" }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Profile</StyledTableCell>
            <StyledTableCell align="center">Name</StyledTableCell>
            <StyledTableCell align="center">E-mail&nbsp;</StyledTableCell>
            <StyledTableCell align="center">Invite Role&nbsp;</StyledTableCell>
            {/* <StyledTableCell align="center">Role&nbsp;</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={Math.random()}>
              <StyledTableCell align="center">
                <img
                  src={row.name}
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "cover",
                    borderRadius: "50%",
                    border: "2px solid black",
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">{row.calories}</StyledTableCell>
              <StyledTableCell align="center">{row.fat}</StyledTableCell>
              <StyledTableCell align="center" justify="center">
                <RoleCheckbox val={row.carbs} id={row.protein} />
              </StyledTableCell>
              {/* <StyledTableCell align="center">
                {
                  <select>
                    <option>Admin</option>
                    <option>Member</option>
                  </select>
                }
              </StyledTableCell> */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
