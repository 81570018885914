import { useState } from "react";

import OtpInput from "react-otp-input";

export const OtpInputComponent = (props) => {
  let [otp, setOtp] = useState("");
  let handleChange = (otp) => setOtp(otp);
  props.checklength(otp);
  return (
    <OtpInput
      value={otp}
      inputStyle={{
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        border: "2px solid black",
      }}
      separator={<span>&nbsp;&nbsp;</span>}
      numInputs={6}
      onChange={handleChange}
    />
  );
};
