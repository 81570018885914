import React from "react";
import "./slider.css";
import Carousel from "react-spring-3d-carousel-2";
import slider1 from "../../images/slider1.png";
import slider2 from "../../images/slider2.png";
import slider3 from "../../images/slider3.png";

const slides = [
  {
    key: 1,
    content: <img src={slider1} alt="1" />,
  },
  {
    key: 2,
    content: <img src={slider2} alt="2" />,
  },
  {
    key: 3,
    content: <img src={slider3} alt="3" />,
  },
  {
    key: 4,
    content: <img src={slider2} alt="4" />,
  },
  {
    key: 5,
    content: <img src={slider3} alt="5" />,
  },
];

export const HomeSlider = () => {
  return (
    <>
      <div className="slider-main">
        <Carousel
          slides={slides}
          goToSlide={0}
          offsetRadius={2}
          showNavigation={false}
          autoPlay={true}
          interval={3}
        />
        <div className="get-started-btn pb-5">
          <button>Click Here To Join</button>
        </div>
      </div>
    </>
  );
};
