import { ListItemIcon } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { workspaceActions } from "../../store/Workspace/workspace-slice";

import {
  Container,
  SearchInput,
  IconRightArrow,
  IconMagnifyingGlass,
} from "./AnimatedSearchBo";

function Search() {
  const dispatch = useDispatch();

  const targetRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const showSearchInput = isHovered || isFocused;
  const workspace = useSelector((state) => state.workspace);

  const searchHandler = (e) => {
    if (e.target.value !== "") {
      console.log(workspace.groups);

      dispatch(
        workspaceActions.searchZone({
          searchZone: workspace.searchZone === false ? true : true,
        })
      );
      let groupsToProcess = JSON.parse(JSON.stringify(workspace.groups));

      groupsToProcess = groupsToProcess.map((item) => {
        item.items = item.items.filter((items) => {
          if (items.name.toUpperCase().includes(e.target.value.toUpperCase())) {
            return true;
          } else {
            return false;
          }
        });
        return item;
      });
      console.log(groupsToProcess);
      dispatch(
        workspaceActions.searchGroups({
          searchGroups: groupsToProcess,
        })
      );
    } else {
      dispatch(
        workspaceActions.searchZone({
          searchZone: false,
        })
      );
    }
  };

  useEffect(() => {
    targetRef.current.value = "";
  }, [showSearchInput]);

  return (
    <div className="row justify-content-end">
      <div
        className={`col-md-4 d-flex jutify-content-end`}
        style={{ position: "relative", height: "50px", marginRight: "20px" }}
      >
        <button
          style={{ position: "absolute", right: "0px" }}
          className="btn btn-success"
          onClick={() => {
            dispatch(
              workspaceActions.showaSearchTime({
                showaSearchTime: !workspace.buttonclicks.showaSearchTime,
              })
            );
          }}
        >
          Filter By Date
        </button>
        <Container
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          hover={showSearchInput}
        >
          <SearchInput
            ref={targetRef}
            onChange={searchHandler}
            showSearchInput={showSearchInput}
          />
          {showSearchInput ? <IconRightArrow /> : <IconMagnifyingGlass />}
        </Container>
      </div>
    </div>
  );
}

export default Search;
