import classes from "./ResetPassword.module.css";
import { Link } from "react-router-dom";
import { resetpassword } from "../../requests/requests";
import BuzzerLogo from "../../images/loginLogo.png";

import { useNavigate } from "react-router-dom";

import Arrow from "../../images/ForgotPassword/ArrowOne.png";
import Lock from "../../images/ForgotPassword/Lock.png";
import cross from "../../images/contactus/Cross.png";

import ContactUsImg from "../../images/contactus/ContactUsImg.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputPassword } from "../../components/InputPassword/InputPassword";
import { Button } from "react-bootstrap";

export const ResetPassword = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      finalpassword: "",
    },
    onSubmit: (values) => {
      console.log(values);
      resetpassword(values.password, navigate);
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .required("Password is Required")
        .min(8, "Password is too short - should be 8 chars minimum."),
      finalpassword: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "Password and Confrm Password must match"
        ),
    }),
  });

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <img src={cross} className={`${classes.imagecross}`} />
        </div>
      </div>
      <div className="row ">
        <div className={`col-lg-7 ${classes.col1Main}`}>
          <div className={`${classes.loginImg}`}>
            <div className={`${classes.ContactText}`}>
              <p className={`${classes.ContactTextHeading}`}></p>
              <p className={`${classes.ContactTextPara}`}></p>
            </div>
            <img className={`${classes.lockImga}`} src={Lock} />
          </div>
        </div>
        <div className={`col-lg-5 pt-5 ${classes.col2Main}`}>
          <div className={`${classes.contactLogo}`}>
            <img alt="Buzzup" src={BuzzerLogo} />
          </div>
          <div className={`${classes.contactusContainer}`}>
            <p>
              Recover <span style={{ color: "Gray" }}>Your Password?</span>
            </p>
            <p style={{ fontSize: "18px" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
              dignissimos placeat minus dicta.
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className={` ${classes.resetFinal}`}>
              <div className="signin-input mt-4">
                <p style={{ fontWeight: "700" }}>Type Password</p>
                <InputPassword formik={formik} password={true} />
              </div>
              <div className="signin-input mt-4">
                <p style={{ fontWeight: "700" }}>Retype Password</p>
                <InputPassword formik={formik} confirmpassword={true} />
              </div>
            </div>
            <div className="d-grid gap-2 mt-1">
              <Button
                variant="primary"
                className="d-flex justify-content-center mt-4 align-items-center"
                size="lg"
                type="submit"
              >
                <div> Reset Password</div>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
