import classes from "./WorkspaceMembersModal.module.css";
import Modal from "react-bootstrap/Modal";
import { CheckboxItemDetails } from "./CheckboxItemDetails";
import ModalBody from "react-bootstrap/ModalBody";
import DatePicker from "react-datepicker";
import CustomPaginationActionsTableMembersItem from "./CustomPaginationActionsTableMembersItem";
import cross from "../../images/blackcross.png";
import attachment from "../../images/workspace/attachment.png";
import { workspaceActions } from "../../store/Workspace/workspace-slice";
import { useSelector, useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { WorkspaceAddChecklistItem } from "./WorkspaceAddChecklistItem";
import {
  updateTaskDescription,
  updateTaskDeadline,
  getTaskData,
  updateTaskDocuments,
  CreateCheckList,
} from "../../store/Workspace/workspace-actions";
import "react-datepicker/dist/react-datepicker.css";
import todolist from "../../images/workspace/to-do-list.png";
import { useState, useEffect, useRef } from "react";

export const WorkspaceTaskDetailsModel = (props) => {
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);
  const inputFile = useRef(null);
  const [todolistName, settodolistName] = useState(false);
  const [MyChecklistName, setMyChecklistName] = useState("");
  const [MyChecklistNames, setMyChecklistNames] = useState("");
  console.log(workspace.useablevariables);
  useEffect(() => {
    dispatch(getTaskData(workspace.useablevariables.itemDetailsStatusId));
  }, []);
  const adddeadline = (data) => {
    console.log(data[1], data[0]);
    dispatch(updateTaskDeadline(data[1], data[0]));
  };
  const OpenClicks = () => {
    settodolistName((previous) => {
      return !previous;
    });
  };
  const saveFile = (url) => {
    saveAs(url);
  };
  const saveChecklist = (data) => {
    console.log(data[1], data[0]);

    dispatch(CreateCheckList(data[1], data[0], workspace));
  };
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };
  const [startDate, setStartDate] = useState(
    `${new Date(Date.now()).getFullYear()}-${
      new Date(Date.now()).getMonth() + 1
    }-${new Date(Date.now()).getDate()}`
  );
  let [timer, settimer] = useState("");
  let iop = (val) => {
    return () => {
      dispatch(
        workspaceActions.setItemDetails({
          itemDetails: JSON.parse(
            JSON.stringify({
              ...JSON.parse(
                JSON.stringify(workspace.useablevariables.itemDetails)
              ),
              description: val,
            })
          ),
        })
      );
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        dispatch(
          updateTaskDescription(
            val,
            workspace.useablevariables.itemDetailsStatusId
          )
        );
      }, 1000);

      settimer((pre) => timer);
    };
  };
  const abc = (e) => {
    let abac = iop(e.target.value);
    abac();
  };
  const uploadDocument = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    // setImage(fileObj);

    console.log("fileObj is", fileObj);

    // 👇️ reset file input
    event.target.value = null;

    // 👇️ is now empty
    console.log(event.target.files);

    // 👇️ can still access file object here
    console.log(fileObj);
    console.log(fileObj.name);
    dispatch(
      updateTaskDocuments(
        fileObj,
        workspace.useablevariables.itemDetailsStatusId,
        workspace
      )
    );
  };
  return (
    <div className={classes.abs}>
      <Modal
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${classes.mymodal} ${classes.margins}`}
        centered
      >
        <img src={cross} className={classes.relcross} onClick={props.onclose} />
        <ModalBody className={`${classes.modalbody}`}>
          <div className="row">
            <div className="col-md-8">
              <div className="px-5">
                <h3>Description</h3>
                <textarea
                  name=""
                  id=""
                  className="form-control"
                  cols="30"
                  rows="10"
                  value={workspace.useablevariables.itemDetails.description}
                  onChange={abc}
                ></textarea>
                {workspace.useablevariables &&
                  workspace.useablevariables.itemDetails &&
                  workspace.useablevariables.itemDetails.checklist &&
                  workspace.useablevariables.itemDetails.checklist.map(
                    (item) => (
                      <div key={item.id}>
                        <h3>{item.name}</h3>
                        {item.additem &&
                          item.additem.map((items) => (
                            <div
                              key={items.id}
                              className={`d-flex align-items-center p-2 mt-4`}
                              style={{
                                backgroundColor: "lightgray",
                                borderRadius: "15px",
                              }}
                              // onClick={onButtonClick}
                            >
                              <CheckboxItemDetails
                                key={items.id}
                                stat={items.status}
                                id={items.id}
                                workspace={workspace}
                              />
                              <h5
                                className="mt-1"
                                style={{ marginLeft: "15px" }}
                              >
                                {items.item_name}
                              </h5>
                            </div>
                          ))}
                        <WorkspaceAddChecklistItem id={item.id} />
                      </div>
                    )
                  )}
                {workspace.useablevariables.itemDetails &&
                  workspace.useablevariables.itemDetails.documents &&
                  workspace.useablevariables.itemDetails.documents.length >
                    0 && <h3>Attachments</h3>}
                {workspace.useablevariables.itemDetails &&
                  workspace.useablevariables.itemDetails.documents &&
                  workspace.useablevariables.itemDetails.documents.map(
                    (item) => (
                      <div
                        className="form-control d-flex align-items-center mt-2"
                        style={{ backgroundColor: "#D3D3D3" }}
                        onClick={saveFile.bind(
                          null,
                          `https://buzzuperpbackend.dejuretechnologies.com${item}`
                        )}
                      >
                        <div
                          style={{
                            width: "90px",
                            height: "50px",
                            backgroundColor: "#b7b7b7",
                          }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          {item.split("documents")[1].split(".")[1]}
                        </div>
                        <h4
                          style={{
                            maxWidth: "60%",
                            marginLeft: "20px",
                            wordWrap: "break-word",
                          }}
                        >
                          {item.split("documents")[1].slice(1)}
                        </h4>
                      </div>
                    )
                  )}
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <div className="mb-2">
                <DatePicker
                  minDate={new Date(Date.now())}
                  selected={
                    workspace.useablevariables.itemDetails.deadline
                      ? new Date(
                          workspace.useablevariables.itemDetails.deadline
                        )
                      : new Date()
                  }
                  onChange={(date) => {
                    console.log(
                      `${new Date(date).getFullYear()}-${
                        new Date(date).getMonth() + 1
                      }-${new Date(date).getDate()}`
                    );
                    dispatch(
                      workspaceActions.setItemDetails({
                        itemDetails: JSON.parse(
                          JSON.stringify({
                            ...JSON.parse(
                              JSON.stringify(
                                workspace.useablevariables.itemDetails
                              )
                            ),
                            deadline: `${new Date(date).getFullYear()}-${
                              new Date(date).getMonth() + 1
                            }-${new Date(date).getDate()}`,
                          })
                        ),
                      })
                    );
                  }}
                />
              </div>
              <button
                className="btn btn-danger form-control"
                onClick={adddeadline.bind(null, [
                  workspace.useablevariables.itemDetailsStatusId,
                  workspace.useablevariables.itemDetails.deadline,
                ])}
              >
                Assign Deadline
              </button>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={uploadDocument}
              />
              <div
                className={`d-flex align-items-center p-2 mt-4`}
                style={{ backgroundColor: "lightgray", borderRadius: "15px" }}
                onClick={onButtonClick}
              >
                <img src={attachment} style={{ width: "20px" }} alt="" />
                <h5 className="mt-1" style={{ marginLeft: "15px" }}>
                  Attachment
                </h5>
              </div>
              <div
                className={`d-flex align-items-center p-2 mt-4`}
                style={{ backgroundColor: "lightgray", borderRadius: "15px" }}
                onClick={OpenClicks}
              >
                <img src={todolist} style={{ width: "20px" }} alt="" />
                <h5 className="mt-1" style={{ marginLeft: "15px" }}>
                  To Do List
                </h5>
              </div>
              <div
                className={`d-flex align-items-center p-2 mt-4 ${
                  todolistName === false ? "d-none" : "d-flex"
                }`}
                // onClick={onButtonClick}
              >
                <input
                  type="text"
                  placeholder="To Do List Name"
                  className="form-control"
                  onChange={(e) => {
                    setMyChecklistName(e.target.value);
                  }}
                />
                {MyChecklistName.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={saveChecklist.bind(null, [
                      MyChecklistName,
                      workspace.useablevariables.itemDetailsStatusId,
                    ])}
                  >
                    Save
                  </button>
                )}
                {MyChecklistName.length === 0 && (
                  <button type="button" disabled className="btn btn-success">
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
