import { SignupDetails } from "../../components/Signupdetails/SignupDetails";
import { useState } from "react";
import BuzzupLogo from "../../images/buzzup_logo.png";
import classes from "./ChooseYourField.module.css";
import { Link } from "react-router-dom";

import Button from "../../components/SpecialComponents/Button";
export const Chooseyourfield = (props) => {
  return (
    <SignupDetails>
      <div className={` ${classes.contentheight}`}>
        <div className={`row`}>
          <div className={`col-md-12 ${classes.paddings}`}>
            <div className="text-center">
              <img src={BuzzupLogo} width="300px" />
            </div>
            <h4>
              <b>Why are you here Today?</b>
            </h4>
          </div>
        </div>
        <div className={`row ${classes.paddingss}`}>
          <div className={`col-md-6 d-flex justify-content-between `}>
            <Button
              text={"HR"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
            ></Button>
          </div>
          <div className={`col-md-6 d-flex justify-content-between `}>
            <Button
              text={"Design"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
            ></Button>
          </div>
          <div className={`col-md-6 d-flex justify-content-between `}>
            <Button
              text={"Marketting"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
            ></Button>
          </div>
          <div className={`col-md-6 d-flex justify-content-between `}>
            <Button
              text={"sales"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
            ></Button>
          </div>
          <div className={`col-md-6 d-flex justify-content-between `}>
            <Button
              text={"Software Development"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
            ></Button>
          </div>
          <div className={`col-md-6 d-flex justify-content-between `}>
            <Button
              text={"Project Management"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
            ></Button>
          </div>
          <div className={`col-md-6 d-flex justify-content-between `}>
            <Button
              text={"Finance"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
            ></Button>
          </div>
          <div
            className={`col-md-12 d-flex justify-content-between mt-5 mb-5 ${classes.paddingss}`}
          >
            <button
              className="btn"
              style={{
                border: "1px solid black",
                width: "100px",
                height: "50px",
                borderRadius: "10px",
              }}
            >
              Back
            </button>
            <button
              className="btn"
              style={{
                border: "1px solid white",
                backgroundColor: "blue",
                borderRadius: "10px",
                color: "white",
                width: "100px",
                height: "50px",
              }}
            >
              <Link
                to="/peoplesurvey"
                style={{ textDecoration: "none", color: "white" }}
              >
                Next
              </Link>
            </button>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
