import classes from "./WorkspaceColorModal.module.css";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import cross from "../../images/blackcross.png";
import tick from "../../images/workspace/tick.png";
import { useCookies } from "react-cookie";
import { updateitemindb } from "../../store/Workspace/workspace-actions";
import { useSelector, useDispatch } from "react-redux";
import { workspaceActions } from "../../store/Workspace/workspace-slice";

export const WorkspaceColorModal = (props) => {
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);
  console.log(workspace);
  const [cookies, setCookie] = useCookies(["user"]);
  console.log(workspace.useablevariables.itemStatusId);
  console.log(workspace.useablevariables.itemStatus);
  const changeStatus = (status) => {
    if (status === "Done") {
      dispatch(workspaceActions.setcheckAutoTimeOff({ act: true }));
    }
    dispatch(
      updateitemindb(
        cookies.Token,
        {
          id: workspace.useablevariables.itemStatusId,
          status,
        },
        workspace
      )
    );
    let latestgroup = JSON.parse(JSON.stringify(workspace.latestgroup));
    let groups = JSON.parse(JSON.stringify(workspace.groups));

    console.log(groups);

    latestgroup.items = latestgroup.items.map((item) => {
      console.log(
        Number(item.id) === Number(workspace.useablevariables.itemStatusId)
      );
      if (Number(item.id) === Number(workspace.useablevariables.itemStatusId)) {
        item.task_status = status;
        console.log(status);
        return item;
      }
      return item;
    });

    dispatch(workspaceActions.updatelatestgroup(latestgroup));
    groups = groups.map((item) => {
      item.items = item.items.map((item2) => {
        if (
          Number(item2.id) === Number(workspace.useablevariables.itemStatusId)
        ) {
          item2.task_status = status;
          console.log(status);
          return item2;
        }
        return item2;
      });
      return item;
    });
    dispatch(workspaceActions.updategroups(groups));
    dispatch(
      workspaceActions.changeItemStatusId({
        ...workspace.useablevariables,
        itemStatus: status,
      })
    );
  };
  return (
    <div className={classes.abs}>
      <Modal
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${classes.mymodal} ${classes.margins}`}
        centered
      >
        <div>
          <img
            src={cross}
            className={classes.relcross}
            alt="car"
            onClick={props.onclose}
          />
        </div>
        <ModalBody className={`${classes.modalbody}`}>
          <h1 className={classes.taskstatus}>Task Status</h1>
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="">
                {workspace.useablevariables.itemStatus === "none" && (
                  <>
                    <div
                      className={classes.bgdarkblue}
                      onClick={changeStatus.bind(null, "Done")}
                    >
                      Done
                    </div>

                    <div
                      className={classes.bgpink}
                      onClick={changeStatus.bind(null, "Working")}
                    >
                      <span>Working on it</span>
                    </div>

                    <div
                      className={classes.bgblue}
                      onClick={changeStatus.bind(null, "Stuck")}
                    >
                      Stuck
                    </div>
                  </>
                )}
                {workspace.useablevariables.itemStatus !== "none" && (
                  <>
                    {workspace.useablevariables.itemStatus === "Done" && (
                      <>
                        <div
                          className={classes.bgdarkblue}
                          onClick={changeStatus.bind(null, "Done")}
                        >
                          Done
                          <img src={tick} className={classes.tick} alt="" />
                        </div>
                        <div
                          className={classes.bgpink}
                          onClick={changeStatus.bind(null, "Working")}
                        >
                          <span>Working on it</span>
                        </div>
                        <div
                          className={classes.bgblue}
                          onClick={changeStatus.bind(null, "Stuck")}
                        >
                          Stuck
                        </div>
                      </>
                    )}

                    {workspace.useablevariables.itemStatus === "Working" && (
                      <>
                        <div
                          className={classes.bgdarkblue}
                          onClick={changeStatus.bind(null, "Done")}
                        >
                          Done
                        </div>
                        <div className={classes.bgpink}>
                          <span>Working on it</span>

                          <img src={tick} className={classes.tick} alt="" />
                        </div>
                        <div
                          className={classes.bgblue}
                          onClick={changeStatus.bind(null, "Stuck")}
                        >
                          Stuck
                        </div>
                      </>
                    )}
                    {workspace.useablevariables.itemStatus === "Stuck" && (
                      <>
                        <div
                          className={classes.bgdarkblue}
                          onClick={changeStatus.bind(null, "Done")}
                        >
                          Done
                        </div>
                        <div
                          className={classes.bgpink}
                          onClick={changeStatus.bind(null, "Working")}
                        >
                          <span>Working on it</span>
                        </div>
                        <div className={classes.bgblue}>
                          Stuck
                          <img src={tick} className={classes.tick} alt="" />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
