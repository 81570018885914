import { useState } from "react";
import classes from "./Button.module.css";
const Button = (props) => {
  const [chkValue, setChkValue] = useState(false);
  let onCheckProcess = () => {
    setChkValue(!chkValue);
  };
  let objCheck = [
    { name: "Work", active: true, data: ["Business Owner", "Team Lead"] },
    { name: "Personal", active: false, data: [] },
    {
      name: "School",
      active: false,
      data: ["Undergraduate student", "Graduate Student", "Others"],
    },
    {
      name: "Nonprofit",
      active: false,
      data: [
        "Board Member",
        "Executive",
        "Employee",
        "Volunteer",
        "It Staff",
        "Others",
      ],
    },
  ];
  let [color, setColor] = useState(false);
  let [cola, setCola] = useState(`${classes.white}`);

  let setCollor = (value) => {
    if (color === true) {
      setCola(`${classes.whites}`);
      if (props.onCheckProcess) {
        onCheckProcess();
      }
    } else {
      if (props.onCheckProcess) {
        onCheckProcess();
      }
      if (props.onchangeval) {
        objCheck = objCheck.map((item) => {
          if (item.active) {
            item.active = false;
            return item;
          }
          return item;
        });
        objCheck = objCheck.map((item) => {
          if (item.name === value) {
            item.active = true;
            return item;
          }
          return item;
        });
        props.onchangeval(objCheck);
        console.log(value);
      }
      setCola(`${classes.white}`);
    }
    setColor(color === true ? false : true);
  };

  let classs = "";

  let setBackground = (e) => {
    if (color === true) {
      setCola(`${classes.whites}`);
    } else {
      setCola(`${classes.white}`);
    }
  };
  return (
    <>
      <button
        className={`${color ? classes.blue : cola}  ${classes.btnstyle} ${
          props.classeformargin ? props.classeformargin : ""
        }`}
        style={props.extraStyle}
        onClick={setCollor.bind(null, props.text)}
        onMouseMove={setBackground}
      >
        {props.text}
      </button>
      {props.onCheckProcess && (
        <input
          type="checkbox"
          checked={chkValue}
          style={{
            width: "20px",
            height: "20px",
            position: "absolute",
            top: "50%",
            left: "10%",
          }}
        />
      )}
    </>
  );
};
export default Button;
