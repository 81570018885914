import home from "../../images/workspace/home.png";
import Add from "../../images/workspace/plus.png";
import classes from "./WorkspacesBoardDropdown.module.css";
import { useSelector, useDispatch } from "react-redux";

export const WorkspacesBoardDropdown = (props) => {
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);
  return (
    <div
      className={`${classes["boardsdropdown"]} ${
        props.status ? "d-block" : "d-none"
      }`}
      style={{ cursor: "pointer" }}
    >
      <p>My Boards</p>
      {props.boards.map((item) => (
        <div
          key={item.id}
          className={classes.allworkspaces}
          onClick={props.changeBoard.bind(null, item.id)}
        >
          <div className="singleworkspace d-flex algn-items-center">
            <img src={home} className={classes.workspaces} alt="" />
            <p className={`m-0 ${classes.singleworkspacetext}`}>{item.name}</p>
          </div>
        </div>
      ))}

      <div className="mt-4">
        <div
          className="singleworkspace d-flex algn-items-center"
          onClick={props.showboardmodal}
        >
          {console.log(props.latestworkspace)}
          {props.latestworkspace &&
            workspace.useablevariables.workspacerole === "company" && (
              <>
                <img src={Add} className={classes.addworkspace} alt="" />
                <p className={`m-0 ${classes.singleworkspacetext}`}>
                  Add Board
                </p>
              </>
            )}
        </div>
      </div>
    </div>
  );
};
