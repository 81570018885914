import React, { useState } from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { workspaceActions } from "../../store/Workspace/workspace-slice";

export const CheckBox = (props) => {
  const workspace = useSelector((state) => state.workspace);
  const dispatch = useDispatch();

  const [val, setVal] = useState(false);

  return (
    <div className="d-flex align-items-center">
      <FormControlLabel
        control={
          <Checkbox
            checked={val}
            onChange={(e) => {
              let abc = [...workspace.useablevariables.TaskDelCounts];

              if (e.target.checked) {
                abc.push(props.id);
                dispatch(
                  workspaceActions.updateTaskDelCounts({
                    count: abc,
                  })
                );
              } else {
                dispatch(
                  workspaceActions.updateTaskDelCounts({
                    count: abc.filter((item) => {
                      if (props.id === item) {
                        return false;
                      } else {
                        return true;
                      }
                    }),
                  })
                );
              }
              setVal(e.target.checked);
            }}
            style={{
              color: "#1565d8",
            }}
            value="task1"
          />
        }
        label={
          <Typography
            variant="h6"
            style={{ color: "black", fontSize: "16px", fontWeight: "400" }}
          ></Typography>
        }
      />
    </div>
  );
};
