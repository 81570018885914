import classes from "./EndDetailsSignup.module.css";
import { SignupDetails } from "../../components/Signupdetails/SignupDetails";

import { Link } from "react-router-dom";
import PersonalDetailsImg from "../../images/Signup/PersonalDetails.png";

import BuzzerLogo from "../../images/loginLogo.png";
import axios from "axios";
import { useState, useEffect } from "react";

import Arrow from "../../images/ForgotPassword/ArrowOne.png";
import Lock from "../../images/ForgotPassword/Lock.png";
import cross from "../../images/blackcross.png";
import { signupActions } from "../../store/signup-slice";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ContactUsImg from "../../images/contactus/ContactUsImg.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputPassword } from "../../components/InputPassword/InputPassword";
import { PhoneCountry } from "../../components/PhoneWithCountry/PhoneWithCountry";
import { Button } from "react-bootstrap";
let detailsval = "";
export const EndDetailsSignup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.signup);
  useEffect(() => {
    console.log(user);
  }, [user]);

  const inputHandler = (e) => {
    detailsval = e.target.value;
    console.log(detailsval);
  };
  const saveDetails = () => {
    if (detailsval.length > 0) {
      dispatch(
        signupActions.signupuser({
          details: detailsval,
        })
      );
      navigate("/logo");
    }
  };
  return (
    <SignupDetails>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <img src={cross} className={`${classes.imagecross}`} />
          </div>
          <div className="col-md-12 text-center ">
            <img src={BuzzerLogo} />
          </div>
          <div className="col-md-12 px-5">
            <p className={`${classes.businessheading}`}>
              Details About your work
            </p>
            <textarea
              cols="20"
              rows="7"
              className={`form-control ${classes.boxshadoweffect}`}
              placeholder="Details"
              onChange={inputHandler}
            />
          </div>
          <div className="col-md-12 text-center">
            <button
              className={`${classes.btnnext} btn btn-primary btn-lg`}
              onClick={saveDetails}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
