import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { workspaceActions } from "../../store/Workspace/workspace-slice";
import { changeItemMemberStatus } from "../../store/Workspace/workspace-actions";

export const ItemAddCheckbox = (props) => {
  let statusVal = [];
  const workspace = useSelector((state) => state.workspace);
  let data = [...workspace.useablevariables.checksOfItems];
  console.log("alkkalkaklalkalkalkaalk");
  console.log(data, props.id);

  data = data.filter((item) => {
    if (`${item.member}` === `${props.id}`) {
      return true;
    }
    return false;
  });
  data = JSON.parse(JSON.stringify(data));
  if (data.length > 0) {
    if (data[0].status === "1") {
      statusVal = "1";
    }
  } else {
    statusVal = "0";
  }

  let finalval = statusVal === "1" ? true : false;
  const [val, setVal] = useState(finalval);

  const dispatch = useDispatch();

  return (
    <div className="d-flex align-items-center justify-content-center">
      <FormControlLabel
        control={
          <Checkbox
            checked={val}
            onChange={(e) => {
              setVal(e.target.checked);
              dispatch(
                changeItemMemberStatus({
                  itemid: workspace.latestitem.id,
                  memberid: props.id,
                })
              );
            }}
            style={{
              color: "#1565d8",
            }}
            value="task1"
          />
        }
        label={
          <Typography
            variant="h6"
            style={{ color: "black", fontSize: "16px", fontWeight: "400" }}
          ></Typography>
        }
      />
    </div>
  );
};
