import classes from "./WorkspaceAddBoard.module.css";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import cross from "../../images/blackcross.png";
import tick from "../../images/workspace/tick.png";
import { Link } from "react-router-dom";

export const WorkspaceAddGroup = (props) => {
  return (
    <div className={classes.abs}>
      <Modal
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${classes.mymodal} ${classes.margins}`}
        centered
      >
        <img src={cross} className={classes.relcross} onClick={props.onclose} />
        <ModalBody className={`${classes.modalbody}`}>
          <h1 className={classes.taskstatus}>Create New Group</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam,
            consequatur debitis dolorem blanditiis aspernatur earum
          </p>
          <div className="row mt-4">
            <div className={`col-md-12 px-5 ${classes.paddingwname}`}>
              <h1 className={classes.taskstatus}>Group Name</h1>
              <input
                type="text"
                placeholder="New Group"
                className="form-control"
                onChange={props.getGroupName}
              />
              {props.GroupError && (
                <p style={{ color: "red" }}>Please Enter Group Name</p>
              )}
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
          <div
            className={`col-md-12 d-flex justify-content-end mt-5 mb-5 ${classes.paddingwnameb}`}
          >
            <button
              className="btn"
              style={{
                border: "1px solid black",
                width: "100px",
                height: "40px",
                borderRadius: "10px",
                marginRight: "60px",
              }}
              onClick={props.onclose}
            >
              Cancel
            </button>
            <button
              className="btn"
              style={{
                border: "1px solid white",
                backgroundColor: "#1565d8",
                borderRadius: "10px",
                color: "white",
                width: "100px",
                height: "40px",
              }}
              onClick={props.saveGroupData}
            >
              Add
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
