import React, { useState } from "react";
import logo from "../../images/buzzup_logo.png";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { NavDropdown, Nav, Row, Col, Dropdown } from "react-bootstrap";

import "./navbar.css";

export const Navbar = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <section className="navbar-bg">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid mx-4">
            <img alt="Logo" src={logo} width={130} />
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShow(!show)}
            >
              <span className="toggler-icon top-bar"></span>
              <span className="toggler-icon middle-bar"></span>
              <span className="toggler-icon bottom-bar"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link itmesofnav">Join For Free </a>
                </li>
                <li className="nav-item">
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <a className="nav-link itmesofnav">Login</a>
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link itmesofnav">Contact us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link itmesofnav">
                    System Operation Guide and Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link itmesofnav">Pricing</a>
                </li>
                <li className="nav-item">
                  <Nav variant="pills" activeKey="1">
                    <NavDropdown title="All Our Services" id="nav-dropdown">
                      <Row className={"mainMega"}>
                        <div className={"parentmegamenu"}></div>
                        <Col xs="12" sm="12" md="6" className="text-left">
                          <div className="p-4">
                            <p>
                              <b>Credit Clearing</b>
                            </p>
                            <span>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Natus, quibusdam impedit pariatur esse
                              eligendi.
                            </span>
                          </div>
                          <div className="p-4">
                            <p>
                              <b>Credit Clearing</b>
                            </p>
                            <span>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Natus, quibusdam impedit pariatur esse
                              eligendi.
                            </span>
                          </div>
                        </Col>
                        <Col xs="12" md="6" className="text-left">
                          <div className="p-4">
                            <p>
                              <b>Credit Clearing</b>
                            </p>
                            <span>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Natus, quibusdam impedit pariatur esse
                              eligendi.
                            </span>
                          </div>
                          <div className="p-4">
                            <p>
                              <b>Credit Clearing</b>
                            </p>
                            <span>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Natus, quibusdam impedit pariatur esse
                              eligendi.
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </NavDropdown>
                  </Nav>
                </li>
              </ul>
              <form className="d-flex">
                <button className="btn getstarted-btn" type="submit">
                  <Link
                    to="/login"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Get Started
                  </Link>
                </button>
              </form>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
};
