import React from "react";
import classes from "./ForgotPassword.module.css";
import { Link } from "react-router-dom";

import { forgotpassword } from "../../requests/requests";
import BuzzerLogo from "../../images/loginLogo.png";
import Arrow from "../../images/ForgotPassword/ArrowOne.png";
import Lock from "../../images/ForgotPassword/Lock.png";
import cross from "../../images/contactus/Cross.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContactUsImg from "../../images/contactus/ContactUsImg.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";

export const ForgotPassword = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  console.log(cookies);
  const navigate = useNavigate();
  useEffect(() => {
    if (cookies.Token !== undefined) {
      navigate("/workspace");
    } else {
      navigate("/forgotpassword");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      await forgotpassword(values);
      navigate("/verify");
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is Required"),
    }),
  });
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <img src={cross} className={`${classes.imagecross}`} />
        </div>
      </div>
      <div className="row ">
        <div className={`col-lg-7 ${classes.col1Main}`}>
          <div className={`${classes.loginImg}`}>
            <div className={`${classes.ContactText}`}>
              <p className={`${classes.ContactTextHeading}`}></p>
              <p className={`${classes.ContactTextPara}`}></p>
            </div>
            <img className={`${classes.lockImga}`} src={Lock} />
          </div>
        </div>
        <div className={`col-lg-5 pt-5 ${classes.col2Main}`}>
          <div className={`${classes.contactLogo}`}>
            <img alt="Buzzup" src={BuzzerLogo} />
          </div>

          <div className={`${classes.contactusContainer}`}>
            <p>
              Forgot <span style={{ color: "Gray" }}>Your Password?</span>
            </p>
            <p style={{ fontSize: "18px" }}>
              We will send a 6 digit code to your registered email address
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className={`${classes.signinInput}`}>
              <p>Email</p>
              <input
                type="text"
                placeholder="Abc@gmail.com"
                required
                id="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email ? (
                <div className="error">{formik.errors.email}</div>
              ) : (
                ""
              )}
            </div>
            <div className="d-grid gap-2 mt-4">
              <Button
                variant="primary"
                className="d-flex justify-content-center mt-4 align-items-center"
                size="lg"
                type="submit"
              >
                <div> Send Code</div>
                <div
                  style={{
                    width: "10px",
                    height: "40px",
                  }}
                >
                  <img
                    alt="facebook"
                    className={classes.socialiconssetup}
                    style={{ marginLeft: "-50px", marginTop: "-25px" }}
                    src={Arrow}
                  />
                </div>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
