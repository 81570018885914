import { UpBar } from "./UpBar";
import classes from "./WorkspaceSideContent.module.css";

export const WorkspaceSideContent = (props) => {
  return (
    <div className={`col-md-10 p-0 m-0 ${classes.content}`}>
      <UpBar />
    </div>
  );
};
