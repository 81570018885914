import { SignupDetails } from "../../components/Signupdetails/SignupDetails";
import { useState } from "react";
import BuzzupLogo from "../../images/buzzup_logo.png";
import classes from "./TrackFocus.module.css";
import Button from "../../components/SpecialComponents/Button";
import { Link } from "react-router-dom";

export const Trackfocus = (props) => {
  return (
    <SignupDetails>
      <div className={` ${classes.contentheight}`}>
        <div className={`row`}>
          <div className={`col-md-12 ${classes.paddings}`}>
            <div className="text-center">
              <img src={BuzzupLogo} width="300px" />
            </div>
            <h4>
              <b>
                <span style={{ color: "gray" }}>What do you like to</span> Focus
                on?
              </b>
            </h4>
          </div>
        </div>
        <div className={`row ${classes.paddingss}`}>
          <div
            className={`col-md-6 d-flex justify-content-between `}
            style={{ position: "relative" }}
          >
            <Button
              text={"HR"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
              onCheckProcess={true}
            ></Button>
          </div>
          <div
            className={`col-md-6 d-flex justify-content-between `}
            style={{ position: "relative" }}
          >
            <Button
              text={"Design"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
              onCheckProcess={true}
            ></Button>
          </div>

          <div
            className={`col-md-6 d-flex justify-content-between `}
            style={{ position: "relative" }}
          >
            <Button
              text={"Marketting"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
              onCheckProcess={true}
            ></Button>
          </div>
          <div
            className={`col-md-6 d-flex justify-content-between `}
            style={{ position: "relative" }}
          >
            <Button
              text={"sales"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
              onCheckProcess={true}
            ></Button>
          </div>
          <div
            className={`col-md-6 d-flex justify-content-between `}
            style={{ position: "relative" }}
          >
            <Button
              text={"Software Development"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
              onCheckProcess={true}
            ></Button>
          </div>
          <div
            className={`col-md-6 d-flex justify-content-between `}
            style={{ position: "relative" }}
          >
            <Button
              text={"Project Management"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
              onCheckProcess={true}
            ></Button>
          </div>
          <div
            className={`col-md-6 d-flex justify-content-between `}
            style={{ position: "relative" }}
          >
            <Button
              text={"Finance"}
              extraStyle={{ width: "100%", marginTop: "20px" }}
              onCheckProcess={true}
            ></Button>
          </div>
          <div className={`col-md-12 d-flex justify-content-between mt-5`}>
            <button
              className="btn"
              style={{
                border: "1px solid black",
                width: "100px",
                height: "50px",
                borderRadius: "10px",
              }}
            >
              Back
            </button>
            <Link
              to="/inviteteamates"
              style={{ textDecoration: "none", color: "white" }}
            >
              <button
                className="btn"
                style={{
                  border: "1px solid white",
                  backgroundColor: "blue",
                  borderRadius: "10px",
                  color: "white",
                  width: "100px",
                  height: "50px",
                }}
              >
                Next
              </button>
            </Link>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
