import classes from './WorkspaceChat.module.css';
import { useEffect, useState, useRef } from "react";
import Girlone from '../../images/workspace/girlone.jpeg';
import Likeicon from '../../images/workspace/likeicon.png';
import Replyicon from '../../images/workspace/Reply.png';
import Sendicon from '../../images/workspace/Sendicon.png';
import Addfile from '../../images/workspace/addfile.png';

import ScrollToBottom from "react-scroll-to-bottom";

const Chat = function (props) {
return (
    <>
    <div className='d-flex justify-content-between px-4'>
        <p className={`m-0 p-0 ${classes.taskname}`}>Task Name</p>
        <select>
            <option>Select Lead</option>
            <option>Lead 1</option>
            <option>Lead 2</option>
            <option>Lead 3</option>
        </select>
    </div>
    <ScrollToBottom className={classes.main}>
        <div className={classes.me}>
            <div className={classes.mydpname}>
                <p className="m-0">Saboor Malik</p>
                <img src={Girlone} className={classes.dp} />
                </div>                
            <div className={classes.mecontent}>
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
            </div>
        </div>
        <div className={classes.others}>
            <div className={classes.otherdpname}>
                <img src={Girlone} className={classes.othersdp} />
                <p className="m-0">Saboor Malik</p>
                </div>
            <div className={classes.othercontent}>
    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
    {/* <div className={`d-flex justify-content-between ${classes.colorblues}`}><p className='m-0 p-0'>12 likes</p><p className='m-0 p-0'>12 replies</p></div>
    <div className={`d-flex justify-content-between ${classes.colorwhite}`}><div><p className='m-0 p-0 d-flex align-items-center'>Like <img src={Likeicon} className={classes.Likeicon}/> </p></div><div><p className='m-0 p-0'>Reply <img src={Replyicon} className={classes.Likeicon}/></p></div></div> */}
            </div>
        </div>
        <div className={classes.me}>
            <div className={classes.mydpname}>
                <p className="m-0">Saboor Malik</p>
                <img src={Girlone} className={classes.dp} />
                </div>                
            <div className={classes.mecontent}>
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>

            </div>
        </div>
        <div className={classes.me}>
            <div className={classes.mydpname}>
                <p className="m-0">Saboor Malik</p>
                <img src={Girlone} className={classes.dp} />
                </div>                
            <div className={classes.mecontent}>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
            </div>
        </div>

    </ScrollToBottom>
    <div className="d-flex align-items-center px-3">
    <p className='m-0 p-0 d-flex align-items-center'><span>Files</span> <img src={Addfile} className={classes.sendchat}/> </p>
    <input type="text" className={`form-control mx-3 ${classes.inpumessage}`} placeholder='write something' />
    <p className='m-0 p-0 d-flex align-items-center'><span>Send</span> <img src={Sendicon} className={classes.sendchat}/> </p>
    </div>
    </>
)
};
export default Chat;
