import { SignupDetails } from "../../components/Signupdetails/SignupDetails";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import classes from "./Password.module.css";
import { Link } from "react-router-dom";
import PersonalDetailsImg from "../../images/Signup/PersonalDetails.png";
import { signupActions } from "../../store/signup-slice";

import BuzzerLogo from "../../images/loginLogo.png";

import Arrow from "../../images/ForgotPassword/ArrowOne.png";
import Lock from "../../images/ForgotPassword/Lock.png";
import cross from "../../images/contactus/Cross.png";

import ContactUsImg from "../../images/contactus/ContactUsImg.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputPassword } from "../../components/InputPassword/InputPassword";
import { Button } from "react-bootstrap";

export const Password = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.signup);

  console.log(user);
  let [radiostatus, setradiostatus] = useState(false);
  const changeRadioStatus = () => {
    setradiostatus(!radiostatus);
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      finalpassword: "",
      agreecheck: "",
    },
    onSubmit: (values) => {
      console.log(values);
      if (radiostatus) {
        dispatch(
          signupActions.signupuser({
            password: values.password,
          })
        );
        navigate("/enterphone");
      }
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .required("Password is Required")
        .matches(
          /^(?=.*[A-Z]).*$/,
          "Need to contain atleast one uppercase letter"
        )
        .matches(
          /^[A-Za-z\d@$!%*#?&]{8,}$/,
          "Need to contain minimum 8 letters"
        ),
      // .matches(
      //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      // ),
      finalpassword: yup
        .string()
        .matches(
          /^(?=.*[A-Z]).*$/,
          "Need to contain atleast one uppercase letter"
        )
        .matches(
          /^[A-Za-z\d@$!%*#?&]{8,}$/,
          "Need to contain minimum 8 letters"
        )
        .oneOf(
          [yup.ref("password"), null],
          "Password and Confrm Password must match"
        ),
    }),
  });

  return (
    <SignupDetails>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <img src={cross} className={`${classes.imagecross}`} />
          </div>
        </div>
        <div className="row ">
          <div
            className={`col-md-6 d-flex flex-column align-items-center justify-content-center ${classes.bgblue}`}
          >
            <img src={PersonalDetailsImg} className="img-fluid" />
            <h5 className="mt-4">
              <b>
                Add your<span className={`${classes.bgblues}`}> details</span>{" "}
                so you can login later
              </b>
            </h5>
          </div>
          <div className={`col-lg-6 pt-5 ${classes.col2Main}`}>
            <div className={`${classes.contactLogo}`}>
              <img alt="Buzzup" src={BuzzerLogo} />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className={`${classes.contactusContainer}`}>
                <p>
                  Create <span style={{ color: "Gray" }}>New Password</span>
                </p>
              </div>
              <div className={` ${classes.resetFinal}`}>
                <div className="signin-input mt-4">
                  <p style={{ fontWeight: "700" }}>Type Password</p>
                  <InputPassword formik={formik} password={true} />
                </div>
                <div className="signin-input mt-4">
                  <p style={{ fontWeight: "700" }}>Retype Password</p>
                  <InputPassword formik={formik} confirmpassword={true} />
                </div>
              </div>
              <div className="d-flex align-items-center  mt-3">
                <input
                  type="radio"
                  style={{ width: "20px", height: "20px" }}
                  name="agreecheck"
                  checked={radiostatus}
                  onClick={changeRadioStatus}
                ></input>

                <div
                  style={{
                    fontSize: "15px",
                    color: "gray",
                  }}
                  className={`${classes.paraa}`}
                >
                  I agree to the terms of services and privacy policies
                </div>
              </div>
              <div className="d-flex mt-3 justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary  btn-lg"
                  style={{
                    fontSize: "16px",
                    width: "90px",
                    borderRadius: "12px",
                  }}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
