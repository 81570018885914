import React from "react";
import "./home.css";
import { useEffect } from "react";
import { HomeBanner } from "../components/HomeBanner/HomeBanner";
import { useNavigate } from "react-router-dom";
import { HomeSlider } from "../components/HomeSlider/HomeSlider";
import { HomeMiddle } from "../components/Home-middle/HomeMiddle";
import { useCookies } from "react-cookie";

export const Home = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  console.log(cookies);
  const navigate = useNavigate();
  useEffect(() => {
    if (cookies.Token !== undefined) {
      navigate("/workspace");
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="home-main">
      <HomeBanner />
      <HomeSlider />
      <div className="web-content-main">
        <div className="row">
          <div className="col-lg-6 home-content">
            <p>
              The <b>Work OS</b> that lets you shape workflows, <b>Your Way</b>
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p>
          </div>
          <div className="col-lg-6 home-content2">
            <p>
              Boost your team’s alignment, efficiency, and productivity by
              customizing any workflow to fit your needs.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, Lorem Ipsum is simply dummy text of the
              printing and typesetting industry,{" "}
            </p>
            <div className="getstarted-btn2">
              <button type="submit">Get Started</button>
            </div>
          </div>
        </div>
      </div>
      <HomeMiddle />
    </div>
  );
};
