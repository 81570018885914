import { SignupDetails } from "../../components/Signupdetails/SignupDetails";
import { InputPassword } from "../../components/InputPassword/InputPassword";

import { useFormik } from "formik";
import * as yup from "yup";

import PersonalDetailsImg from "../../images/Signup/PersonalDetails.png";
import Logo from "../../images/loginLogo.png";
import Plus from "../../images/plus.png";
import classes from "./InviteTeamates.module.css";
import { useState } from "react";
export const InviteTeamates = (props) => {
  let [emailentries, setEmailentries] = useState(1);
  let onEmailIncreases = () => {
    setEmailentries(emailentries + 1);
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      finalpassword: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .required("Password is Required")
        .min(8, "Password is too short - should be 8 chars minimum."),
      finalpassword: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "Password and Confrm Password must match"
        ),
    }),
  });
  return (
    <SignupDetails>
      <div className={`row ${classes.contentheight}`}>
        <div
          className={`col-md-6 d-flex flex-column align-items-center justify-content-center ${classes.bgblue}`}
        >
          <img src={PersonalDetailsImg} className="img-fluid" />
          <h5 className="mt-4">
            <b>
              Add your<span className={`${classes.bgblues}`}> details</span> so
              you can login later
            </b>
          </h5>
        </div>
        <div className={`col-md-6 pt-5 px-4 ${classes.inputmargin}`}>
          <div className="text-center">
            <img src={Logo} className="img-fluid" />
          </div>
          <h4 className="mt-3">
            <b>Invite your Teammates</b>
          </h4>
          {[...Array(emailentries)].map((elementInArray, index) => (
            <div className="form-group mt-4">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Add Email"
              ></input>
            </div>
          ))}
          <div
            className="d-flex align-items-center  mt-3"
            style={{ cursor: "pointer" }}
            onClick={onEmailIncreases}
          >
            <img src={Plus} style={{ width: "20px", height: "20px" }} />

            <div
              style={{
                fontSize: "15px",
                color: "gray",
              }}
              className={`${classes.paraa}`}
            >
              Add Another
            </div>
          </div>
          <div className="d-flex mt-3 justify-content-end">
            <button
              className="btn btn-primary  btn-lg"
              style={{ fontSize: "16px" }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
