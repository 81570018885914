import { createSlice } from "@reduxjs/toolkit";

const signupSlice = createSlice({
  name: "membersignup",
  initialState: {
    role: "",
    email: '',
    password: '',
    name:'',
    secretno:'',
    image: null,
  },
  reducers: {
    signupuser(state, action) {
      const newUser = action.payload;
      if(newUser.email){
          state.email = newUser.email
      }else if(newUser.password){
        state.password = newUser.password
      }else if(newUser.secretno){
        state.secretno = newUser.secretno
      }
      else if(newUser.name){
        state.name = newUser.name
      }else if(newUser.business){
        state.business = newUser.business
      }else if(newUser.details){
        state.details = newUser.details
      }else if(newUser.business_name){
        state.business_name = newUser.business_name
      }else if(newUser.location){
        state.location = newUser.location
      }else if(newUser.image){
        state.image = newUser.image
      }else if(newUser.city_name){
        state.city_name = newUser.city_name
      }else if(newUser.office_name){
        state.office_name = newUser.office_name
      }
      
      console.log(newUser)
    },
  },
});

export const signupActions = signupSlice.actions;

export default signupSlice;
