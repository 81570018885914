import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector, useDispatch } from "react-redux";
import { workspaceActions } from "../../store/Workspace/workspace-slice";
import { deleteinvitedb } from "../../store/Workspace/workspace-actions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F3F6F9",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "white",
    zIndex: 999,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "td , th": {
    border: 1,
  },
}));

export default function CustomizedTables(props) {
  const dispatch = useDispatch();
  function createData(name, calories, fat, carbs, protein, total) {
    return { name, calories, fat, carbs, protein, total };
  }
  const workspace = useSelector((state) => state.workspace);
  const rows = [];
  props.members.map((item) => {
    let abc = createData(
      `https://buzzuperpbackend.dejuretechnologies.com${item.image}`,
      item.name,
      item.email,
      item.id
    );
    rows.push(abc);
  });
  const deleteInvite = (id) => {
    dispatch(deleteinvitedb(id, workspace));
  };
  return (
    <TableContainer style={{ backgroundColor: "white" }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">E-mail&nbsp;</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
            {/* <StyledTableCell align="center">Role&nbsp;</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={Math.random()}>
              <StyledTableCell align="center">{row.fat}</StyledTableCell>
              <StyledTableCell align="center">
                <button
                  className="btn btn-danger"
                  onClick={deleteInvite.bind(null, row.carbs)}
                >
                  Delete Invite
                </button>
              </StyledTableCell>
              {/* <StyledTableCell align="center">
                {
                  <select>
                    <option>Admin</option>
                    <option>Member</option>
                  </select>
                }
              </StyledTableCell> */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
