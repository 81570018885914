import classes from "./WorkspaceMembersModal.module.css";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import CustomPaginationActionsTableMembersItem from "./CustomPaginationActionsTableMembersItem";
import cross from "../../images/blackcross.png";

export const WorkspaceItemMembersModel = (props) => {
  //   console.log(props.membersin);
  return (
    <div className={classes.abs}>
      <Modal
        show={true}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={`${classes.mymodal} ${classes.margins}`}
        centered
      >
        <img src={cross} className={classes.relcross} onClick={props.onclose} />
        <ModalBody className={`${classes.modalbody}`}>
          <div className="row">
            <div className="col-md-12">
              <div className="px-5">
                <h1 className={classes.timeheading}>Add Member To Task</h1>
                <p className={classes.para}>Assign this task to members</p>
                {/* <div className={`input-group ${classes.forminput}`}>
                  <input
                    className={`form-control py-2 ${classes.searchbar}`}
                    type="text"
                    placeholder="Search any member"
                  />
                  <span className="input-group-append">
                    <button
                      className={`btn btn-outline-secondary border-0 border ${classes.search}`}
                      type="button"
                    >
                      <i className={`fa fa-search ${classes.searchicon}`}></i>
                    </button>
                  </span>
                </div> */}
              </div>
              <CustomPaginationActionsTableMembersItem
                members={props.membersin}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
