import React from "react";

import "./MemberSignup.css";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { signupActions } from "../../store/MemberSignup/MemberSignup-slice";
import { Link } from "react-router-dom";
import signupImg from "../../images/signupImg.png";
import loginLogo from "../../images/loginLogo.png";
import googleIcon from "../../images/icons/googleIcon.png";
import facebookIcon from "../../images/icons/facebookBlue.png";
import appleIcon from "../../images/icons/appleIcon.png";

import { useFormik } from "formik";
import * as yup from "yup";

export const MemberSignup = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.membersignup);
  const [cookies, setCookie] = useCookies(["user"]);
  console.log(user);
  const navigate = useNavigate();
  useEffect(() => {
    if (cookies.SendInvite === "ok") {
      navigate("/membersignup");
    } else {
      navigate("/");
    }
  }, []);
  console.log(user);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      console.log(values);
      dispatch(
        signupActions.signupuser({
          email: values.email,
        })
      );
      navigate("/memberpassword");
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is Required"),
    }),
  });
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-7 col1-main">
          <div className="signup-img">
            <img alt="login" src={signupImg} />
          </div>
        </div>
        <div className="col-lg-5 col2-main">
          <div className="login-logo">
            <img alt="Buzzup" src={loginLogo} />
          </div>

          <div className="signin-form">
            <p>Welcome to BuzzUp</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="signin-input">
                <p>Email</p>
                <input
                  type="text"
                  placeholder="Abc@gmail.com"
                  required
                  id="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : (
                  ""
                )}
              </div>
              <button type="submit" className="login-btn mt-5">
                {/* <Link
                  to="/password"
                  style={{ textDecoration: "none", color: "white" }}
                > */}
                Continue
                {/* </Link> */}
              </button>
            </form>
            <p className="login-with">OR Sign up with</p>
            <button type="submit" className="google-btn">
              Google
              <img alt="icon" src={googleIcon} className="google-icon" />
            </button>
            <button type="submit" className="google-btn facebook-btn">
              Facebook
              <img alt="icon" src={facebookIcon} className="google-icon" />
            </button>
            <button type="submit" className="google-btn facebook-btn">
              Apple
              <img alt="icon" src={appleIcon} className="google-icon" />
            </button>
            <div className="have-account">
              <p>
                Already have an account?{" "}
                <Link to="/login">
                  <span> Sign In</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
