import { Store } from "react-notifications-component";

import "animate.css/animate.min.css";

import "animate.css/animate.compat.css";
import axios from "axios";
import { Navigate } from "react-router-dom";


export const forgotpassword = async (reqdata)=>{
    let loadingid=''
    try {
        loadingid = Store.addNotification({
            title: "Loading...",
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            slidingExit:{
              timingFunction:'linear'
            }
        });
          console.log('Loading id',loadingid)
          let data = new FormData()
          data.append('email',reqdata.email)
        const response = await axios({
            method: "POST",
            url: "https://buzzuperpbackend.dejuretechnologies.com/api/v1/companies/forget_password",
            data,
        });
        console.log(response)
        localStorage.setItem('verificationcode',response.data.verification_code);
        localStorage.setItem('companyid',response.data.company_id);
        Store.removeNotification(loadingid)
        Store.addNotification({
          title: "Email Sent Successfully",
          message: `Please check your email`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
      } catch (error) {
        console.log(error);
  
  
        Store.removeNotification(loadingid)
  
        Store.addNotification({
          title: "Something Wrong",
          message:`${error.response.data.message}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            // onScreen: true,
          },
        });
        console.log(error);
        // dispatch(
        //   uiActions.showNotification({
        //     status: "error",
        //     title: "Error!",
        //     message: "Sending cart data failed!",
        //   })
        // );
      }
}

export const resetpassword = async (reqdata,navigate)=>{
    let loadingid=''
    try {
        loadingid = Store.addNotification({
            title: "Loading...",
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            slidingExit:{
              timingFunction:'linear'
            }
        });
          console.log('Loading id',loadingid)
          let data = new FormData()
          console.log(reqdata)
          data.append('password',reqdata)
        const response = await axios({
            method: "POST",
            url: `https://buzzuperpbackend.dejuretechnologies.com/api/v1/companies/reset_password/${localStorage.getItem('companyid')}`,
            data,
        });
        console.log(response)
        Store.removeNotification(loadingid)
        Store.addNotification({
          title: "Password Reset Successfully!",
          message: `Your password has been successfully updated`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
        navigate('/login')
      } catch (error) {
        console.log(error);
  
  
        Store.removeNotification(loadingid)
  
        Store.addNotification({
          title: "Something Wrong",
          message:`${error.response.data.message}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            // onScreen: true,
          },
        });
        console.log(error);
        // dispatch(
        //   uiActions.showNotification({
        //     status: "error",
        //     title: "Error!",
        //     message: "Sending cart data failed!",
        //   })
        // );
      }
}

