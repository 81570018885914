import classes from "./BusinessIdNumber.module.css";
import { SignupDetails } from "../../components/Signupdetails/SignupDetails";

import { Link } from "react-router-dom";
import PersonalDetailsImg from "../../images/Signup/PersonalDetails.png";

import BuzzerLogo from "../../images/loginLogo.png";
import axios from "axios";
import {useState,useEffect} from'react';

import Arrow from "../../images/ForgotPassword/ArrowOne.png";
import Lock from "../../images/ForgotPassword/Lock.png";
import cross from "../../images/blackcross.png";

import ContactUsImg from "../../images/contactus/ContactUsImg.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputPassword } from "../../components/InputPassword/InputPassword";
import { PhoneCountry } from '../../components/PhoneWithCountry/PhoneWithCountry';
import { Button } from "react-bootstrap";

export const BusinessIdNumber = () => {
  let [countryCode,setCountryCode] = useState('')
  useEffect( () => {
    let abc = async ()=>{
        try {
            const response = await axios.get(
              `https://api.ipregistry.co/110.39.1.130?key=0z8t5649zclj7ah3`
            );

            setCountryCode(response.data.location.country.code)
          } catch(error){
            console.log(error)
          }
        }
        abc()
      });
      console.log(countryCode)
  return (
    <SignupDetails>

    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <img src={cross} className={`${classes.imagecross}`} />
        </div>
        <div className="col-md-12 text-center">
        <img src={BuzzerLogo} />
        </div>
        <div className="col-md-12 px-5">
            <p className={`${classes.businessheading}`}>Enter Your Business Id Number</p>
            <input type="text" className="form-control" placeholder="1234567890" />
        </div>
        <div className="col-md-12 text-center">
            <button className={`${classes.btnnext} btn btn-primary btn-lg`}>Next</button>
        </div>
      </div>
    </div>
    </SignupDetails>
  );
};
