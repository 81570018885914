import React, { useState } from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { workspaceActions } from "../../store/Workspace/workspace-slice";
import { updateStatusOfCheckListItem } from "../../store/Workspace/workspace-actions";
export const CheckboxItemDetails = (props) => {
  const dispatch = useDispatch();
  console.log(props.stat);
  const [val, setVal] = useState("none");

  console.log("val");

  return (
    <div className="d-flex align-items-center">
      <FormControlLabel
        control={
          <Checkbox
            checked={
              val === "none"
                ? props.stat === "0"
                  ? false
                  : true
                : val === "0"
                ? false
                : true
            }
            onChange={(e) => {
              dispatch(updateStatusOfCheckListItem(props.id, props.workspace));
              setVal(e.target.checked === true ? "1" : "0");
            }}
            style={{
              color: "#1565d8",
            }}
            value="task1"
          />
        }
        label={
          <Typography
            variant="h6"
            style={{ color: "black", fontSize: "16px", fontWeight: "400" }}
          ></Typography>
        }
      />
    </div>
  );
};
