import { Store } from "react-notifications-component";
import { signupActions } from "./signup-slice";
import { workspaceActions } from "./Workspace/workspace-slice";

import "animate.css/animate.min.css";

import "animate.css/animate.compat.css";
import axios from "axios";


export const sendSignupData = (user,setCookie,navigate) => {
  return async (dispatch) => {
    console.log(user)
    // dispatch(
    //   uiActions.showNotification({
    //     status: "pending",
    //     title: "Sending...",
    //     message: "Sending cart data!",
    //   })
    // );
    let loadingid=''
    const sendRequest = async () => {
      let data = new FormData();

      data.append("email", user.email);
      data.append("password", user.password);
      data.append("phone", user.phone);
      data.append("business", user.business);
      data.append("business_name", user.business_name);
      data.append("city_name", user.city_name);
      data.append("details", user.details);
      data.append("image", user.image);
      data.append("location", user.location);
      data.append("office_name", user.office_name);
      data.append("password", user.password);
      loadingid = Store.addNotification({
        title: "Loading...",
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        slidingExit:{
          timingFunction:'linear'
        }
      });
      console.log('Loading id',loadingid)

      const response = await axios({
        method: "POST",
        url: "https://buzzuperpbackend.dejuretechnologies.com/api/v1/companies/signup",
        data,
      });
      return response;
      // if (response.data.status !== "success") {
        //   throw new Error("Sending cart data failed.");
        // }
      };
      
      try {
      let response= await sendRequest();
      console.log(response)
      var today = new Date();
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate()+20);
      setCookie("Token", `${response.data.data.remember_token}`, { path: "/",expires: tomorrow });
      dispatch(
        signupActions.signupuser({
          role: "",
          email: '',
          password: '',
          phone: '',
          business: '',
          location: '',
          city_name: '',
          bank_name: '',
          office_name: '',
          business_name: '',
          details: '',
          image: null,
        })
      );
      Store.removeNotification(loadingid)
      Store.addNotification({
        title: "Signup Successful",
        message: `You can login through email ${user.email}`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
      setTimeout(function(){
        navigate('/workspace')
      },3000)
    } catch (error) {
      console.log(error);


      Store.removeNotification(loadingid)

      Store.addNotification({
        title: "Something Wrong",
        message:`${error.response.data.message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          // onScreen: true,
        },
      });
      console.log(error);
      // dispatch(
      //   uiActions.showNotification({
      //     status: "error",
      //     title: "Error!",
      //     message: "Sending cart data failed!",
      //   })
      // );
    }
  };
};
