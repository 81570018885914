import classes from "./Verify.module.css";
import { Link } from "react-router-dom";

import { Store } from "react-notifications-component";

import "animate.css/animate.min.css";

import "animate.css/animate.compat.css";

import BuzzerLogo from "../../images/loginLogo.png";
import Arrow from "../../images/ForgotPassword/ArrowOne.png";
import Lock from "../../images/ForgotPassword/Lock.png";
import cross from "../../images/contactus/Cross.png";

import ContactUsImg from "../../images/contactus/ContactUsImg.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { OtpInputComponent } from "../../components/OtpInput/OtpInput";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const Verify = () => {
  const navigate = useNavigate();

  let checkcorrect = "";
  let finalval = "";
  const checklength = (val) => {
    if (val.length < 6) {
      checkcorrect = false;
      finalval = val;
    } else {
      checkcorrect = true;
      finalval = val;
    }
  };
  const checkotp = () => {
    if (checkcorrect) {
      if (finalval == localStorage.getItem("verificationcode")) {
        localStorage.setItem("verificationcode", "");
        navigate("/resetpassword");
      } else {
        let loadingid = Store.addNotification({
          title: "Otp is incorrect",
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
          },
          slidingExit: {
            timingFunction: "linear",
          },
        });
      }
    } else {
      let loadingid = Store.addNotification({
        title: "Otp must be of 6 digits",
        type: "warning",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
        },
        slidingExit: {
          timingFunction: "linear",
        },
      });
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <img src={cross} className={`${classes.imagecross}`} />
        </div>
      </div>
      <div className="row ">
        <div className={`col-lg-7 ${classes.col1Main}`}>
          <div className={`${classes.loginImg}`}>
            <div className={`${classes.ContactText}`}>
              <p className={`${classes.ContactTextHeading}`}></p>
              <p className={`${classes.ContactTextPara}`}></p>
            </div>
            <img className={`${classes.lockImga}`} src={Lock} />
          </div>
        </div>
        <div className={`col-lg-5 pt-5 ${classes.col2Main}`}>
          <div className={`${classes.contactLogo}`}>
            <img alt="Buzzup" src={BuzzerLogo} />
          </div>
          <div className={`${classes.contactusContainer}`}>
            <p>
              Enter <span style={{ color: "Gray" }}>Your OTP</span>
            </p>
            <p style={{ fontSize: "18px" }}>
              Please Enter your otp sent to your email
            </p>
          </div>
          <div className={`d-flex justify-content-center ${classes.otpfinal}`}>
            <OtpInputComponent checklength={checklength} />
          </div>
          <div className="d-grid gap-2 mt-4">
            <Button
              variant="primary"
              className="d-flex justify-content-center mt-4 align-items-center"
              size="lg"
              onClick={checkotp}
            >
              <div>Send Code</div>
              <div
                style={{
                  width: "10px",
                  height: "40px",
                }}
              >
                <img
                  alt="facebook"
                  className={classes.socialiconssetup}
                  style={{ marginLeft: "-50px", marginTop: "-25px" }}
                  src={Arrow}
                />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
