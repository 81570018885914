import classes from "../../pages/Workspace/base.module.css";
import logo from "../../images/Base/Logo.png";
import Dots from "../../images/Base/Dots.png";
import workspace from "../../images/Base/workspace.png";
import workspacebg from "../../images/Base/iconsbackground/workspacebg.png";
import bell from "../../images/Base/bell.png";
import bellbg from "../../images/Base/iconsbackground/bellbg.png";
import logout from "../../images/workspace/logout.png";
import heart from "../../images/Base/heart.png";
import heartbg from "../../images/Base/iconsbackground/heartbg.png";
import { propTypes } from "react-bootstrap/esm/Image";

export const BaseNavbar = (props) => {
  return (
    <div className="col-md-11 m-0 p-0">
      <div
        className={`${classes.navbar} d-flex align-items-center justify-content-between`}
      >
        <div className="d-flex align-items-center">
          <div className=" d-flex  align-items-center">
            <img
              src={workspace}
              className={classes.workspace}
              alt=""
              onMouseOver={(e) => (e.currentTarget.src = workspacebg)}
              onMouseOut={(e) => (e.currentTarget.src = workspace)}
            />
            <img
              src={bell}
              className={classes.bell}
              alt=""
              onMouseOver={(e) => (e.currentTarget.src = bellbg)}
              onMouseOut={(e) => (e.currentTarget.src = bell)}
            />
            <img
              src={heart}
              className={classes.heart}
              alt=""
              onMouseOver={(e) => (e.currentTarget.src = heartbg)}
              onMouseOut={(e) => (e.currentTarget.src = heart)}
            />
          </div>
          <div className={`input-group ${classes.forminput}`}>
            <input
              className={`form-control py-2 ${classes.searchbar}`}
              type="text"
              placeholder="Search Everything"
            />
            <span className="input-group-append">
              <button
                className={`btn btn-outline-secondary border-0 border ${classes.search}`}
                type="button"
              >
                <i className="fa fa-search"></i>
              </button>
            </span>
          </div>
        </div>
        <div className="">
          <button
            className={`btn text-light ${classes.btnplans}`}
            type="button"
          >
            See Plans
          </button>
          <img src={Dots} className={classes.dots} alt="" />
        </div>
      </div>
      <h1 className={`${classes.content}`}>{props.children}</h1>
    </div>
  );
};
