import { SignupDetails } from "../../components/Signupdetails/SignupDetails";
import { useState } from "react";
import BuzzupLogo from "../../images/buzzup_logo.png";
import classes from "./PeopleSurvey.module.css";
import Button from "../../components/SpecialComponents/Button";
import { Link } from "react-router-dom";
export const Peoplesurvey = (props) => {
  return (
    <SignupDetails>
      <div className={` ${classes.contentheight}`}>
        <div className={`row`}>
          <div className={`col-md-12 ${classes.paddings}`}>
            <div className="text-center">
              <img src={BuzzupLogo} width="300px" />
            </div>
            <h4>
              <b>
                <span style={{ color: "gray" }}>
                  How many people are in your
                </span>{" "}
                team?
              </b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div
            className={`col-md-12 d-flex flex-wrap justify-content-start ${classes.paddingss}`}
          >
            <Button
              text={"Only ME"}
              classeformargin={classes.classformargin}
            ></Button>
            <Button
              text={"2 - 5"}
              classeformargin={classes.classformargin}
            ></Button>
            <Button
              text={"6 - 10"}
              classeformargin={classes.classformargin}
            ></Button>
            <Button
              text={"12 - 15"}
              classeformargin={classes.classformargin}
            ></Button>
            <Button
              text={"16 - 20"}
              classeformargin={classes.classformargin}
            ></Button>
            <Button
              text={"20 - 25"}
              classeformargin={classes.classformargin}
            ></Button>
          </div>
        </div>
        <div className={`row`}>
          <div className={`col-md-12 ${classes.paddings}`}>
            <h4>
              <b>
                <span style={{ color: "gray" }}>
                  How many people are in your
                </span>{" "}
                company?
              </b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div
            className={`col-md-12 d-flex flex-wrap justify-content-start ${classes.paddingss}`}
          >
            <Button
              text={"Only ME"}
              classeformargin={classes.classformargin}
            ></Button>
            <Button
              text={"2 - 5"}
              classeformargin={classes.classformargin}
            ></Button>
            <Button
              text={"6 - 10"}
              classeformargin={classes.classformargin}
            ></Button>
            <Button
              text={"12 - 15"}
              classeformargin={classes.classformargin}
            ></Button>
            <Button
              text={"16 - 20"}
              classeformargin={classes.classformargin}
            ></Button>
            <Button
              text={"20 - 25"}
              classeformargin={classes.classformargin}
            ></Button>
          </div>
          <div
            className={`col-md-12 d-flex justify-content-between mt-5 ${classes.paddingss}`}
          >
            <button
              className="btn"
              style={{
                border: "1px solid black",
                width: "100px",
                height: "50px",
                borderRadius: "10px",
              }}
            >
              Back
            </button>
            <Link
              to="/trackfocus"
              style={{ textDecoration: "none", color: "white" }}
            >
              <button
                className="btn"
                style={{
                  border: "1px solid white",
                  backgroundColor: "blue",
                  borderRadius: "10px",
                  color: "white",
                  width: "100px",
                  height: "50px",
                }}
              >
                Next
              </button>
            </Link>
          </div>
        </div>
      </div>
    </SignupDetails>
  );
};
